
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import {ArchwizardModule} from 'angular-archwizard';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbAccordionModule, NgbButtonsModule, NgbCarouselModule, NgbCollapseModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './page/login/login.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ToastrModule } from 'ngx-toastr';
import {TextMaskModule} from 'angular2-text-mask';
import {NgNumberFormatterModule} from 'ng-number-formatter';

import { FullTextSearchPipe  } from './shared/filter.pipe';
import { SanitizeHtmlPipe  } from './shared/sanitize.pipe';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { NgxSpinnerModule } from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './page/home/home.component';
import {TinymceModule} from 'angular2-tinymce';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { SelectModule } from 'ng-select';
import {TagInputModule} from 'ngx-chips';
import {DragulaModule, DragulaService} from 'ng2-dragula';
import { SelectOptionService } from './theme/shared/components/select/select-option.service';
import { BarRatingModule } from 'ngx-bar-rating';
import { TabSenhasComponent } from './page/tab-senhas/tab-senhas.component';
import { TabCadastroTestadorComponent } from './page/tab-cadastro-testador/tab-cadastro-testador.component';
import { TabTestamentoComponent } from './page/tab-testamento/tab-testamento.component';
import { TabAnexosComponent } from './page/tab-anexos/tab-anexos.component';
import { ProgressComponent } from './page/progress/progress.component';
import { TabExcecutoresBeneficiariosComponent } from './page/tab-excecutores-beneficiarios/tab-excecutores-beneficiarios.component';
import { PreviewTestamentoComponent } from './page/preview-testamento/preview-testamento.component';
import { PagamentoComponent } from './page/pagamento/pagamento.component';
import { ConstruirTestamentoComponent } from './page/construir-testamento/construir-testamento.component';
import { GerenciarTestamentoComponent } from './page/gerenciar-testamento/gerenciar-testamento.component';
import { AcompanharAssinaturasComponent } from './page/acompanhar-assinaturas/acompanhar-assinaturas.component';
import {SafePipe} from './page/pipe';
import { RedefinirSenhaComponent } from './page/redefinirSenha/redefinirSenha.component';
import { ComponenteCadUsuarioComponent } from './page/componente-cad-usuario/componente-cad-usuario.component';
import { ModalAcompanharAssinaturasComponent } from './page/modal-acompanhar-assinaturas/modal-acompanhar-assinaturas.component';
import { AceiteComponent } from './page/aceite-executor/aceite/aceite.component';
import { AceiteExecutorComponent } from './page/aceite-executor/aceite-executor.component';
import { AceiteLoginComponent } from './page/aceite-executor/aceite-login/aceite-login.component';
import { ModalModule } from './theme/shared/components';
import { TabLeituraComponent } from './page/tab-leitura/tab-leitura.component';
import { RelatoriosGerenciaisComponent } from './page/relatorios-gerenciais/relatorios-gerenciais.component';
// import { AdicionarExecutorFormComponent } from './page/adicionar-executor-form/adicionar-executor-form.component';


//import { AdvRatingComponent } from './demo/ui-elements/ui-adv/adv-rating/adv-rating.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LoginComponent,
    RedefinirSenhaComponent,
    HomeComponent,
    FullTextSearchPipe,
    SanitizeHtmlPipe,
    CadastroUsuarioComponent,
    SafePipe,
    TabSenhasComponent,
    TabCadastroTestadorComponent,
    TabTestamentoComponent,
    TabAnexosComponent,
    TabExcecutoresBeneficiariosComponent,
    PreviewTestamentoComponent,
    PagamentoComponent,
    ConstruirTestamentoComponent,
    GerenciarTestamentoComponent,
    AcompanharAssinaturasComponent,
    ComponenteCadUsuarioComponent,
    ModalAcompanharAssinaturasComponent,
    AceiteComponent,
    AceiteExecutorComponent,
    AceiteLoginComponent,
    TabLeituraComponent,
    RelatoriosGerenciaisComponent,
    ProgressComponent
    // AdicionarExecutorFormComponent,





  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule ,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    FileUploadModule,
    NgxSpinnerModule,
    NgbCollapseModule,
    NgbAccordionModule,
    ArchwizardModule,
    TextMaskModule,
    ColorPickerModule,
    TinymceModule,
    SelectModule,
    TagInputModule,
    NgNumberFormatterModule,
    BarRatingModule,
    DragulaModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    NgbCarouselModule,
    ModalModule,



  ],
  providers: [NavigationItem, LoggedInGuard, DragulaService, SelectOptionService],
  bootstrap: [AppComponent]
})
export class AppModule { }

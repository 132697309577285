import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-aceite-executor',
  templateUrl: './aceite-executor.component.html',
  styleUrls: ['./aceite-executor.component.scss']
})
export class AceiteExecutorComponent implements OnInit {

  public controle: any;
  
  constructor(
    public util: UtilService
  ) {
    this.controlaDiv('login');
   }

  controlaDiv(div: string){    
    switch (div) {
      case "login":
        this.controle = {'login': true, 'aceite': false};
        break;
      case "aceite":
        this.controle = {'login': false, 'aceite': true};
        break;
    }
  }

  ngOnInit() {
  }

  irAceiteentrar(respostaLogin){      
    if(respostaLogin.resposta){
      this.controlaDiv('aceite');
    }
  }

}

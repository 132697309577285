<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="auth-wrapper aut-bg-img">

  <div class="blur-bg-images"></div>
  <!-- [ change-password ] start -->
  <div class="auth-content cadastroUser">
    <div class="card">
      <div class="row align-items-center">
        <div class="col-md-12 sm-mt-4 position-relative">
          <div (click)="sair()" class="btnFecharModal">x</div>

          <div class="card-body" *ngIf="!exibirRecuperacaoDeSenha">

            <div class="col-md-12" align="center">
              <img src="assets/images/logo-azul-2.png" alt
                class="img-fluid mb-4">
            </div>
            <h3 class="mb-4 f-w-400 text-blue text-center text-uppercase">Novo
              Cadastro</h3>

            <div class="switch switch-primary w-100 text-uppercase pb-4" style="display: none !important;">
              <label class="mr-2">perfil pessoal</label>
              <input type="checkbox" id="switch-a-1"
                (click)="selectTipoPerfil()">
              <label for="switch-a-1" class="cr"></label>
              <label class="ml-2">administrador</label>
            </div>
            <div class *ngIf="!exibirPessoal">
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Nome"
                  [(ngModel)]="nome" name="nome">
              </div>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="CPF"
                  [(ngModel)]="cpf" [textMask]="{mask: maskCpf}" name="cpf">
              </div>
              <div class="input-group mb-2">
                <input type="text" class="form-control"
                  placeholder="Data Nascimento" [(ngModel)]="dataNascimento"
                  [textMask]="{mask: maskData}" name="dataNascimento">
              </div>
              <div class="input-group mb-2">
                <input type="email" class="form-control" placeholder="E-mail"
                  [(ngModel)]="email" name="email">
              </div>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Telefone"
                  [(ngModel)]="telefone"
                  [textMask]="{mask: maskTeleArea}" name="telefone">
              </div>
              <div class="input-group mb-2">
                <input type="password" class="form-control" placeholder="Senha"
                  [(ngModel)]="senha" name="senha">
              </div>
              <div class="input-group mb-2">
                <input type="password" class="form-control"
                  placeholder="Confirmação de Senha"
                  [(ngModel)]="confirmacaoSenha" name="confirmacaoSenha">
              </div>
            </div>
            <div class *ngIf="exibirPessoal">
              <div class="input-group mb-2">
                <!-- CAMPO NOVO -->
                <input type="text" class="form-control"
                  placeholder="NOME EMPRESA/ADVOGADO/ESCRITÓRIO"
                  [(ngModel)]="nome" name="NOME EMPRESA/ADVOGADO/ESCRITÓRIO">
              </div>
              <div class="input-group mb-2 position-relative">
                <div class="position-absolute iconAlerta"
                  ngbTooltip="Ao inserir um CNPJ se torna opcional o preenchimento do campo &ldquo;número OAB&rdquo;"
                  placement="top">!</div>
                <!-- CAMPO NOVO -->
                <input type="text" class="form-control"
                  placeholder="CPF OU CNPJ"
                  [(ngModel)]="cpfCnpj"
                  name="cpf OU CNPJ">
              </div>
              <div class="input-group mb-2">
                <!-- CAMPO NOVO -->
                <input type="text" class="form-control"
                  placeholder="Número OAB (Ex.: UF 999999)"
                  [(ngModel)]="numeroOAB"
                  name="numeroOAB">
              </div>
              <div class="input-group mb-2">
                <input type="text" class="form-control"
                  placeholder="Data Nascimento" [(ngModel)]="dataNascimento"
                  [textMask]="{mask: maskData}" name="dataNascimento">
              </div>
              <div class="input-group mb-2">
                <input type="email" class="form-control" placeholder="E-mail"
                  [(ngModel)]="email" name="email">
              </div>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Telefone"
                  [(ngModel)]="telefone"
                  [textMask]="{mask: maskTeleArea}" name="telefone">
              </div>
              <div class="input-group mb-2">
                <input type="password" class="form-control" placeholder="Senha"
                  [(ngModel)]="senha" name="senha">
              </div>
              <div class="input-group mb-2">
                <input type="password" class="form-control"
                  placeholder="Confirmação de Senha"
                  [(ngModel)]="confirmacaoSenha" name="confirmacaoSenha">
              </div>
            </div>

            <!-- verificação de conta via WhatsApp -->
            <!-- <div class="input-group text-center form-group mb-4 d-mobile w-100" align="center">
              <p class="w-100 mb-0">COMO PREFERE CONFIRMAR SUA IDENTIDADE?</p>
              <div class="switch switch-primary d-inline w-100">
                <label class="mr-2">WhatsApp</label>
                <input type="checkbox" id="switch-a-1" checked="" (click)="util.openModal(modalValidarWhatsApp)">
                <label for="switch-a-1" class="cr"></label>
                <label class="ml-2">E-mail</label>
                </div>
            </div> -->

            <div class="col-md-12 mb-2 mt-3" align="center">
              <button class="btn btn-block button-rounded mb-4"
                (click)="validarEAbrirTermos()">
                <i class="feather icon-upload-cloud"></i>
                <strong>Cadastrar</strong></button>
            </div>
            <div class="acessoCadastro mt-2 text-uppercase">
              <a [routerLink]="['/login']" class="f-w-400 link-blue">Sou
                cadastrado</a>
              <span class="link-blue"> | </span>
              <a
                class="f-w-400 link-blue"
                (click)="mostrarRecuperacaoDeSenha()">Recuperar
                Senha</a>
            </div>
          </div>
          <div class="card-body" *ngIf="exibirRecuperacaoDeSenha">
            <img src="assets/images/logo-azul-2.png" alt
              class="img-fluid mb-4 d-block d-xl-none d-lg-none logoMobile">
            <h3
              class="text-center text-uppercase mb-4 f-w-400 link-blue">Recuperar
              Senha</h3>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i
                    class="feather icon-user"></i></span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="recuperarCpf"
                name="recuperarCpf" maxlength="11"
                placeholder="Cpf">
            </div>
            <button class="btn button-rounded col-md-8 col-sm-12 mb-3"
              style="margin: 0 auto;"
              (click)="enviarEmailRecuperacaoDeSenha()">
              <strong>Enviar</strong> </button>
            <!-- <div class="text-center">
              <div class="saprator my-4"></div>
              <br><br>
            </div> -->
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- [ change-password ] end -->
</div>

<app-ui-modal #modalTermosDeUso [hideFooter]="true"
  [dialogClass]="'modal-md modalChave'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo"> TERMOS E CONDIÇÕES DE USO</h5>
  </div>
  <div class="app-modal-body">
    <div class="col-md-12 divTermoDeUso">

      <div class="divTermoUsoTexto">

        <br />
        <b>ATENÇÃO:</b> Antes de contratar qualquer produto (Pagamento Único e
        Plano Assinatura) na plataforma Meu
        Testamento Digital, você deve ler atentamente os Termos e Condições
        abaixo delineadas, que descrevem com
        exatidão cada produto ofertado e as demais regras aplicáveis. AO CLICAR
        NO BOTÃO “REALIZAR PAGAMENTO” VOCÊ
        RECONHECE POSSUIR CAPACIDADE JURÍDICA PLENA PARA CONTRATAÇÃO DOS
        SERVIÇOS, BEM COMO ADERE E ACEITA
        EXPRESSAMENTE, DE FORMA IRRETRATÁVEL E INCONDICIONAL, TODOS OS TERMOS E
        CONDIÇÕES GERAIS E DEMAIS POLÍTICAS E
        PRINCÍPIOS QUE REGEM A PRESENTE CONTRATAÇÃO. Caso você não concorde com
        os termos propostos, não deve efetuar a
        contratação.

        <br /> <br />
        <b>PARTES</b>
        <br />
        Estes Termos e Condições compõem o contrato entre: LUMAGO SOLUTIONS
        LTDA, pessoa jurídica constituída sob a lei
        brasileira e inscrita no CNPJ sob o n. 27.652.671/0001-93, com sede na
        Rua dos Aimorés, n. 1101, bairro
        Funcionários, Belo Horizonte/MG - CEP 30.140-071, e-mail:
        lumago@lumago.com.br; doravante denominada
        “CONTRATADA”, e você, usuário dos Serviços, doravante denominado
        “USUÁRIO”.
        <br /> <br />

        <b>1.</b> Da Plataforma e seus conceitos básicos: O Meu Testamento
        Digital (a seguir também denominado
        “Produto”, “Plataforma” e “MTD”) é uma plataforma digital que
        possibilita ao usuário a juntada de mídias, tais
        como documentos, fotos, vídeos e áudios e a criação de um Testamento
        Particular assinado digitalmente por três
        testemunhas ou juntado como anexo na plataforma. A plataforma conta com
        roteiro para que o Usuário escolha os
        arquivos que deseja armazenar e um modelo para a criação de um
        Testamento Particular. Em caso de dúvidas, não
        deixe de entrar em contato com um advogado para orientação técnica
        especializada. Fazer um Testamento Particular
        pode demandar auxílio profissional.

        <br /> <br />
        <b>2.</b> Das Definições: Para correta compreensão da plataforma e dos
        produtos ofertados pela CONTRATADA,
        considera-se:

        <ul>
          <li> a) Usuário: É o adquirente dos serviços e responsável pelo
            preenchimento das informações que integram o
            Testamento Digital, bem como pela escolha das partes
            envolvidas.</li>
          <li> b) Testamento Digital: Conjunto de documentos, fotos, vídeos,
            áudios e senhas, podendo ou não conter um
            Testamento Particular assinado digitalmente por três testemunhas ou
            juntado como documento.</li>
          <li> c) Testamento Particular: Documento pelo qual o Usuário/Testador
            dispõe de seus bens para após sua morte,
            mediante assinatura de três testemunhas que conhecerão o conteúdo do
            Testamento particular. Esclarece-se que
            as testemunhas não têm acesso ao restante de seu Testamento Digital
            (vídeos, áudios, documentos, etc), mas
            tão somente ao Testamento Particular.</li>
          <li> d) Criptografia: É a conversão de dados de um formato legível
            para um formato codificado. Os dados
            criptografados só podem ser lidos ou processados depois de serem
            descriptografados a partir de uma Chave de
            Acesso. Essa é gerada após a criptografia.</li>
          <li> e) Testamento Digital Criptografado: É o conjunto de documentos,
            fotos, vídeos, áudios e senhas, podendo
            ou não conter um Testamento Particular, reunido em arquivo único e
            criptografado. O Testamento digital
            criptografado só poderá ser acessado através da Chave de
            Acesso.</li>
          <li> f) Chave de Acesso: Conjunto de letras, números e símbolos que
            permitem a descriptografia do Testamento
            Digital Criptografado.</li>
          <li> g) Beneficiário: Pessoa ou pessoas escolhidas pelo Usuário para
            receberem o Testamento Digital
            Criptografado e terem acesso ao seu conteúdo após a ocorrência de um
            sinistro.</li>
          <li> h) Executor: Pessoa ou pessoas escolhidas pelo Usuário para
            receberem tão somente a Chave de Acesso ao
            Testamento Digital Criptografado. O Executor tem como função
            armazenar a Chave de Acesso e entrega-la ao(s)
            Beneficiário(s) após a morte ou outro sinistro escolhido pelo
            Usuário. O recebimento da Chave de Acesso é
            condicionada ao aceite do Termo de Compromisso.</li>
          <li> i) Testemunhas: Optando o Usuário por realizar um Testamento
            Particular, cabe ao mesmo eleger três
            testemunhas, que conhecerão do conteúdo do Testamento Particular e
            assiná-lo de forma física ou digital. As
            testemunhas não tem acesso ao conjunto de documentos, fotos, vídeos,
            áudios e senhas, mas tão somente ao
            Testamento Particular criado pelo Usuário.</li>
          <li> j) Termo de Compromisso: Contrato aceito pelo Executor em que
            esse assume a obrigação de armazenar e
            fornecer a Chave de Acesso do Testamento Digital Criptografado ao(s)
            Beneficiário(s) eleito(s) após a
            ocorrência do(s) sinistro(s) eleito(s) pelo Usuário e constantes do
            Termo de Compromisso.</li>
          <li> k) Certidão do Testamento Digital: Certidão emitida pela
            plataforma com informações detalhadas acerca das
            partes envolvidas (testemunhas, executores e beneficiários), data de
            conclusão ou última alteração do
            Testamento digital e número e natureza dos arquivos
            armazenados.</li>
        </ul>

        <br /> <br />
        <b>
          3.</b> Do Objeto: Constitui objeto deste Contrato: (i)
        Disponibilização pela CONTRATADA ao USUÁRIO para uso da
        plataforma, a partir dos produtos ofertados (“Planos Disponíveis”); (ii)
        Armazenamento de dados e criptografia
        dos documentos armazenados; (iii) Formalização por assinatura digital do
        Testamento Particular criado pelo
        usuário e do Termo de Compromisso; (iv) Guarda e disponibilização dos
        arquivos armazenados para as partes
        envolvidas, na forma dos termos deste contrato.

        <br /> <br />
        <b>
          4. </b>Dos Planos disponíveis:
        <ul>
          <li> a) Pagamento Único: No Pagamento Único o Testamento Digital
            Criptografado é armazenado pelo Beneficiário
            após a finalização do Testamento Digital. Com isso, cabe ao
            Beneficiário guardar o Testamento Digital
            Criptografado e acessá-lo após o fornecimento da Chave de acesso
            pelo(s) Executor(es). Isso significa que,
            finalizado o processo, não é possível ao Usuário acrescentar
            quaisquer dados ao Testamento Digital
            Criptografado, já que o mesmo ficará na posse do Beneficiário. Nesse
            plano, a Plataforma armazena
            temporariamente o Testamento Digital Criptografado e disponibiliza
            meios para o seu correto download. Após o
            prazo de download do Testamento Digital Criptografado pelo(s)
            Beneficiário(s) e/ou Usuário, a plataforma
            descarta o referido arquivo, não havendo possibilidade de
            recuperação. Contudo, caso o usuário tenha
            formalizado um Testamento Particular, cópias do referido documento
            serão disponibilizados às Testemunhas.
          </li>
          <li> b) Plano Assinatura: No plano Assinatura o Testamento Digital
            Criptografado é armazenado na Plataforma,
            através de serviços de armazenamento em nuvem. Nesse plano, o
            Usuário poderá editar ou acrescentar arquivos
            ao seu Testamento Digital Criptografado. Na ocorrência de morte ou
            outro sinistro descrito no Termo de
            Compromisso assinado(a) pelo(s) Executor(es), caberá(ão) a este(s)
            acessar a plataforma e inserir a Chave de
            Acesso. Ao inserir a Chave de Acesso a plataforma encaminhará ao(s)
            Beneficiário(s) link para download do
            Testamento Digital Criptografado. Havendo interrupção de pagamento
            pelo Usuário, a plataforma notificará o
            Usuário de que o Testamento Digital Criptografado será
            disponibilizado ao(s) Beneficiário(s). Caso o Usuário
            não se manifeste acessando a plataforma e formalize o cancelamento,
            o Testamento Digital Criptografado será
            encaminhado para download do Beneficiário. No mesmo momento serão
            informados os dados do(s) Executor(es) e
            cópia do termo de compromisso assinado.</li>
        </ul>

        <br /> <br />
        <b>
          AVISO: Independentemente do plano escolhido, alerta-se que caso haja
          interesse na alteração do conteúdo do
          Testamento Particular finalizado e assinado, caberá ao USUÁRIO
          produzir um novo Testamento Particular,
          formalizado através da obtenção da assinatura de três testemunhas ou
          por qualquer outro instrumento apto a
          invalidar o Testamento Particular anterior.
        </b>

        <br /> <br />
        <b>
          4.1.</b> Obrigações da Contratada: a) Realizar a criptografia dos
        documentos armazenados durante todo o
        processo (upload e armazenamento final), os unificando em arquivo único
        e o disponibilizando para download nas
        hipóteses previstas no plano contratado; b) Fornecer ao Usuário e ao(s)
        Executor(es) a correta Chave de Acesso
        ao Testamento Digital Criptografado, viabilizando seu download pelo
        Beneficiário ou pelo próprio Usuário; c) A
        depender do plano, enviar e-mail de segurança ao Usuário, concedendo-o
        24 horas para impedir eventual tentativa
        de acesso ao seu Testamento Digital criptografado pelo Executor ou
        Beneficiário ou na hipótese de interrupção do
        pagamento do Plano Assinatura; d) Disponibilizar ao Usuário link para
        download do Testamento Digital
        Criptografado em caso de manifesta desistência do uso da plataforma.

        <br /> <br />
        <b>
          4.2.</b> Obrigações do Usuário: a) Preencher seus dados de forma
        completa e precisa; b) Realizar o pagamento
        do plano escolhido; c) Eleger as Testemunhas (se houver Testamento
        Particular), Executor(es) e seus
        Beneficiário(s) de seu Testamento Digital; d) Manter atualizados os
        dados de seu(s) Beneficiários; e) Caso opte
        por criar um Testamento Particular assinado fisicamente ou digitalmente
        responsabilizar-se pelo seu conteúdo; f)
        Caso opte por revogar um Testamento Particular já criado e formalizado,
        o Usuário deverá criar outro Testamento
        Particular indicando as alterações do Testamento anterior.

        <br /> <br />
        <b>
          5. </b>Do inadimplemento e da resolução do contrato: Constituem
        condição resolutiva do contrato firmado entre
        as partes, sem prejuízo da adoção de demais medidas administrativas e/ou
        judiciais cabíveis pela parte
        prejudicada, as seguintes situações:

        <br /> <br />
        <b>
          5.1.</b> Da falta de pagamento do Plano Assinatura: O USUÁRIO concorda
        e tem ciência de que o atraso do
        pagamento da prestação mensal, semestral ou anual que supere 15 dias
        corridos acarretará a sua notificação para
        regularização do inadimplemento, por meio das seguintes ações:

        <ul>
          <li> a) Encaminhamento de mensagem via e-mail ao USUÁRIO comunicando
            que, o não pagamento ou efetivo
            cancelamento do plano através do acesso ao sistema, ambos no prazo
            de 7 dias, resultará no envio de link ao
            Beneficiário para download do Testamento Digital Criptografado,
            acompanhado da Certidão do Testamento
            digital e Termo de Compromisso assinado pelo(s) Executor(es). O
            acesso do Beneficiário ao conteúdo do
            Testamento Digital Criptografado dependerá do fornecimento da Chave
            de Acesso pelo(s) Executor(es)</li>
          <li> b) Caso o Usuário pague o valor pendente ou desative seu plano, o
            Beneficiário não receberá o link para
            download do inventário digital criptografado.</li>
        </ul>

        <br /> <br />
        <b>
          5.2.</b> Da desistência do Usuário: Em caso de desistência do Usuário
        pelo uso da plataforma, será
        disponibilizado o prazo de 7 dias para que o mesmo realize o download do
        Testamento Digital Criptografado. Após
        o referido prazo a plataforma descartará o Testamento Digital
        Criptografado. De toda forma poderá ser fornecido
        Certidão que ateste o registro do Testamento Digital na Plataforma,
        mediante requisição do Usuário.

        <br /> <br />
        <b>
          5.3.</b> Da eventual descontinuidade da plataforma: Em caso de
        descontinuidade da plataforma, a CONTRATADA se
        compromete a fornecer os dados armazenados aos Usuários, através de
        e-mail com link para download dos arquivos.

        <br /> <br />
        <b>
          6. </b>Da Privacidade e Proteção de Dados Pessoais
        As condições descritas neste Termo só se aplicam ao tratamento dos dados
        pessoais do USUÁRIO no Brasil ou para
        produtos e serviços aqui ofertados, estando sujeitas às leis locais
        aplicáveis, com destaque para a Lei nº
        13.709/2018 (Lei Geral de Proteção de Dados Pessoais, ou "LGPD"). Logo:
        <ul>
          <li> a) A CONTRATADA, em NENHUMA HIPÓTESE, realizará o
            compartilhamento dos arquivos armazenados, salvo para
            os fins a que destina a plataforma (destinação para beneficiários
            eleitos pelo usuário).</li>
          <li> b) A CONTRATADA trata apenas dados cadastrais de identificação e
            acesso dos USUÁRIOS, nos termos do
            artigo 5º, X da LGPD, não se responsabilizando por quaisquer dados
            pessoais, sensíveis ou não, inseridos
            e/ou constantes no inventário, cabendo ao USUÁRIO a única e
            exclusiva responsabilidade pelo conteúdo dos
            referidos instrumentos;</li>
          <li> c) Não obstante, a CONTRATADA garante a inviolabilidade dos dados
            pessoais e demais informações
            constantes no inventário por meio de melhores técnicas de
            armazenamento digital e segurança da informação,
            incluindo, mas se limitando a: Armazenamento de arquivos em nuvem
            (cloud) a partir de provedores-operadores
            certificados (NIST, ISO, etc.), criptografia simétrica dos dados
            armazenados, confidencialidade das partes
            envolvidas na formalização do testamento digital.</li>
          <li> d) O tratamento de dados pode ser necessário para a correta
            identificação do USUÁRIO, para a formação de
            cadastros, para a execução de outros contatos, para cumprimento de
            obrigação legal, regulamentar ou outros.
          </li>
          <li> e) A CONTRATADA retém apenas os dados pessoais estritamente
            necessários a cumprir suas obrigações legais,
            regulatórias ou para finalidade comercial, respeitados os direitos
            fundamentais dos USUÁRIOS. A plataforma
            poderá reter Dados Pessoais por períodos mais longos do que o
            exigido por lei se for de nosso interesse
            comercial legítimo e desde que não seja proibido por lei;</li>
          <li> f) Assim o Usuário declara expressamente sua ciência com a
            utilização pela CONTRATADA, a partir da base
            legal de legítimo interesse, das informações cadastrais e do
            histórico de navegabilidade no site da
            Plataforma, para os fins de elaboração e mostra de publicidade e
            para a oferta de produtos e/ou serviços da
            contratada;</li>
          <li> g) Entretanto, a CONTRATADA não compartilha nenhum dado pessoal
            coletado do USUÁRIO para finalidades
            estranhas àquelas definidas neste Termo ou em seus Avisos de
            Privacidade. Na hipótese de compartilhamento
            com terceiro não estipulado inicialmente, a CONTRATADA coletará o
            consentimento do USUÁRIO em consonância
            com o artigo 7º, I da LGPD;</li>
          <li> h) A CONTRATADA não se responsabiliza pela obtenção, guarda ou
            revogação de consentimentos para
            tratamento de dados pessoais constantes no inventário, bem como
            validação e eficácia das vontades
            expressadas no documento sucessório, cabendo exclusivamente ao
            USUÁRIO observar os requisitos legais para
            produção dos efeitos desejados;</li>
          <li> i) A CONTRATADA não compartilha com terceiros, para finalidade
            comercial ou cadastral, os dados pessoais
            coletados para acesso e identificação de seus serviços;</li>
          <li> j) A CONTRATADA trata dados pessoais a partir do consentimento,
            para cumprimento de obrigação legal ou
            dos contratos ofertados ou ainda em legítimo interesse próprio, do
            titular ou de terceiros, obedecendo em
            todas as hipóteses as garantias de privacidade impostas pela
            legislação;</li>
          <li> k) O USUÁRIO ou qualquer outro titular de dados pessoais pode
            exercer os direitos previstos no artigo 18
            da LGPD, mediante requisição ao e-mail
            contato@meutestamentodigital.com, obedecido o prazo legal de
            resposta
            previsto na mesma legislação;</li>
        </ul>

        <br /> <br />
        <b>
          7. </b>Das Disposições Gerais:
        <ul>
          <li> a) Ao utilizar a plataforma o(a) USUÁRIO(a) declara conhecer a
            legislação brasileira atinente ao direito
            sucessório brasileiro, comprometendo-se à respeitá-la e agir dentro
            dos limites legais.</li>
          <li> b) O USUÁRIO declara ter ciência que a validade do Testamento
            Particular criado na plataforma MTD depende
            da validação judicial através de um processo judicial de
            inventário.</li>
          <li> c) O USUÁRIO declara que conhece as normas atinentes ao direito
            hereditário brasileiro e que disporá de
            seus bens dentro dos parâmetros legais vigentes.</li>
          <li> d) O Usuário declara que tem ciência que o conteúdo de eventual
            Testamento Particular criado na
            plataforma será compartilhado com as três testemunhas eleitas pelo
            mesmo.</li>
          <li> e) Consoante item 4, o Usuário declara que tem ciência que a
            revogação de um Testamento Particular
            depende da criação de outro Testamento Particular, com expressa
            previsão nesse sentido.</li>
          <li> f) O USUÁRIO declara ter ciência da importância de um
            profissional habilitado (advogado) quando da
            elaboração de um Testamento particular.</li>
          <li> g) A CONTRATADA informa que preza pela proteção e preservação da
            integridade das informações pessoais do
            Usuário e que todo arquivo em sua guarda não será em nenhuma
            circunstância acessado, compartilhado,
            formatado ou utilizado para qualquer finalidade.</li>
        </ul>
        <br /> <br />
        <b>
          8. </b>Da isenção de responsabilidade da CONTRATADA: A CONTRATADA não
        se responsabiliza: (i) por qualquer
        indisponibilidade de acesso à internet, bem como por eventuais erros de
        cadastro ou falhas de acesso à
        Plataforma; (ii) por erros ou inconsistências na transmissão de dados ou
        pela qualidade ou disponibilidade de
        sinal de Internet, que possam afetar o uso da Plataforma; e (iii) por
        quaisquer danos e/ou prejuízos decorrentes
        do conhecimento não autorizado, por terceiros, de qualquer informação
        fornecida por meio da Plataforma, e que
        não sejam causados por uma incidente de segurança da informação da
        CONTRATADA. Por tal razão:
        <ul>
          <li> a) O USUÁRIO está ciente de que a CONTRATADA recomenda o uso de
            ferramentas e de tecnologia (hardware
            e/ou software) devidamente seguros e atualizados. Qualquer uso,
            pelo(a)s USUÁRIO(a)s, de ferramentas e
            tecnologias desatualizadas e não consideradas seguras ocorrerá por
            quaisquer danos sofridos pelo(a)s
            Usuário(a)s.</li>
          <li> b) Em nenhuma hipótese a CONTRATADA será responsável por
            quaisquer danos indiretos, de qualquer natureza,
            que possam, ser atribuíveis ao uso ou à incapacidade de uso do
            Portal, qualquer conteúdo publicado na
            Plataforma, ou destes Termos de Uso, dos Serviços ou quaisquer
            informações, dados ou software prestado
            através da sua página eletrônica, mesmo se advertido sobre a
            possibilidade de tais danos ou no caso de tais
            danos serem previsíveis.</li>
          <li> c) A CONTRATADA não poderá ser responsabilizada por quaisquer
            danos morais e materiais diretos,
            indiretos, incidentais, especiais, consequenciais ou punitivos que
            não tenham sido causados direta e
            imediatamente pela Plataforma ou por seus/suas funcionário(a)s,
            incluindo, mas não se limitando a perdas e
            danos, lucros cessantes, perda de uma chance, outras perdas e danos
            intangíveis, relacionados ao uso da
            Plataforma, nem com relação à incapacidade e/ou impossibilidade de
            usá-los.</li>
          <li>d) A CONTRATADA trabalha e se esforça para manter a Plataforma e
            demais produtos em funcionamento,
            contudo, todos os serviços online estão sujeitos a interrupções e
            paradas ocasionais. A CONTRATADA não se
            responsabiliza por nenhuma interrupção ou perda que o USUÁRIO possa
            sofrer em decorrência de tais
            interrupções ou paradas.</li>
        </ul>
        <br /> <br />
        <b>
          9. </b>Cessão: É vedada a cessão, total ou parcial, dos direitos e
        obrigações assumidos pelas Partes em razão
        deste contrato sem a prévia e expressa anuência da outra Parte.

        <br /> <br />
        <b>
          10.</b> Alterações Futuras: A CONTRATADA reserva-se no direito de
        alterar este ou demais instrumentos que
        regem seu relacionamento com o USUÁRIO. Essas alterações serão
        realizadas automaticamente na(s) data(s) de
        entrada em vigor correspondente(s) indicada(s) na página de visualização
        deste documento.

        <br /> <br />
        <b>
          11.</b> Do Foro: O presente Contrato é regido pela legislação da
        República Federativa do Brasil e as Partes,
        de comum acordo, elegem o Foro da Comarca de Belo Horizonte/MG para
        solução de qualquer controvérsia sobre o
        presente instrumento, renunciando qualquer outro por mais privilegiado
        que seja.
        <br />

      </div>
      <br>
      <div class="aceitarTermo">
        <input type="checkbox" [(ngModel)]="termoDeUsoAceito"
          name="termoDeUsoAceito" id="termoDeUsoAceito" />

        <label style="font-weight: bold" for="termoDeUsoAceito">Declaro ter
          lido, compreendido e aceito os termos
          acima.</label>
      </div>

      <div
        class="row text-center d-flex align-items-center justify-content-center m-2">
        <div align="center" style="float: left;" class="col-6">
          <button class="btn btnCancelar w-100"
            (click)="util.closeModal(modalTermosDeUso)"
            style="background-color: #999 !important; border-radius: 20px;">
            <i class="fa" aria-hidden="true"></i>
            Voltar
          </button>
        </div>
        <div align="center" style="float: right;" class="col-6">
          <button class="btn button-rounded w-100" (click)="cadastrar()">
            <i class="fa" aria-hidden="true"></i>
            Salvar
          </button>
        </div>

      </div>
    </div>
  </div>

</app-ui-modal>

<!-- Modal validação via WhatsApp    -->
<app-ui-modal #modalValidarWhatsApp [hideFooter]="true"
  [dialogClass]="'modal-md modalChave'" class="modalMobile">
  <div class="app-modal-header text-center w-100">
    <h3 class="f-w-400 text-blue mb-0">Bem vindo</h3>
    <button type="button" class="close basic-close" data-dismiss="modal"
      aria-label="Close"
      #botaoFecharmodalValidarWhatsApp
      (click)="util.closeModal(modalValidarWhatsApp)"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">

      <div class="col-md-12 mt-3">
        <p class="text-center mb-0">
          O seu cadastro foi realizado com sucesso!
        </p>
        <p class="text-center">
          Para continuar informe o código recebido pelo WhatsApp.
        </p>
      </div>

      <div class="col-md-12 mb-2 mt-3">
        <div _ngcontent-dlv-c210
          class="form-group mx-sm-3 d-flex justify-content-around">
          <input type="text" id="inputPassword2" maxlength="1"
            class="form-control col-2">
          <input type="text" id="inputPassword3" maxlength="1"
            class="form-control col-2">
          <input type="text" id="inputPassword4" maxlength="1"
            class="form-control col-2">
          <input type="text" id="inputPassword5" maxlength="1"
            class="form-control col-2">
        </div>
      </div>

      <div class="col-md-12 mt-4 mb-4">
        <button type="button" class="btn button-rounded col-md-6"
          style="margin: 0 auto;">
          Confirmar código
        </button>
      </div>

    </div>

  </div>
</app-ui-modal>
<!-- fim Modal validação via WhatsApp    -->

<button type="button" class="close basic-close" aria-label="Close"
  style="display: none; width: 0px; height: 0px"
  #btnExibirModalTermosDeUso
  (click)="util.openModal(modalTermosDeUso)">.</button>

<button type="button" class="close basic-close" aria-label="Close"
  style="display: none; width: 0px; height: 0px"
  #btnFecharModalTermosDeUso
  (click)="util.closeModal(modalTermosDeUso)">.</button>

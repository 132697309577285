<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<app-ui-modal #modalTermos [hideFooter]="true" [dialogClass]="'modal-xl'">
  <div class="app-modal-header">
    <h2 class="modal-title titulo">Termo de Aceite</h2>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalTermos)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">


    <div class="htmlTermo" [innerHTML]="textoTermo | SafePipe"></div>




    <br>
    <div class="form-check text-muted mt-3 mb-3"  align="center" style="cursor: pointer;">
      <input class="form-check-input" type="checkbox" [(ngModel)]="aceiteTermo" id="aceiteTermo">
      <label for="aceiteTermo" class="form-check-label"><b>CONCORDO COM OS TERMOS ACIMA</b>
      </label>
    </div>
    <br> <br>

    <div class="row d-flex align-items-center justify-content-center mb-4">

        <button type="button" class="btn btnVoltar col-md-3 m-2" (click)="util.closeModal(modalTermos)">
          VOLTAR
        </button>
        <button type="button" class="btn button-rounded col-md-3 m-2" (click)="salvarAceite(modalTermos)">
          LI E CONCORDO COM OS TERMOS
        </button>
        <button type="button" class="btn button-rounded-green btnDownload col-md-3 m-2" (click)="downloadTermo()">
          DOWNLOAD
        </button>

    </div>


  </div>
</app-ui-modal>

<div class="auth-wrapper aut-bg-img">
  <div class="blur-bg-images"></div>
  <!-- [ change-password ] start -->
  <div class="auth-content col-md-6" align="center">
    <div class="card">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="card-body">

            <div class="col-md-12">
              <img src="assets/images/logo/logo-only.png" alt="" class="img-fluid mb-4">
            </div>
            <h4 class="mb-4 f-w-400">ACEITE DO TERMO DE COMPROMISSO</h4>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Testamento</th>
                    <th>Data Aceite</th>
                    <th class="col-2">Aceitar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of arrayTestamentos">
                    <td>{{item.nomeTestador }}</td>
                    <td>{{item?.dataAceite | date: "dd/MM/yyyy HH:mm"}}</td>
                    <td class="col-2">

                      <button *ngIf="item.dataAceite == null" type="button" class="btn btn-icon btn-success btnAceitar"
                        (click)="acaoAceitar(modalTermos, item)">
                        <i class="feather icon-check-circle"></i>
                      </button>

                      <i *ngIf="item.dataAceite != null" class="feather icon-check-circle text-success"></i>

                      <!--<button type="button" class="btn btn-icon btn-success " (click)="acaoAceitar(modalTermos, item)">
                        <i class="feather icon-check-circle"></i>
                      </button>-->

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <button class="btn button-rounded col-md-3" (click)="voltarLogin()" style="margin: 0 auto;"> <strong>SAIR</strong> </button>
            </div>

            <!--<br>
              <button class="btn btn-block button-rounded-green  mb-4" (click)="acaoAceitar()"><strong> ACEITAR </strong></button>
              <button class="btn btn-block button-rounded-gray  mb-4" (click)="acaoReprovar()"><strong> REPROVAR </strong></button>
            -->
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- [ change-password ] end -->
</div>
<div class="d-none-sm">
    <div class="row img-topo img-leitura" style="height: 16vh;">
        <p>
          Nosso sistema oferece um ambiente para reuniões com o Testador e Testemunhas, onde é recomendado que o Testador leia o testamento. A reunião pode ser gravada e não deve ultrapassar 15 minutos. Terminada a gravação, o vídeo será salvo automaticamente. Esse procedimento é essencial para atestar a genuína vontade do Testador, assegurando que a leitura do testamento seja feita por ele na presença de testemunhas e registrada em vídeo.
        </p>
    </div>

    <div class="row align-items-center"
        style="padding: 0 0 14px 0; margin:0px;">

        <div class="col-md-12 pr-0">
            <a class="float-right">

                <button type="button" class="btn btn-icon btn-outline-info"
                    (click)="adicionarGravacao()">
                    <i class="feather icon-plus"></i>iniciar gravação</button>

                    <button type="button" #btnAbrirModalAddGravacao style="display: none !important; width:0px; height:0px;" class="btn btn-icon btn-outline-info"
                    (click)="util.openModal(modalLeitura)"></button>
            </a>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Url</th>
                    <th>Data/Hora Criação</th>
                    <th>Disponível</th>
                    <th class="headerAcoes">Ações</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayMeetings">
                <td>{{item.url}}</td>
                <td>{{item.dataInclusao | date: "dd/MM/yyyy HH:mm"}}</td>
                <td>{{item.isArquivoDisponivel ? "Sim" : "Não"}}</td>
                <td class="gridBtnAcoes">
                <a *ngIf="item.urlDownload != '' && item.urlDownload != null" target="_blank" href="{{item.urlDownload}}"><i  class="feather icon-eye"></i></a>
                <button type="button" (click)="apagarMeeting(item.id,item.idTestamento)" class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
              </td>
              </tr>
            </tbody>
        </table>

        <div class="col-md-12 mt-5">
            <button type="button" class="btn button-rounded col-md-3"
                style="margin: 0 auto;"
                (click)="irParaAvancar()">
                <i class="feather icon-arrow-right"></i>AVANÇAR
            </button>
        </div>
    </div>
</div>

<app-ui-modal #modalLeitura [hideFooter]="true" [dialogClass]="'modal-md'"
    class="modalMobile">
    <div class="app-modal-header">
        <h5 class="modal-title titulo">CRIAR O CONVITE PARA REUNIÃO</h5>
        <button style="cursor: pointer !important;" type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" #botaoFecharModalLeitura
            (click)="util.closeModal(modalLeitura)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">

            <div class="col-md-12 mt-3">
                <p>ENVIE O LINK ABAIXO PARA OS PARTICIPANTES E EM SEGUIDA, CLIQUE EM INICIAR REUNIÃO</p>
            </div>
            <div (click)="copiarLink()" class="col-md-12 mt-3 d-flex">
                <label style="position: absolute; z-index: -1;"></label>

                <input (click)="copiarLink()" type="email" class="form-control"
                    placeholder="{{meetingLink}}">
                <div  (click)="copiarLink()" class="btnCopy" placement="top" tooltipClass="tooltipTestemunhas"
                    ngbTooltip="Clique para copiar o link da reunião">
                    <i style="cursor: pointer !important;" (click)="copiarLink()" class="feather icon-copy"></i>
                </div>
            </div>

            <div class="col-md-12 mt-4 mb-2">
                <button type="button" class="btn button-rounded col-md-6"
                    style="margin: 0 auto;">
                    <i style="color:#fff" class="feather icon-arrow-right"></i>
                   <a style="color:#fff" href="{{this.meetingLink}}" target="_blank"> INICIAR REUNIÃO</a>
                </button>
            </div>

        </div>

    </div>
</app-ui-modal>


<app-ui-modal #modalLeituraReuniao [hideFooter]="false" [dialogClass]="'modalLeituraReuniao'"
    class="modalMobile">
    <div class="app-modal-header">
        <h5 class="modal-title titulo">Reunião de Leitura do Testamento</h5>
        <button type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" #botaoFecharmodalLeituraReuniao
            (click)="util.closeModal(modalLeituraReuniao)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">

            <div class="col-md-12 mt-2">
                <h5 class="mb-0">TESTAMENTO FULANO DE TAL</h5>
                <p class="mb-0">Segunda-feira, 05/07/2023</p>
            </div>
            <div class="col-md-12 mt-2 d-flex align-items-center justify-content-center">
                <img src="/assets/images/teste-leitura.png" alt="">
            </div>

        </div>
    </div>
    <div class="app-modal-footer">
        <div class="row gridBtnAcoes">
                <button type="button" class="btn btn-outline-danger">
                    <i class="feather icon-link corAzul"></i>
                    LINK DA REUNIÃO
                </button>
                <button type="button" class="btn btn-outline-danger">
                    <i class="feather icon-video-off corPrincipal"></i>
                    gravar leitura
                </button>
                <button type="button" class="btn btn-outline-danger">
                    <i class="feather icon-check-circle corVerde"></i>
                    usar gravação
                </button>
                <button type="button" class="btn btn-outline-danger">
                    <i class="feather icon-log-out corSecundaria"></i>
                    finalizar reunião
                </button>
        </div>
    </div>
</app-ui-modal>

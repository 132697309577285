import { API, TIPO_TESTAMENTO } from "src/environments/environment";
import { AjaxService } from "../service/ajax.service";
import { UtilService } from "../service/util.service";
import * as moment from 'moment';

export class Testamento {
  ComplementoTestamento: string;
  Testemunhas: Testemunha[];
  Assinatura3Testemunhas: boolean;
  AssinaturaDigital: boolean;
  HaveraDisposicaoBens: boolean;
  IdTestamento: string;

  constructor(
    ComplementoTestamento: string,
    Testemunhas: Testemunha[],
    Assinatura3Testemunhas: boolean,
    AssinaturaDigital: boolean,
    HaveraDisposicaoBens: boolean,
    idTestamento: string,

    private util?: UtilService,
    private ajax?: AjaxService
  ) {
    this.ComplementoTestamento = ComplementoTestamento;
    this.Testemunhas = Testemunhas;
    this.Assinatura3Testemunhas = Assinatura3Testemunhas;
    this.AssinaturaDigital = AssinaturaDigital;
    this.HaveraDisposicaoBens = HaveraDisposicaoBens;
    this.IdTestamento = idTestamento;
  }

  public validaAddTestemunha(objTestemunha: Testemunha): boolean {

    if (objTestemunha.Nome == "") {
      this.util.toasterShowError("Atenção", "O Campo Nome não pode ser Vazio!");
      return false;
    }

    if (this.util.removeMask(objTestemunha.CPF) == "" || this.util.removeMask(objTestemunha.CPF).length < 11) {
      this.util.toasterShowError("Atenção", "O Campo CPF não pode ser Vazio ou estar incompleto!!");
      return false;
    }

    if (objTestemunha.DataNascimento == undefined || this.util.removeMask(objTestemunha.DataNascimento) == "" || this.util.removeMask(objTestemunha.DataNascimento).length < 8) {
      this.util.toasterShowError("Atenção", "O Campo Data Nascimento não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (objTestemunha.Email == "") {
      this.util.toasterShowError("Atenção", "O Campo Email não pode ser Vazio!");
      return false;
    }

    if (objTestemunha.Telefone == undefined || this.util.removeMask(objTestemunha.Telefone) == "" || this.util.removeMask(objTestemunha.Telefone).length < 11) {
      this.util.toasterShowError("Atenção", "O Campo Telefone não pode ser Vazio ou estar incompleto!!");
      return false;
    }

    if(!moment(objTestemunha.DataNascimento , 'DD/MM/YYYY',true).isValid()){
      this.util.toasterShowError("Atenção", "Erro na data de nascimento, data inválida!");
      return false;
    }

    return true;
  }


  public addTestemunha(objTestemunha: Testemunha) {
    if (this.validaAddTestemunha(objTestemunha))
      this.Testemunhas.push(objTestemunha);
  }

  public montarTestemunhaJson(objTestemunha: Testemunha) {
    if (this.validaAddTestemunha(objTestemunha)) {
      return {
        "idTestamento": this.IdTestamento,
        "pessoas": [{
          "nome": objTestemunha.Nome,
          "email": objTestemunha.Email,
          "telefone": this.util.removeMask(objTestemunha.Telefone),
          "cpf": this.util.removeMask(objTestemunha.CPF),
          "dataNascimento": this.util.formatDateMaskSave(objTestemunha.DataNascimento),
        }]
      }
    }
  }

  public montarTestamentoDisposicaoBensJson() {
    return {
      "idTipoTestamento": this.HaveraDisposicaoBens ? TIPO_TESTAMENTO.comDisposicaoBens : TIPO_TESTAMENTO.semDisposicaoBens
    }
  }

  VerificarTestamento(): any {
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url);
  }

}

export class Testemunha {
  Nome: string;
  CPF: string;
  DataNascimento: string;
  Email: string;
  Telefone: string;
}


<app-ui-modal #modalExcecutor [hideFooter]="true" [dialogClass]="'modal-md'" class="modalMobile">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ADICIONAR EXECUTOR</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" #botaoFecharModalExecutor
      (click)="util.closeModal(modalExcecutor)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">

      <!-- <div class="col-md-12">
        <div class="form-check text-muted mt-4">
          <input class="form-check-input" type="checkbox" id="gridDesativarCPF" (click)="toggleDataDesativarExecutor()">
          <label class="form-check-label" for="gridDesativarCPF">NÃO DESEJO INFORMAR CPF E DATA DE NASCIMENTO</label>
        </div>
      </div> -->

      <div class="col-md-12" *ngIf="desativarCamposExecutor">
        <input type="text" class="form-control fundoInput" (keyup)="verificarSeUsuarioExisteAsync('executor')"
          [(ngModel)]="linhaExecutor.CPF" name="linhaExecutor.CPF" placeholder="CPF - Comece por aqui" [textMask]="{mask: maskCPF}">
      </div>

      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteExecutor"
          [(ngModel)]="linhaExecutor.Nome" name="linhaExecutor.Nome" placeholder="Nome Completo">
      </div>

      <div class="col-md-12" *ngIf="desativarCamposExecutor">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteExecutor"
          [(ngModel)]="linhaExecutor.DataNascimento" name="linhaExecutor.DataNascimento" placeholder="Data Nascimento"
          [textMask]="{mask: maskData}">
      </div>
      <div class="col-md-12">
        <input type="email" class="form-control fundoInput" [disabled]="isRegistroExisteExecutor"
          [(ngModel)]="linhaExecutor.Email" name="linhaExecutor.Email" placeholder="E-mail">
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteExecutor"
          [(ngModel)]="linhaExecutor.Telefone" name="linhaExecutor.Telefone" placeholder="Telefone"
          [textMask]="{mask: maskTelefoneCelular}">
      </div>
      <!-- <div class="col-md-12">
        <div class="form-check text-muted mt-3">
          <input class="form-check-input" type="checkbox" id="gridRadiosAssinaturaWhatsapp">
          <label class="form-check-label" for="gridRadiosAssinaturaWhatsapp">Colher assinatura via WhatsApp</label>
        </div>
      </div> -->

      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;" (click)="addExecutor()">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
      <div class="d-mobile lineDividerH"></div>

      <div class="d-mobile justify-content-center col-md-12 mt-3 mb-2" align="center">
        <p class="titulo mb-0">Já adicionado(s)</p>
      </div>

      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" *ngFor="let item of arrayExecutor">

        <div class="col-10">
          {{item.nome}}
        </div>
        <div class="col-1">
          <button type="button" (click)="deletarExecutor(item.guid,item.idTestamento)"
            class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>
      <div class="d-mobile m-2"></div>

    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalBeneficiario [hideFooter]="true" [dialogClass]="'modal-md'" class="modalMobile">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ADICIONAR BENEFICIÁRIO</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      #botaoFecharModalBeneficiario (click)="util.closeModal(modalBeneficiario)"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">

      <!-- <div class="col-md-12">
        <div class="form-check text-muted mt-4">
          <input class="form-check-input" type="checkbox" id="gridDesativarCPF"
            (click)="toggleDataDesativarBeneficiario()">
          <label class="form-check-label" for="gridDesativarCPF">NÃO DESEJO INFORMAR CPF E DATA DE NASCIMENTO</label>
        </div>
      </div> -->

      <div class="col-md-12" *ngIf="desativarCamposBeneficiario">
        <input type="text" class="form-control fundoInput" (keyup)="verificarSeUsuarioExisteAsync('beneficiario')"
          [(ngModel)]="linhaBeneficiario.CPF" name="linhaBeneficiario.CPF" placeholder="CPF - Comece por aqui"
          [textMask]="{mask: maskCPF}">
      </div>

      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteBeneficiario"
          [(ngModel)]="linhaBeneficiario.Nome" name="linhaBeneficiario.Nome" placeholder="Nome Completo">
      </div>

      <div class="col-md-12" *ngIf="desativarCamposBeneficiario">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteBeneficiario"
          [(ngModel)]="linhaBeneficiario.DataNascimento" name="linhaBeneficiario.DataNascimento"
          placeholder="Data Nascimento" [textMask]="{mask: maskData}">
      </div>

      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteBeneficiario"
          [(ngModel)]="linhaBeneficiario.Email" name="linhaBeneficiario.Email" placeholder="E-mail">
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [disabled]="isRegistroExisteBeneficiario"
          [(ngModel)]="linhaBeneficiario.Telefone" name="linhaBeneficiario.Telefone" placeholder="Telefone"
          [textMask]="{mask: maskTelefoneCelular}">
      </div>
      <!-- <div class="col-md-12">
        <div class="form-check text-muted mt-3">
          <input class="form-check-input" type="checkbox" id="gridRadiosAssinaturaWhatsappB">
          <label class="form-check-label" for="gridRadiosAssinaturaWhatsappB">Colher assinatura via WhatsApp</label>
        </div>
      </div> -->

      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;" (click)="addBeneficiario()">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
      <div class="d-mobile lineDividerH"></div>

      <div class="d-mobile justify-content-center col-md-12 mt-3 mb-2" align="center">
        <p class="titulo mb-0">Já adicionado(s)</p>
      </div>

      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" style="text-transform: uppercase;"
        *ngFor="let item of arrayBeneficiario">

        <div class="col-9">
          {{item.nome}}
        </div>
        <div class="col-1">
          <input type="checkbox" (click)="alterarBeneficiarioCienciaTestamento($event,item.guid,item.idTestamento)"
            [(checked)]="item.cienteTestamento"
            ngbTooltip="Notifica o BENEFICIÁRIO sobre a existência do TESTAMENTO DIGITAL assim que o mesmo for pago e gerado pelo sistema."
            placement="top">
        </div>
        <div class="col-1">
          <button type="button" (click)="deletarBeneficiario(item.guid,item.idTestamento)"
            class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>
      <div class="d-mobile m-2"></div>

    </div>

  </div>
</app-ui-modal>


<div class="d-none-sm">
  <ul class="nav nav-tabs profile-tabs nav-fill" style="margin-top:-37px;">
    <li class="nav-item">
      <a class="nav-link text-reset" [ngClass]="{'myActive': this.activeTabExcec === 'executores'}"
        (click)="this.activeTabExcec = 'executores'" data-toggle="tab" href="javascript:void" role="tab">
        <!--<i class="feather icon-home mr-2"></i>-->EXECUTORES ( {{arrayExecutor?.length}} )</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-reset" [ngClass]="{'myActive': this.activeTabExcec === 'beneficiarios'}"
        (click)="this.activeTabExcec = 'beneficiarios'" data-toggle="tab" href="javascript:void" role="tab">
        <!--<i class="feather icon-user mr-2"></i>-->BENEFICIÁRIOS ( {{arrayBeneficiario?.length}} )</a>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade" [ngClass]="{'show active': this.activeTabExcec === 'executores'}" role="tabpanel">

      <div class="row align-items-center" style="padding: 30px 0px 14px 0px; margin:0px;">

        <div class="row img-topo img-executores">
          <!-- <p>Escolha seu(s) EXECUTOR(ES). O EXECUTOR receberá somente a CHAVE DE ACESSO ao TESTAMENTO DIGITAL
            criptografado após aceitar o <a href='{{urlDownloadTermoExecutor}}'
              style="font-style: italic; text-decoration: underline;">TERMO DE COMPROMISSO</a>. O EXECUTOR não tem
            acesso ao seu TESTAMENTO DIGITAL. </p> -->
          <p>Prezado(a) Advogado(a), escolha até cinco Executores. O Executor armazenará a chave de acesso ao Testamento Digital Criptografado, devendo informá-la no sistema após a ocorrência de algum sinistro com o Testador. O Executor receberá a Chave após assinar o termo de compromisso. O Executor não tem acesso ao conteúdo do TESTAMENTO DIGITAL. </p>
        </div>

        <!-- <div *ngIf="isAdvogadoUtilizando" class="col-md-9 pr-0">
          <a class="float-right text-muted btnSerExecutor">
            <button type="button" class="btn btn-icon btn-warning" (click)="tornarAdvogadoUmExecutor()">
              <i class="feather icon-plus"></i>Tornar-se um EXECUTOR</button>
          </a>
        </div> -->

        <div class="gridBtnAdd col-md-12 pr-0">
          <a class="float-right text-muted btnSerExecutor" *ngIf="isAdvogadoUtilizando">
            <button type="button" class="btn btn-icon btn-warning" (click)="tornarAdvogadoUmExecutor()">
              <!-- <i class="feather icon-plus"></i> -->
              Tornar-se um EXECUTOR</button>
          </a>
          <a class="float-right text-muted">
            <button type="button" class="btn btn-icon btn-outline-info" (click)="util.openModal(modalExcecutor)">
              <i class="feather icon-plus"></i>Adicionar EXECUTOR</button>
          </a>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nome Completo</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr title="CPF:{{item.cpf}}" *ngFor="let item of arrayExecutor">
              <td>{{item.nome}}</td>
              <td>{{item.email}}</td>
              <td>{{item.telefone}}</td>
              <td class="col-1 bloquearNaEdicao">
                <button type="button" (click)="deletarExecutor(item.guid,item.idTestamento)"
                  class="btn btn-outline-danger bloquearNaEdicao"><i
                    class="feather icon-trash bloquearNaEdicao"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <div class="row align-items-center" style="margin:0px;">
        <div class="col">

          <strong class="text-muted">
            <i class="fas fa-hand-holding f-40"></i>
            Escolha a(s) hipótese(s) em que o(s) EXECUTOR(ES) deverá entregar a CHAVE DE ACESSO ao(s) BENEFICIÁRIO(S) ou
            Comunicar o Sinistro na plataforma</strong>
          <br><br>
          <div class="form-check text-muted">
            <input disabled type="checkbox" class="form-check-input" type="checkbox" id="gridRadios1"
              (click)="setFormaEntregaChave($event)" value="dff963a1-cbc8-4e70-8a75-248a0a221dc0"
              checked="{{entregaChaveCadastrada('dff963a1-cbc8-4e70-8a75-248a0a221dc0')}}">
            <label class="form-check-label" for="gridRadios1">MORTE</label>
          </div>
          <div class="form-check text-muted">
            <input class="form-check-input" type="checkbox" id="gridRadios2" (click)="setFormaEntregaChave($event)"
              value="dff963a1-cbc8-4e70-8a75-248a0a221dc1"
              checked="{{entregaChaveCadastrada('dff963a1-cbc8-4e70-8a75-248a0a221dc1')}}">
            <label class="form-check-label" for="gridRadios2">ACIDENTE QUE IMPOSSIBILITE A MANIFESTAÇÃO DE
              VONTADE POR MAIS DE 48 HORAS
            </label>
          </div>
          <div class="form-check text-muted">
            <input class="form-check-input" type="checkbox" id="gridRadios3" (click)="setFormaEntregaChave($event)"
              value="dff963a1-cbc8-4e70-8a75-248a0a221dc2"
              checked="{{entregaChaveCadastrada('dff963a1-cbc8-4e70-8a75-248a0a221dc2')}}">
            <label class="form-check-label" for="gridRadios3">DESAPARECIMENTO DO USUÁRIO POR MAIS DE 30
              DIAS</label>
          </div>
        </div>
      </div>


      <div class="col-md-12 mt-5">
        <button type="button" class="btn  button-rounded col-md-3" style="margin: 0 auto;"
          (click)="irParaBeneficiarios()">
          <i class="feather icon-arrow-right"></i>AVANÇAR
        </button>
      </div>


    </div>

    <div class="tab-pane fade" [ngClass]="{'show active': this.activeTabExcec === 'beneficiarios'}" role="tabpanel">

      <div class="row align-items-center" style="padding: 30px 0px 14px 0px; margin:0px;">

        <div class="row img-topo img-beneficiarios">
          <p>Escolha seus BENEFICIÁRIOS. O BENEFICIÁRIO é a pessoa que receberá o seu TESTAMENTO DIGITAL após o
            fornecimento da CHAVE DE ACESSO pelo(s) Executor(es). Caso queira informar o(s) BENEFICIÁRIO(s) da
            existência do TESTAMENTO DIGITAL, basta marcar o ícone “Notificar”.</p>
        </div>
        <div class="col-md-12 pr-0">
          <a class="float-right text-muted ">
            <button type="button" class="btn btn-icon btn-outline-info" (click)="util.openModal(modalBeneficiario)">
              <i class="feather icon-plus"></i>Adicionar BENEFICIÁRIO</button>
          </a>

        </div>
      </div>


      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="col-sm-1"
                title="Notifica o BENEFICIÁRIO sobre a existência do TESTAMENTO DIGITAL assim que o mesmo for pago e gerado pelo sistema.">
                Notificar</th>
              <th>Nome Completo</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>

            <tr title="CPF:{{item.cpf}}" *ngFor="let item of arrayBeneficiario">
              <td><input type="checkbox"
                  (click)="alterarBeneficiarioCienciaTestamento($event,item.guid,item.idTestamento)"
                  [(checked)]="item.cienteTestamento"></td>
              <td>{{item.nome}}</td>
              <td>{{item.email}}</td>
              <td>{{item.telefone}}</td>
              <td class="col-1 bloquearNaEdicao">
                <button type="button" (click)="deletarBeneficiario(item.guid,item.idTestamento)"
                  class="btn btn-outline-danger bloquearNaEdicao"><i class="feather icon-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-12 mt-5">
        <button type="button" class="btn  button-rounded col-md-3" style="margin: 0 auto;"
          (click)="irParaTestamento()">
          <i class="feather icon-arrow-right"></i>AVANÇAR
        </button>
      </div>
    </div>

  </div>

</div>

<div class="d-mobile flex-wrap">


  <button type="button" class="col-12 btn button-rounded mobile" (click)="util.openModal(modalExcecutor)">
    <!-- <i class="feather icon-user-check"></i> -->
    <img class="iconMobileHome" src="/assets/images/icones-mobile/executor.gif">
    <span>Adicionar Executor</span>
  </button>
  <button type="button" class="col-12 btn button-rounded mobile" (click)="util.openModal(modalBeneficiario)">
    <!-- <i class="feather icon-users"></i> -->
    <img class="iconMobileHome" src="/assets/images/icones-mobile/beneficiarios.gif">
    <span>Adicionar BENEFICIÁRIO</span>
  </button>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MENSAGEM } from '../../../environments/environment';

@Component({
  selector: 'app-construir-testamento',
  templateUrl: './construir-testamento.component.html',
  styleUrls: ['./construir-testamento.component.scss']
})
export class ConstruirTestamentoComponent implements OnInit {


  public guidTestamento: string;
  controlaDiv: any;
  chave: any;
  public urlDownload: string;
  public isAssinaturaDigital:boolean;
  public isTestamentoComBens:boolean;
  public mensagem:String;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.guidTestamento = this.activatedRoute.snapshot.params['guid'];
    this.buscarTestamento();
    this.urlDownload = API + "TestamentoDigital/Testamento/" + this.guidTestamento + "/Download";
  }

  buscarTestamento() {
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        if (response.testamento == null) {
          //this.downloadTestamento();
          this.controlaDivs('download');
        } else {
          this.isTestamentoComBens = response.testamento.isTestamentoParticularComBens;
          this.isAssinaturaDigital = response.testamento.isAssinaturaDigital;
          this.controlaDivs('construir');
        }
      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }


  controlaDivs(div: string) {
    switch (div) {
      case 'construir':
        this.controlaDiv = { construir: true, download: false };
        break;
      case 'download':
        this.controlaDiv = { construir: false, download: true };
        this.urlDownload = API + "TestamentoDigital/Testamento/" + this.guidTestamento + "/Download";
        break;
    }
  }

  buildTestamento() {

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.guidTestamento + "/Construir";
    return this.ajax.post(url, {})
      .subscribe((response: any) => {
        this.util.closeLoading();
        this.chave = response;
        this.controlaDivs('download');

        if(this.chave.seraConstruidoDepoisDasAssinaturasNoClickSign){

          if(!this.isTestamentoComBens || !this.isAssinaturaDigital)
          {
          this.mensagem= MENSAGEM.construcaoExecutoresAceitarTermo;
          }else{
            this.mensagem = MENSAGEM.construcaoTestemunhasAssinarem;
          }
        }

      }, err => {
        this.util.closeLoading();
        let stringErro = "";
        err.error.forEach(res => {
          stringErro = stringErro + res
        });
        this.util.toasterShowError("Atenção", stringErro);
      });
  }
}

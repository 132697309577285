
<ul class="navbar-nav ml-auto menuPrincipalDireito position-relative">

  <li class="bemVindo">Olá,&nbsp;<strong><em>{{user.nome.toUpperCase()}}</em></strong> !  &nbsp;Bem vindo à plataforma!</li>


  <!--<li *ngIf="!isTestadorClienteAdvogado" (click)="home()" [class.menuAtivo]="isActive('/home')"><strong>INÍCIO</strong></li>-->
  <li  (click)="gerenciamento()" [class.menuAtivo]="isActive('/gerenciar-testamento')"><strong>GERENCIAR</strong></li>
  <li  ><a href="https://meutestamentodigital.com/#faq" target="_blank"><strong>AJUDA</strong></a></li>
  <li (click)="Sair()"><a href="javascript:" placement="bottom" ngbTooltip="Clique para sair">SAIR</a></li>
  <li><div class="dropdown drp-user" ngbDropdown placement="auto"></div></li>
</ul>


<section class="header-user-list" *ngIf="visibleUserList && !this.gradientConfig['rtl-layout']" [ngClass]="{'open': visibleUserList}" [@slideInOutLeft]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)" (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && !this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}" [@slideInOutLeft]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-user-list" *ngIf="visibleUserList && this.gradientConfig['rtl-layout']" [ngClass]="{'open': visibleUserList}" [@slideInOutRight]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)" (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}" [@slideInOutRight]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

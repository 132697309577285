<div *ngIf="TodosCampos">
  <div class="row">
    <div class="row img-topo img-cadastro">
      <p>Prezado(a) Advogado(a), a qualidade do TESTAMENTO DIGITAL depende do preenchimento correto de todos os campos.</p>
    </div>

    <!-- <div class="col-md-12 form-group float-left mb-4 d-none-sm" style="margin-top: -20px;">
      <app-tab-testamento class="cadUsuario"></app-tab-testamento>
    </div>  -->

    <div class="col-md-6">
      <div class="form-group">
        <label for="nome-testador">Nome completo do testador</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Nome" name="testador.Nome">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="nacionalidade">Nacionalidade</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Nacionalidade" name="testador.Nacionalidade">
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="dt-nascimento">Dt. Nascimento</label>
        <input type="text" class="form-control" [(ngModel)]="testador.DataNascimento" name="testador.DataNascimento"
          [textMask]="{mask: maskData}">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="nome-pai">Nome Pai</label>
        <input type="text" class="form-control" [(ngModel)]="testador.NomePai" name="testador.NomePai">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="nome-mae">Nome Mãe</label>
        <input type="text" class="form-control" [(ngModel)]="testador.NomeMae" name="testador.NomeMae">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="profissao">Profissão</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Profissao" name="testador.Profissao">
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="cpf-passaporte">CPF</label>
        <input type="text" class="form-control" [(ngModel)]="testador.CPFPassaporte" name="testador.CPFPassaporte"
          disabled>
      </div>
    </div>
     <div class="col-md-4">
      <div class="form-group">
        <label for="estado-civil">Estado Civil</label>
        <!--<input type="text" class="form-control" [(ngModel)]="testador.EstadoCivil" name="testador.EstadoCivil">-->
        <select [(ngModel)]="testador.EstadoCivil" name="testador.EstadoCivil"
          class="form-control form-control-default">
          <option value="1">Solteiro</option>
          <option value="2">Casado</option>
          <option value="3">Divorciado</option>
        </select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="endereco-testador">Endereço</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Endereco" name="testador.Endereco">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <div class="form-group">
        <label for="e-mail">E-mail</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Email" name="testador.Email" disabled>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label for="telefone">Telefone</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Telefone" name="testador.Telefone"
          [textMask]="{mask: maskTelefoneCelular}">
      </div>
    </div>
    <div class="gridCheckBox col-md-4">
      <input #garantirAcessoCheckbox  (change)="onGarantirAcessoTestadorChanged(garantirAcessoCheckbox.checked)"  id="garantirAcessoCheckbox"  type="checkbox" [(ngModel)]="isGarantirAcessoTestador"  name="check-sem-testamento" (click)="garantirAcessoTestadorClick();">
      <label for="sem-testamento-particular" class="semTestamentoParticular"><strong>
        GARANTIR ACESSO AO TESTADOR</strong> </label>
        <span class="iconAlerta" ngbTooltip="Testador poderá acessar a plataforma e ingressar em seu Testamento Digital, podendo apenas JUNTAR novos documentos" placement="top">!</span>
    </div>
    <div *ngIf="urlDiretaAcessoCliente">
      <div class="col-md-12">
        <div class="form-group">
          <label for="linkDeAcesso">Link De Acesso</label>
          <p style="text-transform: none !important;">{{urlDiretaAcessoCliente}}</p>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- especial para tela de gerenciamento -->
<div *ngIf="!TodosCampos">

  <div class="row">
    <br>

    <div class="col-sm-12 col-md-7 mobile-mt-3">
      <div class="form-group">
        <label for="nome-testador">Nome Completo</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Nome" name="testador.Nome">
      </div>
    </div>
    <div class="col-sm-12 col-md-5">
      <div class="form-group">
        <label for="email">E-mail</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Email" name="testador.Email">
      </div>
    </div>

  </div>


  <div class="row">
    <div class="col-sm-12 col-md-7">
      <div class="form-group">
        <label for="telefone">Telefone</label>
        <input type="text" class="form-control" [(ngModel)]="testador.Telefone" name="testador.Telefone"
          [textMask]="{mask: maskTelefoneCelular}">
      </div>
    </div>
    <div class="col-sm-12 col-md-5">
        <div class="form-group">
          <label for="dt-nascimento">Data Nascimento</label>
          <input type="text" class="form-control" [(ngModel)]="testador.DataNascimento" name="testador.DataNascimento"
            [textMask]="{mask: maskData}">
        </div>
      </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-7">
      <div class="form-group">
        <label class="alterarSenha" for="alterar-senha">Senha &nbsp; - <span> Não preencha se não quiser alterar sua senha</span></label>
        <input type="password" class="form-control" placeholder="Nova Senha" [(ngModel)]="testador.Senha" name="testador.Senha"/>
      </div>
    </div>

    <div class="col-sm-12 col-md-5">
      <div class="form-group">
        <label for="confirmar-senha">Confirmação Senha</label>
        <input type="password" class="form-control" placeholder="Confirmação Nova Senha" [(ngModel)]="testador.ConfirmacaoSenha"  name="testador.ConfirmacaoSenha"/>

      </div>
    </div>

  </div>



</div>



<br><br>
<div class="row">

  <div class="col-md-12 " align="center">
    <button type="button" class="btn button-rounded col-md-3" (click)="salvar()">
      <i class="feather icon-arrow-right"></i>AVANÇAR
    </button>
  </div>

</div>

import { API } from "src/environments/environment";
import { AjaxService } from "../service/ajax.service";
import { AuthService } from "../service/auth.service";
import { UtilService } from "../service/util.service";
import { Andamento } from "./Andamento";
import { TestamentoEdicao } from "./TestamentoEdicao";
import { Validar } from "./Validar";


declare var $: any;

export class ValidacaoModel {

  private testamentoEdicao :TestamentoEdicao;

  public Andamento: Andamento;
  public Validar: Validar;
  public DadosBasicosValidados:any;
  public isEdicao:boolean;

  constructor(
    private ajax?: AjaxService,
    private util?: UtilService,
    private auth?: AuthService
    ) {
      this.testamentoEdicao = new TestamentoEdicao();
      this.isEdicao=this.testamentoEdicao.buscarTestamentoEmEdicao();
     }


  public buscarTestamento(){
    if(this.testamentoEdicao.buscarTestamentoEmEdicao() ){
      this.inicializarTestamento(this.testamentoEdicao.Testamento);
      this.isEdicao=true;
      return true;
     }

    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        if (response.testamento != null) {

          this.isEdicao=false;
          this.inicializarTestamento(response.testamento);

         }else{
          //this.util.toasterShowWarning("Atenção", "Não há testamento Ativo");
         }
      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  inicializarTestamento(testamento:any){
    this.Andamento = {testamento};
    this.auth.setTestamento(testamento);
    this.auth.getTestamento();
    this.validarTestamento();

    if(this.Andamento?.testamento?.naoPodeMaisSeralterado){
      if(this.isEdicao){
         var interval = setInterval(() => {
            $('.bloquearNaEdicao *').prop('disabled',true);
            $('.bloquearNaEdicao,.bloquearNaEdicao *').attr('title','Desabilitado');
            if(!this.isEdicao)
            clearInterval(interval);
          }, 200);
      }
      else{
         $('#controleCampos').prop('disabled',true);
         $('#controleValidarGerar').prop('disabled',true);

         $('#controleCampos *').attr('title','Desabilitado');
         $('#controleValidarGerar').attr('title','Desabilitado');
      }
    }
  }

  validarTestamento(){
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Validar";
    return this.ajax.get(url)
      .subscribe(response => {
        this.Validar = response;

      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  async validarDadosBasicos():Promise<boolean> {

    let url = API + "Auth/Client/EnviouDadosBasicos";
    await this.ajax.getAsync(url,(response) =>{
      this.DadosBasicosValidados = response;
    });

    return  this.DadosBasicosValidados.valido;
  }
}

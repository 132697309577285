<div class="tab-content">
    <div class="tab-pane fade show active">
      <div class="header-resumo d-none-sm">NOME DO TESTAMENTO
        <h5 class="card-title">{{homeValidacao.Andamento?.testamento?.nome}}</h5>
      </div>
      <div class="form-preview-item">
        <li class="nav-link">
          <div class="form-preview-item-color previewAndamento">
            <h6 class="form-preview-item-color" style="font-size:13px;">STATUS DE PREENCHIMENTO</h6>
            <p class="">{{homeValidacao.Andamento?.testamento?.status}}</p>
          </div>
        </li>
      </div>
      <div class="form-preview-item">
        <li class="nav-link">
          <div class="form-preview-item-color">
            <h6 class="form-preview-item-color" style="font-size:13px;">EXECUTOR(ES)</h6>
            <p class="">{{homeValidacao.Andamento?.testamento?.executores?.length}}</p>
          </div>
        </li>
      </div>
      <div class="form-preview-item">
        <li class="nav-link">
          <div class="form-preview-item-color">
            <h6 class="form-preview-item-color" style="font-size:13px;">BENEFICIÁRIO(S) <!--<span
                class="badge badge-warning">!</span>--></h6>
            <p class="">{{homeValidacao.Andamento?.testamento?.beneficiarios?.length}}</p>

          </div>
        </li>
      </div>
      <div class="form-preview-item d-none-sm">
        <li class="nav-link">
          <div class="form-preview-item-color">
            <h6 class="form-preview-item-color" style="font-size:13px;">TESTAMENTO PARTICULAR?</h6>
            <p class="">{{homeValidacao.Andamento?.testamento?.isTestamentoParticularText}}</p>
          </div>
        </li>
      </div>
      <div class="form-preview-item">
        <li class="nav-link">
          <div class="form-preview-item-color">
            <h6 class="form-preview-item-color" style="font-size:13px;">ARQUIVOS E SENHAS</h6>
            <!-- <p class="">{{homeValidacao.Andamento?.testamento?.arquivos?.length}}</p> -->
            <p class="">Verificar</p>
          </div>
        </li>
      </div>
      <div class="form-preview-item">
        <li class="nav-link">
          <div class="form-preview-item-color">
            <h6 class="form-preview-item-color" style="font-size:13px;">LEITURA DO TESTAMENTO</h6>
            <p class="">Verificar</p>
          </div>
        </li>
      </div>
      <!-- <div class="form-preview-item">
        <li class="nav-link">
          <div class="form-preview-item-color">
            <h6 class="form-preview-item-color" style="font-size:13px;">SENHAS CADASTRADAS</h6>
            <p class="">{{homeValidacao.Andamento?.testamento?.dadosLivres?.length}}</p>
          </div>
        </li>
      </div> -->

    </div>

    <div class="row" style="margin-right:-15px; margin-left:-15px">

      <div class="col-md-12 btn-validar" align="center">
        <br>
        <h6 class="d-none-sm" *ngIf="!isEdicao"  id="concluaSeuTestamento">{{fraseTituloTestamento}}</h6>
        <button *ngIf="!isEdicao" type="button" class="btn btn-hover color-1" (click)="escolhaPlano()">
          <!-- <i class="far fa-credit-card d-none-sm"></i> -->
          <svg id="cls-icon-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.85 50.78">
            <defs>
              <style>
                .cls-icon-logo {
                  fill: #353b4c;
                }
              </style>
            </defs>
            <path class="iconeMenuLateralEsquerdo cls-icon-logo"
              d="M27.51,20.65V10.58C27.51,4.74,22.77,0,16.93,0S6.35,4.74,6.35,10.58v10.07c-3.87,3.1-6.35,7.86-6.35,13.2,0,9.35,7.58,16.93,16.93,16.93s16.93-7.58,16.93-16.93c0-5.34-2.48-10.1-6.35-13.2ZM10.58,10.58c0-3.5,2.85-6.35,6.35-6.35s6.35,2.85,6.35,6.35v7.59c-1.96-.8-4.1-1.24-6.35-1.24s-4.39,.45-6.35,1.24v-7.59Zm6.35,35.97c-7,0-12.69-5.69-12.69-12.69s5.69-12.69,12.69-12.69,12.69,5.69,12.69,12.69-5.69,12.69-12.69,12.69Z" />
            <path class="cls-icon-logo"
              d="M6.42,33.73c.04,1.22,.54,2.39,1.37,3.28,.97,1.02,2.29,1.59,3.69,1.58,1.36,0,2.65-.59,3.61-1.55l.27-.27c.46-.46,.46-1.2,0-1.66s-1.2-.46-1.66,0l-.29,.29c-.47,.47-1.09,.78-1.75,.83-1.03,.08-2-.42-2.55-1.34-.13-.21-.22-.44-.28-.67-.28-1.16,.17-2.31,1.16-2.96,.27-.18,.58-.3,.9-.37,.92-.18,1.83,.09,2.47,.74l1.9,1.9h0s3.57,3.58,3.57,3.58c.95,.95,2.22,1.48,3.57,1.48,1.35,0,2.61-.52,3.57-1.48,1-1,1.52-2.34,1.47-3.75-.04-1.22-.54-2.39-1.37-3.28-.97-1.02-2.29-1.59-3.69-1.58-1.36,0-2.65,.59-3.61,1.55l-.27,.27c-.46,.46-.46,1.2,0,1.66,.46,.46,1.2,.46,1.66,0l.29-.29c.47-.47,1.09-.78,1.75-.83,1.03-.08,2,.42,2.55,1.34,.13,.21,.22,.44,.28,.67,.28,1.16-.17,2.31-1.16,2.96-.27,.18-.58,.3-.9,.37-.92,.18-1.83-.09-2.47-.74l-1.9-1.9h0s-3.57-3.58-3.57-3.58c-.95-.95-2.22-1.48-3.57-1.48s-2.61,.52-3.57,1.48c-1,1-1.52,2.34-1.47,3.75Z" />
          </svg>
          <img class="iconMobileAnimated d-mobile" src="/assets/images/icones-mobile/card.gif">
          validar e salvar
        </button>

        <button  *ngIf="isEdicao" type="button" class="btn btn-hover color-1 permitirNaEdicao" (click)="encerrarEdicao()">
          <!-- <i class="feather icon-arrow-right permitirNaEdicao"></i> -->
          <svg id="cls-icon-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.85 50.78">
            <defs>
              <style>
                .cls-icon-logo {
                  fill: #353b4c;
                }
              </style>
            </defs>
            <path class="iconeMenuLateralEsquerdo cls-icon-logo"
              d="M27.51,20.65V10.58C27.51,4.74,22.77,0,16.93,0S6.35,4.74,6.35,10.58v10.07c-3.87,3.1-6.35,7.86-6.35,13.2,0,9.35,7.58,16.93,16.93,16.93s16.93-7.58,16.93-16.93c0-5.34-2.48-10.1-6.35-13.2ZM10.58,10.58c0-3.5,2.85-6.35,6.35-6.35s6.35,2.85,6.35,6.35v7.59c-1.96-.8-4.1-1.24-6.35-1.24s-4.39,.45-6.35,1.24v-7.59Zm6.35,35.97c-7,0-12.69-5.69-12.69-12.69s5.69-12.69,12.69-12.69,12.69,5.69,12.69,12.69-5.69,12.69-12.69,12.69Z" />
            <path class="cls-icon-logo"
              d="M6.42,33.73c.04,1.22,.54,2.39,1.37,3.28,.97,1.02,2.29,1.59,3.69,1.58,1.36,0,2.65-.59,3.61-1.55l.27-.27c.46-.46,.46-1.2,0-1.66s-1.2-.46-1.66,0l-.29,.29c-.47,.47-1.09,.78-1.75,.83-1.03,.08-2-.42-2.55-1.34-.13-.21-.22-.44-.28-.67-.28-1.16,.17-2.31,1.16-2.96,.27-.18,.58-.3,.9-.37,.92-.18,1.83,.09,2.47,.74l1.9,1.9h0s3.57,3.58,3.57,3.58c.95,.95,2.22,1.48,3.57,1.48,1.35,0,2.61-.52,3.57-1.48,1-1,1.52-2.34,1.47-3.75-.04-1.22-.54-2.39-1.37-3.28-.97-1.02-2.29-1.59-3.69-1.58-1.36,0-2.65,.59-3.61,1.55l-.27,.27c-.46,.46-.46,1.2,0,1.66,.46,.46,1.2,.46,1.66,0l.29-.29c.47-.47,1.09-.78,1.75-.83,1.03-.08,2,.42,2.55,1.34,.13,.21,.22,.44,.28,.67,.28,1.16-.17,2.31-1.16,2.96-.27,.18-.58,.3-.9,.37-.92,.18-1.83-.09-2.47-.74l-1.9-1.9h0s-3.57-3.58-3.57-3.58c-.95-.95-2.22-1.48-3.57-1.48s-2.61,.52-3.57,1.48c-1,1-1.52,2.34-1.47,3.75Z" />
          </svg>
          FINALIZAR INSERÇÃO E APROVAR
        </button>
      </div>
    </div>
  </div>



<div style="margin-top: 0; position: absolute; right: 48px; z-index: 2;"
  [ngClass]="{'hidden': this.activeTabTestamento != 'testemuntas'}"
  class="form-group float-right d-none-sm" placement="left"
  tooltipClass="tooltipTestemunhas"
  ngbTooltip="A validade de um TESTAMENTO PARTICULAR depende da assinatura de três testemunhas. Escolha suas TESTEMUNHAS e a forma de obter as assinaturas. ALERTA: A testemunha deve ser maior de idade e não poderá ser seu beneficiário.">
  <span><i class="feather icon-alert-triangle"></i></span>
</div>

<div class="d-none-sm">
  <ul class="nav nav-tabs profile-tabs nav-fill"
    style="clear:both; margin-top:-37px;">
    <li class="nav-item">
      <a class="nav-link text-reset"
        [ngClass]="{'myActive': this.activeTabTestamento == 'testamento'}"
        (click)="irParaAbaTestamento();" data-toggle="tab" href="javascript:"
        role="tab">
        CRIAR TESTAMENTO
      </a>
    </li>
    <li class="nav-item" *ngIf="!haveraDisposicaoDeBens">
      <a class="nav-link text-reset"
        [ngClass]="{'myActive': this.activeTabTestamento == 'testemuntas'}"
        (click)="irParaAbaTestemunhas();" data-toggle="tab" href="javascript:"
        role="tab">
        TESTEMUNHAS ( {{arrayTestemunhas.length}} )
      </a>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade"
      [ngClass]="{'show active': this.activeTabTestamento == 'testamento'}"
      role="tabpanel">

      <div class="row aba-testamento"
        style="padding: 30px 0px 14px 0px; margin:0px;">
        <div class="row img-topo img-testamento" style="margin-top: 0;">

          <p>
            Prezado(a) Advogado(a), ao redigir um <span>TESTAMENTO
              PARTICULAR</span>, é importante seguir um processo meticuloso para
            garantir que as vontades do Testador sejam adequadamente expressas.
            Utilize do modelo abaixo.
          </p>
        </div>

        <div *ngIf="!isMobile" class="form-group float-left mb-4"
          style="margin-top: -20px;"
          [ngClass]="{'hidden': this.activeTabTestamento == 'testemuntas'}">
          <input style="float: left;" type="checkbox"
            (click)="alterarTipoTestamento($event)"
            [(ngModel)]="haveraDisposicaoDeBens">
          <label class="semTestamentoParticular"><strong>
              Não desejo criar um testamento particular</strong> </label>
        </div>

        <p class="textTestamento text-form text-muted">
          <span [innerHTML]="objTestamento?.cabecalho"></span>
        </p>

        <!-- ------ novo layout -->
        <div class="col-md-12 btn-validar mt-2 mb-4">
          <button [(disabled)]="haveraDisposicaoDeBens" type="button"
            class="btn btn-hover color-1 col-md-5" style="margin: 0 auto;"
            (click)="util.openModal(modalPreencherTestamento)">
            <i class="feather icon-edit-3"></i>Preencher o testamento
          </button>

        </div>
        <!-- ------ fim novo layout -->

        <div class="col-md-12 mt-4">
          <button [(disabled)]="haveraDisposicaoDeBens" type="button"
            class="btn button-rounded col-md-3"
            style="margin: 0 auto;" (click)="salvarTestamento()">
            <i class="feather icon-arrow-right"></i>AVANÇAR
          </button>

          <a style="float: right;  margin-top: -36px; padding-inline: 1rem;"
            *ngIf="!isAssinaturaDigital && testamento.ComplementoTestamento && !haveraDisposicaoDeBens"
            href='{{urlDownload}}' target='_blank'
            class="btn button-rounded-green"
            title="Para que as testemunhas sejam incluídas, favor adicionar no MÍNIMO 3(TRÊS) TESTEMUNHAS">Gerar
            Pdf</a>

        </div>

      </div>
    </div>

    <div class="tab-pane fade"
      [ngClass]="{'show active': this.activeTabTestamento == 'testemuntas'}"
      role="tabpanel">

      <div class="row align-items-center"
        style="padding: 30px 0px 14px 0px; margin:0px;">

        <div class="row img-topo img-testemunha" style="margin-top: 0;">
          <p>
            <span>Escolha três testemunhas.</span> A validade de um testamento
            particular depende da assinatura de três
            testemunhas que deverão conhecer o conteúdo do testamento
            particular. Alerta: A testemunha deve ser maior de
            idade e não poderá ser beneficiada no testamento particular.
          </p>
        </div>
        <div class="col-md-12 pr-0">
          <a class="float-right text-muted ">

            <button type="button" class="btn btn-icon btn-outline-info"
              (click)="util.openModal(modalTestemunha)">
              <i class="feather icon-plus"></i>Adicionar testemunha</button>
          </a>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nome Completo</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>

            <tr title="CPF:{{item.cpf}}" *ngFor="let item of arrayTestemunhas">
              <td>{{item.nome}}</td>
              <td>{{item.email}}</td>
              <td>{{item.telefone}}</td>
              <td class="col-1">
                <button type="button"
                  (click)="deletarTestemunha(item.guid,item.idTestamento)"
                  class="btn btn-outline-danger"><i
                    class="feather icon-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div class="row align-items-center" style="margin:0px;">
        <div class="col">

          <strong class="text-muted">
            <i class="fas fa-hand-holding f-40"></i>
            FORMA DE OBTENÇÃO DAS ASSINATURAS:
          </strong>
          <br><br>
          <div class="form-check text-muted">
            <input class="form-check-input" type="radio" name="gridRadios"
              id="gridRadios1"
              (click)="setTipoAssinaturaTestemunha($event)"
              value="cff963a1-cbc8-4e70-8a75-248a0a221dc0"
              checked="{{idTipoAssinaturaTestemunhaSelecionado=='cff963a1-cbc8-4e70-8a75-248a0a221dc0'}}">
            <label class="form-check-label" for="gridRadios1">Assinatura Digital
              do Usuário e das Testemunhas</label>
          </div>
          <div class="form-check text-muted">
            <input class="form-check-input" type="radio" name="gridRadios"
              id="gridRadios2"
              (click)="setTipoAssinaturaTestemunha($event)"
              value="cff963a1-cbc8-4e70-8a75-248a0a221dc1"
              checked="{{idTipoAssinaturaTestemunhaSelecionado=='cff963a1-cbc8-4e70-8a75-248a0a221dc1'}}">
            <label class="form-check-label" for="gridRadios2"
              title="VISANDO CUMPRIR OS EXATOS TERMOS DA LEGISLAÇÃO&#013;EM VIGOR (Art.1.876 e seguintes do Código Cível Brasileiro),&#013;O MTD RECOMENDA A OBTENÇÃO DAS ASSINATURAS DE FORMA MANUAL.">Imprimir
              o Testamento Particular e Colher Assinaturas Manualmente
              (RECOMENDADO)
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-5">
        <button type="button" class="btn button-rounded col-md-3"
          style="margin: 0 auto;" (click)="irParaAnexos()">
          <i class="feather icon-arrow-right"></i>AVANÇAR
        </button>
      </div>

    </div>
  </div>
</div>
<div class="d-home" style="display: none;">
  <input style="float: left;" type="checkbox"
    (click)="alterarTipoTestamento($event)"
    [(ngModel)]="haveraDisposicaoDeBens">
  <label class="semTestamentoParticular"><strong>
      Não desejo criar um testamento particular</strong> </label>
</div>
<div *ngIf="isMobile" class="d-mobile m-2">
  <input style="float: left;" type="checkbox"
    (click)="alterarTipoTestamento($event)"
    [(ngModel)]="haveraDisposicaoDeBens">
  <label class="semTestamentoParticular"><strong>
      Ciente de não ter testamento particular </strong>
    <i class="feather icon-info mobile"
      ngbTooltip="Ao acessar a versão mobile não é possível criar um testamento particular, essa opção está disponível somente acessando por um computador"
      placement="auto"></i>
  </label>
</div>

<app-ui-modal #modalPreencherTestamento [hideFooter]="true"
  [dialogClass]="'modal-xl'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">Prossiga com a elaboração do seu
      testamento</h5>
    <button type="button" class="close basic-close" data-dismiss="modal"
      aria-label="Close" #botaoFecharModalPreencherTestamento
      (click)="util.closeModal(modalPreencherTestamento)"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">

      <div class="col-12">
        <!--<input style="float: left;" type="checkbox">
        <label class="semTestamentoParticular">
          <strong>Advogado testamenteiro?</strong>
        </label>-->
        <a class="float-right text-muted ">
          <button type="button" class="btn btn-icon btn-outline-info"
            (click)="abrirModalBensEDireitos(modalListarBens)">
            <i class="feather icon-plus"></i>Listar bens e direitos</button>
        </a>
      </div>

      <div class="col-12 form-group">
        <label for="exampleInputPassword1"></label>
        <textarea [(disabled)]="haveraDisposicaoDeBens"
          placeholder="Prossiga com a elaboração do seu testamento"
          [(ngModel)]="testamento.ComplementoTestamento" type="text"
          class="form-control txtTestamento" rows="10"
          cols="33">
          </textarea>

        <!-- <app-card cardTitle="Pell WYSIWYG Editor" [options]="false">
            <pell-editor></pell-editor>
          </app-card> -->

      </div>

      <div class="col-12 form-group">
        <p class="textTestamento text-form text-muted">
          {{objTestamento?.rodape}}
        </p>
      </div>

      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-4"
          style="margin: 0 auto;" (click)="salvarTestamento()">
          <i class="feather icon-upload-cloud"></i>SALVAR
        </button>
      </div>
    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalListarBens [hideFooter]="false" [dialogClass]="'modal-md'" class="modalListarBens">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">listagem de bens e direitos</h5>
    <button type="button" class="close basic-close" data-dismiss="modal"
      aria-label="Close" #botaoFecharModalListarBens
      (click)="util.closeModal(modalListarBens)"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">

      <div class="col-12 form-group">
        <p>Selecione os itens abaixo para adicioná-los ao seu
          testamento:</p>
      </div>

      <div *ngFor="let item of bensEDireitos" class="col-12 d-flex align-items-center justify-content-flex-start">
        <div class="col-md-9">
          <label>{{item.texto}}</label>
        </div>
        <div class="col-md-2 contadorBens">
          <button (click)="menos(item.id)" class="a">-</button>
          <input maxlength="8" (focusout)="atualizarValorAtual(item.id)" type="number" id="{{item.id}}" #{{item.id}} class="numberController" placeholder="0" value="0">
          <button (click)="mais(item.id)" class="b">+</button>
        </div>
      </div>

    </div>
  </div>
  <div class="app-modal-footer w-100">
    <div class="row gridBtnAcoes">
      <button type="button" class="btn button-rounded col-md-6"
      style="margin: 0 auto;" (click)="salvarBensEDireitos(modalListarBens)">
      <i class="feather icon-upload-cloud"></i>SALVAR
    </button>
    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalTestemunha [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ADICIONAR TESTEMUNHA</h5>
    <button type="button" class="close basic-close" data-dismiss="modal"
      aria-label="Close" #botaoFecharModalTestemunha
      (click)="util.closeModal(modalTestemunha)"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput"
          (keyup)="verificarSeTestemunhaExisteAsync()"
          [(ngModel)]="testemunha.CPF" name="testemunha.CPF" placeholder="CPF"
          [textMask]="{mask: maskCPF}">
      </div>

      <div class="col-md-12">
        <input type="text" class="form-control fundoInput"
          [(ngModel)]="testemunha.Nome" [disabled]="isRegistroExiste"
          name="testemunha.Nome" style="text-transform: uppercase;"
          placeholder="Nome Completo">
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput"
          [(ngModel)]="testemunha.DataNascimento"
          [disabled]="isRegistroExiste" name="testemunha.DataNascimento"
          placeholder="Data Nascimento"
          [textMask]="{mask: maskData}">
      </div>

      <div class="col-md-12">
        <input type="email" class="form-control fundoInput"
          [(ngModel)]="testemunha.Email" [disabled]="isRegistroExiste"
          name="testemunha.Email" placeholder="E-mail">
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput"
          [(ngModel)]="testemunha.Telefone"
          [disabled]="isRegistroExiste" name="testemunha.Telefone"
          placeholder="Telefone"
          [textMask]="{mask: maskTelefoneCelular}">
      </div>

      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6"
          style="margin: 0 auto;" (click)="addTestemunha()">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
    </div>

  </div>
</app-ui-modal>

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
    name: 'SafePipe'
})
export class SafePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }
    transform(html: any): SafeHtml {

        return this.sanitizer.bypassSecurityTrustHtml(html+`<style>
        div.match
{
 background-image: url(/assets/images/match.png);
 background-repeat: no-repeat;
  height: 37px;
  padding:9px 0px 0px 38px;
}


div.notmatch
{
  background-image: url(/assets/images/unmatch.png);
  background-repeat: no-repeat;
   height: 37px;
   padding:9px 0px 0px 38px;
}     <style>` );
    }

}

import { Andamento } from './../../model/Andamento';
import { AuthService } from 'src/app/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { AjaxService } from 'src/app/service/ajax.service';
import { ResponseUser } from 'src/app/model/User.model';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { Testamento } from 'src/app/model/Testamento.model';
import { API } from "src/environments/environment";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  validarModel: ValidacaoModel;
  user: ResponseUser;
  public testamento: Testamento;
  public dadosValidados: boolean = false;
  public isTestadorClienteAdvogado: boolean = false;
  public isAdministradorEmpresa:boolean = false;
  // adicionar botão click exibir/ocultar div
  toDisplay: any = true;
  toggleData() {
    this.toDisplay = !this.toDisplay;
  }

  toDisplayModalNomeTestamento: any = true;
  toggleDataModalNomeTestamento() {
    this.toDisplayModalNomeTestamento = !this.toDisplayModalNomeTestamento;
  }

  // toDisplay2: any = true;
  // toggleData2() {
  //   this.toDisplay2 = !this.toDisplay2;
  // }
  // fim adicionar botão click exibir/ocultar div


  arrayProgramas: [];
  public abas = { cadastroTestador: true, testamento: false, senhas: false, anexosAba: false, executoresBeneficiarios: false, leituraTestamento: false };

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.testamento = new Testamento("", [], true, false, false, "", this.util, this.ajax);
    this.validarModel = new ValidacaoModel(ajax, util, auth);
    this.dadosValidados = false;
    this.validarModel.Validar = {
      mensagensValidacao: "",
      status: {
        dadosTestadorEnviados: true,
        testemunhasEnviadas: false,
        executoresEnviados: false,
        arquivosEnviados: false,
        beneficiariosEnviados: false,
        testamentoPago: false,
        testamentoValido: false,
      }


    }
  }

  async ngOnInit() {
    debugger;
    this.user = this.auth.getUser();
    this.isTestadorClienteAdvogado = this.user.user.isTestadorClienteAdvogado;
    this.isAdministradorEmpresa  =this.user.user.isAdministradorEmpresa;
    if(this.user.user.isTestador){
      this.buscarTestamento();
      this.validarDadosBasicos();
    }
    this.toDisplayModalNomeTestamento = false;
    if(this.isTestadorClienteAdvogado)
    {
      this.abas.cadastroTestador = false;
      this.abas.anexosAba = true;
    }

    /*if(this.isAdministradorEmpresa || this.user.user.isAdvogado)
    {
      setTimeout(async () => {
        await this.verificarSePlanoEstaPago();
      }, 500);

    }*/
  }

  async verificarSePlanoEstaPago(){

      var estaPago = await this.verificarSeEstaPago();

      if(!estaPago)
      this.router.navigate(['/pagamento'],
        { queryParams: { construir: 'false' } }
      );

  }

  async verificarSeEstaPago(){

    let estaPago = false;

    if(!this.auth.testamentoAtivo || !this.auth.testamentoAtivo.guid){
      setTimeout(()=>{
        this.verificarSeEstaPago();
      },1000);
      return;
    }

    let url = API + "Financeiro/Pagamento/Testamento/" + this.auth.testamentoAtivo.guid + "/EstaPago";
    await this.ajax.getAsync(url,  response => {
      estaPago = response.pago;
    });
    return estaPago;
  }

  async salvarTestamentoDisposicaoBens() {
    let url = `${API}TestamentoDigital/Testamento/CriarComDisposicaoDeBens`;
    await this.ajax.postAsync(url, null, (response) => {
      location.reload();
    });
  }

  async salvarDisposicaoBens() {

    let testamento = this.auth.getTestamento();
    if (testamento == null) {
      this.testamento.HaveraDisposicaoBens = true;
      await this.salvarTestamentoDisposicaoBens();
    }
  }

  async validarDadosBasicos() {
    this.dadosValidados = await this.validarModel.validarDadosBasicos();
  }

  async selectAba(selecionar: string) {

    this.validarDadosBasicos();
    switch (selecionar) {
      case 'cadastro-testador':
        this.abas = { cadastroTestador: true, testamento: false, senhas: false, anexosAba: false, executoresBeneficiarios: false, leituraTestamento: false };
        break;
      case 'testamento':
        this.abas = { cadastroTestador: false, testamento: true, senhas: false, anexosAba: false, executoresBeneficiarios: false, leituraTestamento: false };
        break;
      case 'senhas':
        this.abas = { cadastroTestador: false, testamento: false, senhas: true, anexosAba: false, executoresBeneficiarios: false, leituraTestamento: false };
        break;
      case 'anexos':
        this.abas = { cadastroTestador: false, testamento: false, senhas: false, anexosAba: true, executoresBeneficiarios: false, leituraTestamento: false };
        break;
      case 'excecutores-beneficiarios':
        this.abas = { cadastroTestador: false, testamento: false, senhas: false, anexosAba: false, executoresBeneficiarios: true, leituraTestamento: false };
        break;
      case 'leitura':
        this.abas = { cadastroTestador: false, testamento: false, senhas: false, anexosAba: false, executoresBeneficiarios: false, leituraTestamento: true };
        break;
    }
  }

  buscarTestamento() {
    this.validarModel.buscarTestamento();
  }
  escolhaPlano(){
    this.buscarTestamento();
  }

  validarProximaTela(obj: any){
    if(obj.status.arquivosEnviados &&
      obj.status.beneficiariosEnviados &&
      obj.status.dadosTestadorEnviados &&
      obj.status.executoresEnviados &&
      obj.status.testemunhasEnviadas
       ){
        this.router.navigate(['/pagamento']);
       }else{
        Swal.fire(
          'Atenção!',
          obj.mensagensValidacao,
          'warning'
        );
       }
  }

}

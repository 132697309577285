
export class TestamentoEdicao {

  public Testamento:any;
  private  TestamentoKey:string = "testamentoEdicao";

  constructor(
  ) {
  }

  buscarTestamentoEmEdicao ():boolean {

    var testamento = localStorage.getItem(this.TestamentoKey);

    if(testamento){
      this.Testamento = JSON.parse(testamento);
      return true;
    }

    return false;
  }

  limparTestamentoEmEdicao()
  {
    localStorage.removeItem(this.TestamentoKey);
  }

  addTestamentoEdicao(testamento:any){
    localStorage.setItem(this.TestamentoKey,JSON.stringify(testamento));
  }
}

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class=" d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <img src="assets/images/logo-azul-2.png" alt="" class="img-fluid">
        <br><br><br><br><br><br>
        <h4 class="text-white font-weight-normal" style="font-size:30px;">Crie um testamento digital em 5 etapas.</h4>
        <h1 class="text-white my-4 font-weight-normal" style="font-size:8em; font-family: 'Roboto Condensed'; font-weight: 100 !important; line-height: 100%;">
          Resguarde os interesses<br>de seus clientes com <br>praticidade e segurança.</h1>
        <br>
        <button class="btn button-rounded mb-0" style="padding-left: 10% ; padding-right: 10%  ;display:none;"  (click)="this.util.notImplement()"> <strong>VER VÍDEO DE DEMONSTRAÇÃO</strong> </button>
      </div>
    </div>
    <div class="auth-side-form">
      <div class=" auth-content" >
        <img src="assets/images/logo-azul-2.png" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none">
        <h3 class="mb-4 f-w-400 link-blue">Redefinir Senha</h3>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-user"></i></span>
          </div>
          <input type="password" class="form-control" [(ngModel)]="novaSenha" name="novaSenha"  placeholder="Nova Senha">
        </div>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-lock"></i></span>
          </div>
          <input type="password" class="form-control" [(ngModel)]="confirmacaoSenha" name="confirmacaoSenha"  placeholder="Repita a Nova Senha">
        </div>
        <button class="btn button-rounded mb-0" (click)="alterarSenha()" style="padding-left: 42% ; padding-right: 42%  ;"> <strong>Redefinir</strong> </button>
      </div>
      <div class="text-center">
        <div class="saprator my-4"></div>
        <br><br>
        <p class="mb-0 text-muted"> <br><a class="f-w-400 link-blue" (click)="irParaTelaDeLogin()">Ir para Tela de Login </a></p>
      </div>
    </div>
  </div>
</div>

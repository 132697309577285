import { AjaxService } from "../service/ajax.service";
import { UtilService } from "../service/util.service";

export class CadastroTestador {
  Nome: string;
  Nacionalidade: string;
  DataNascimento: string;
  NomePai: string;
  NomeMae: string;
  Profissao: string;
  CPFPassaporte: string;
  EstadoCivil: string;
  Endereco: string;
  Email: string;
  Telefone: string;
  Senha: string;
  ConfirmacaoSenha:String;

  constructor(
    Nome: string,
    Nacionalidade: string,
    DataNascimento: string,
    NomePai: string,
    NomeMae: string,
    Profissao: string,
    CPFPassaporte: string,
    EstadoCivil: string,
    Endereco: string,
    Email: string,
    Telefone: string,
    Senha: string,
    private util?: UtilService
  ) {
    this.Nome = Nome;
    this.Nacionalidade = Nacionalidade;
    this.DataNascimento = DataNascimento;
    this.NomePai = NomePai;
    this.NomeMae = NomeMae;
    this.Profissao = Profissao;
    this.CPFPassaporte = CPFPassaporte;
    this.EstadoCivil = EstadoCivil;
    this.Endereco = Endereco;
    this.Email = Email;
    this.Telefone = Telefone;
    this.Senha = Senha;
  }


  public validar(todosCampos:Boolean=true) : boolean{


    if(this.Nome == ""){
      this.util.toasterShowError("Atenção", "O Campo Nome não pode ser Vazio!");
      return false;
    }

    if(todosCampos && this.Nacionalidade == ""){
      this.util.toasterShowError("Atenção", "O Campo Nacionalidade não pode ser Vazio!");
      return false;
    }

    if(this.DataNascimento == undefined || this.util.removeMask(this.DataNascimento) == "" || this.util.removeMask(this.DataNascimento).length < 8){
      this.util.toasterShowError("Atenção", "O Campo DataNascimento não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if(todosCampos && this.NomePai == ""){
      this.util.toasterShowError("Atenção", "O Campo Nome Pai não pode ser Vazio!");
      return false;
    }

    if(todosCampos && this.NomeMae == ""){
      this.util.toasterShowError("Atenção", "O Campo Nome Mãe não pode ser Vazio!");
      return false;
    }

    if(todosCampos && this.Profissao == ""){
      this.util.toasterShowError("Atenção", "O Campo Profissão não pode ser Vazio!");
      return false;
    }

    if(todosCampos && this.CPFPassaporte == ""){
      this.util.toasterShowError("Atenção", "O Campo CPF/Passaporte não pode ser Vazio!");
      return false;
    }

    if(todosCampos && this.EstadoCivil == ""){
      this.util.toasterShowError("Atenção", "O Campo Estado Civil não pode ser Vazio!");
      return false;
    }

    if(todosCampos && this.Endereco == ""){
      this.util.toasterShowError("Atenção", "O Campo Endereço não pode ser Vazio!");
      return false;
    }

    if(this.Email == ""){
      this.util.toasterShowError("Atenção", "O Campo Email não pode ser Vazio!");
      return false;
    }

    if(this.Telefone == undefined || this.util.removeMask(this.Telefone) == "" || this.util.removeMask(this.Telefone).length < 11){
      this.util.toasterShowError("Atenção", "O Campo Telefone não pode ser Vazio ou estar incompleto!!");
      return false;
    }

    return true;
  }

  public montarJson(): any{
    let dados = {
     "nome": this.Nome,
     "nacionalidade":  this.Nacionalidade,
     "dataNascimento":   this.util.formatDateMaskSave(this.DataNascimento),
     "nomePai":  this.NomePai,
     "nomeMae":  this.NomeMae,
     "profissao":  this.Profissao,
     "CPFPassaporte":  this.CPFPassaporte,
     "estadoCivil":  parseInt(this.EstadoCivil),
     "endereco":  this.Endereco,
     "email":  this.Email,
     "telefone":  this.util.removeMask(this.Telefone),
    };

    if(this.Senha)
      dados["senha"] = this.Senha;

      if(this.ConfirmacaoSenha)
      dados["confirmacaoSenha"] = this.ConfirmacaoSenha;


    return dados;
  }




}

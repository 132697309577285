import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CadastroTestador } from 'src/app/model/CadastroTestador.model';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API,  maskData, maskTelefoneCelular } from 'src/environments/environment';

@Component({
  selector: 'app-tab-cadastro-testador',
  templateUrl: './tab-cadastro-testador.component.html',
  styleUrls: ['./tab-cadastro-testador.component.scss']
})
export class TabCadastroTestadorComponent implements OnInit {

  @Input() homeValidacao: ValidacaoModel;

  @Output()
  onChangeAba = new EventEmitter<string>();

  public testador: CadastroTestador;
  public maskTelefoneCelular = maskTelefoneCelular;
  public maskData = maskData;

  constructor(
    private util: UtilService,
    private ajax: AjaxService,
    private auth: AuthService,
  ) {
    this.inicializar();
  }

  ngOnInit() {
    this.inicializar();
    this.buscarDadosTestador();
  }

  inicializar() {
    this.auth.getUser();
    this.testador = new CadastroTestador(
      this.auth.user.user.nome,
      "",
      this.util.formatDateStringOnlyDate(this.auth.user.user.dataNascimento),
      "",
      "",
      "",
      this.auth.user.user.cpf,
      "2",
      "",
      this.auth.user.user.email,
      this.auth.user.user.telefone,
      "",
      this.util);
  }

 async buscarDadosTestador() {
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/DadosTestador";
    return await this.ajax.getAsync(url,response => {
        if (response.data.length > 0) {
          let dados = response.data[0];
          if(dados != null){
            this.testador = new CadastroTestador(
              dados.nome,
              dados.nacionalidade,
              this.util.formatDateStringOnlyDate(dados.dataNascimento),
              dados.nomePai,
              dados.nomeMae,
              dados.profissao,
              this.auth.user.user.cpf,
              this.util.convertEstadoCivil(dados.estadoCivil),
              dados.endereco,
              this.auth.user.user.email,
              dados.telefone,
              "",
              this.util);
          }
        }
      });
  }

  irParaExecutoresEBeneficiarios(){
    this.onChangeAba.emit('excecutores-beneficiarios');
  }

  async salvar() {

    if (this.testador.validar()) {
      let dados = this.testador.montarJson();
      let url = API + "Auth/Client";
      await this.ajax.putAsync(url, dados,response => {

        this.util.toasterShowSuccess("Atenção", "Testador Atualizado com Sucesso!");
        this.buscarDadosTestador();
        this.irParaExecutoresEBeneficiarios();
      });

    }
  }

}

import {Component, DoCheck, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {GradientConfig} from '../../../../../app-config';
import { AuthService } from 'src/app/service/auth.service';
import { User } from 'src/app/model/User.model';
import { UtilService } from 'src/app/service/util.service';
import { Router } from '@angular/router';
import { TestamentoEdicao } from '../../../../../model/TestamentoEdicao';
import { AjaxService } from 'src/app/service/ajax.service';
import { API } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {

  public activeMenuTop: boolean;
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  public user: User;
  public testamentoEdicao:TestamentoEdicao;
  public isInEdicao:boolean;
  public isTestadorClienteAdvogado:boolean = false;

  constructor(
    private auth: AuthService,
    public util: UtilService,
    private ajax: AjaxService,
    private router: Router
    ) {
    this.activeMenuTop = this.activeMenuTop;
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;
  }

  isActive(url: string): boolean {
    return this.router.isActive(url, true);
  }

  ngOnInit() {
    this.testamentoEdicao = new TestamentoEdicao();
   // this.isInEdicao = this.testamentoEdicao.buscarTestamentoEmEdicao();
    this.user = this.auth.getUser().user;
    this.isTestadorClienteAdvogado = this.user.isTestadorClienteAdvogado;
   }

   Sair(){
    this.testamentoEdicao.limparTestamentoEmEdicao();
    this.auth.logout();
   }

   async home(){

    let url = `${API}TestamentoDigital/Testamento/Em/Andamento`;
    await this.ajax.getAsync(url,response => {
        if (response.testamento == null) {
          this.util.ExibirModalAtencao("Não há Testamento Digital sendo criado. Caso deseje criar um novo acionar o botão Novo Testamento.");

        } else {
          this.router.navigate(['/home']);
        }
      });
   }

   gerenciamento(){
    this.router.navigate(['/gerenciar-testamento']);
   }

  onChatToggle(friendID) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }
}

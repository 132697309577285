import { Component, Input, OnInit,ViewChild,ElementRef, EventEmitter, Output  } from '@angular/core';
import { LinhaSenha, Senha } from 'src/app/model/Senhas.model';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, MENSAGEM } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TestamentoEdicao } from '../../model/TestamentoEdicao';

@Component({
  selector: 'app-tab-senhas',
  templateUrl: './tab-senhas.component.html',
  styleUrls: ['./tab-senhas.component.scss']
})
export class TabSenhasComponent implements OnInit {

  @Input() homeValidacao: ValidacaoModel;

  @Output()
  onChangeAbaLeitura = new EventEmitter<string>();

  @Output()
  onChangeAbaVindoAnexos = new EventEmitter<number>();

  private testamentoEdicao :TestamentoEdicao;
  public senha: Senha;
  public linhaSenha: LinhaSenha;
  public arraySenhas: any;
  public isAdvogadoUtilizando: boolean = false;
  public isTestadorClienteAdvogado: boolean = false;

  @ViewChild('botaoFecharModalSenhas')
  botaoFecharModalSenhas: ElementRef;

  constructor(
    public util: UtilService,
    private auth: AuthService,
    private ajax: AjaxService
  ) {
    this.testamentoEdicao = new TestamentoEdicao();
    this.senha = new Senha([], "", this.util);
    this.homeValidacao = new ValidacaoModel(ajax, util, auth);
    this.limpar();
    this.buscarTestamento();
    let usuarioLogado = this.auth.getUser().user;
    this.isAdvogadoUtilizando = usuarioLogado.isAdvogado || usuarioLogado.isAdministradorEmpresa;
    this.isTestadorClienteAdvogado = usuarioLogado.isTestadorClienteAdvogado;
  }

  buscarTestamento() {

    if(this.testamentoEdicao.buscarTestamentoEmEdicao() ){
      this.inicializarTestamento(this.testamentoEdicao.Testamento);
      return true;
     }

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();
       // this.irParaLeitura();
        if (response.testamento != null) {
          this.inicializarTestamento(response.testamento);
        } else {

        }

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  inicializarTestamento(testamento:any){
    this.auth.setTestamento(testamento);
    this.auth.getTestamento();
    this.inicializar();
}


  inicializar() {
    this.senha = new Senha([], this.auth.testamentoAtivo.guid, this.util);
    this.buscarSenhasSalvas();
  }

  ngOnInit() {

  }

  limpar() {
    this.linhaSenha = new LinhaSenha();
    this.linhaSenha.Descricao = "";
    this.linhaSenha.Senha = "";
  }

  buscarSenhasSalvas() {

    let url = API + "TestamentoDigital/Testamento/" + this.senha.IdTestamento + "/DadosLivres/"+this.getChave();
    this.util.showLoading();
    this.ajax.get(url).subscribe(response => {
      this.arraySenhas = response.data;
      this.onChangeAbaVindoAnexos.emit(this.arraySenhas.length);
      this.util.closeLoading();
     // this.irParaLeitura();
      this.homeValidacao.buscarTestamento();
    }, err => {
      this.util.closeLoading();
      this.util.toasterShowError("Atenção", "Erro ao buscar Senhas");
    });
  }

  async deletarSenha(id:string, idTestamento:string):Promise<void>{

    this.util.Confirmacao("Tem certeza que deseja remover o registro?",async ()=>{

      var url = `${API}TestamentoDigital/DadoLivre/${id}/${idTestamento}`;
      this.ajax.deleteAsync(url, r=>{
        this.util.toasterShowSuccess("Atenção", "Deletado com Sucesso");
        this.buscarSenhasSalvas();
      });

    });
  }

  getChave(){
    let chave = "0";

    if(this.testamentoEdicao.Testamento && this.testamentoEdicao.Testamento.chave)
      chave= this.testamentoEdicao.Testamento.chave;

      return chave;
  }

  async visualizarSenha(id:string, idTestamento:string):Promise<void>{


      var url = `${API}TestamentoDigital/DadoLivre/${id}/${idTestamento}/${this.getChave()}`;
    this.ajax.getAsync(url, r=>{

    if(r && r.data && r.data.length)
    Swal.fire("Senha", r.data[0].valor);
    });
  }

  addSenha() {
    let dados = this.senha.montarItemJson(this.linhaSenha);
    dados.ChaveTestamento = this.getChave();
    if (dados != undefined) {
      let url = API + "TestamentoDigital/DadoLivre";
      this.util.showLoading();
      this.ajax.post(url, dados).subscribe(response => {
        this.buscarSenhasSalvas();
        this.limpar();
        this.util.toasterShowSuccess("Atenção", "Senha Salva com Sucesso!");
        this.util.closeLoading();
        this.botaoFecharModalSenhas.nativeElement.click();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Senha");
      });
    }
  }

  irParaLeitura(){
    if(!this.isTestadorClienteAdvogado)
      this.onChangeAbaLeitura.emit('leitura');
  }
}

import { Component, Input, OnInit,ViewChild,ElementRef, EventEmitter, Output   } from '@angular/core';
import { ExcecutoresBeneficiarios, LinhaBeneficiario, LinhaExecutor } from 'src/app/model/ExcecutoresBeneficiarios.model';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF, maskData, maskTelefoneCelular, MENSAGEM } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TestamentoEdicao } from '../../model/TestamentoEdicao';

@Component({
  selector: 'app-tab-excecutores-beneficiarios',
  templateUrl: './tab-excecutores-beneficiarios.component.html',
  styleUrls: ['./tab-excecutores-beneficiarios.component.scss']
})
export class TabExcecutoresBeneficiariosComponent implements OnInit {

  private testamentoEdicao :TestamentoEdicao;
  @Input() homeValidacao: ValidacaoModel;
  public utils:UtilService;
  public isRegistroExisteExecutor:boolean;
  public isRegistroExisteBeneficiario:boolean;
  public activeTabExcec: string;
  public executoresBeneficiarios: ExcecutoresBeneficiarios;
  public arrayExecutor: any[];
  public linhaExecutor: LinhaExecutor;
  public urlDownloadTermoExecutor:string;
  public arrayBeneficiario: any[];
  public linhaBeneficiario: LinhaBeneficiario;
  public idTipoEntregaChaveSelecionado: [];
  public maskCPF = maskCPF;
  public maskData = maskData;
  public maskTelefoneCelular = maskTelefoneCelular;
  public isAdvogadoUtilizando:boolean;
  public testamentoEmAndamento: any;
  public isTestadorClienteAdvogado:boolean;
  desativarCamposExecutor: any = true;
  toggleDataDesativarExecutor() {
    this.desativarCamposExecutor = !this.desativarCamposExecutor;
  }
  desativarCamposBeneficiario: any = true;
  toggleDataDesativarBeneficiario() {
    this.desativarCamposBeneficiario = !this.desativarCamposBeneficiario;
  }

  @ViewChild('botaoFecharModalBeneficiario')
  botaoFecharModalBeneficiario: ElementRef;

  @ViewChild('botaoFecharModalExecutor')
  botaoFecharModalExecutor: ElementRef;

  @Output()
  onChangeAba = new EventEmitter<string>();

  constructor(
    public util: UtilService,
    private auth: AuthService,
    private ajax: AjaxService,
  ) {
    this.testamentoEdicao = new TestamentoEdicao();
    this.activeTabExcec = 'executores';
    this.executoresBeneficiarios = new ExcecutoresBeneficiarios([], true, false, false, false, "", "", this.util);
    this.homeValidacao = new ValidacaoModel(ajax, util, auth);
    this.utils=util;
    this.limparExecutor();
    this.limparBeneficiario();
    this.buscarTestamento();
    this.isRegistroExisteExecutor=true;
    this.isRegistroExisteBeneficiario=true;

    let usuarioLogado = this.auth.getUser().user;
    this.isAdvogadoUtilizando = usuarioLogado.isAdvogado || usuarioLogado.isAdministradorEmpresa
    this.isTestadorClienteAdvogado = usuarioLogado.isTestadorClienteAdvogado;
  }

  //idTipoAssinaturaTestemunha
  inicializar() {
    this.auth.getTestamento();
    this.executoresBeneficiarios = new ExcecutoresBeneficiarios([], true, false, false, false, "", this.auth.testamentoAtivo.guid, this.util);
    if (this.auth.existeTestamento()) {
      this.idTipoEntregaChaveSelecionado = this.auth.testamentoAtivo.entregasChave;
    }
    this.urlDownloadTermoExecutor = `${API}TestamentoDigital/Testamento/${this.auth.testamentoAtivo.guid}/DownloadTermoCompromissoExecutor`;
  }

  entregaChaveCadastrada(chave: string) {
    var encontrado = this.idTipoEntregaChaveSelecionado.find(f => f['idTipoEntregaChave'] == chave);
    return encontrado;
  }

  irParaBeneficiarios(){
    this.activeTabExcec = "beneficiarios";
  }

  irParaTestamento(){
    this.onChangeAba.emit('testamento');
  }

  buscarTestamento() {

    if(this.testamentoEdicao.buscarTestamentoEmEdicao() ){
      this.inicializarTestamento(this.testamentoEdicao.Testamento);
      return true;
     }

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();
        if (response.testamento != null) {
          this.inicializarTestamento(response.testamento);
        } else {

        }
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  inicializarTestamento(testamento:any){
    this.auth.setTestamento(testamento);
    this.auth.getTestamento();
    this.inicializar();
}

  limparExecutor() {
    this.linhaExecutor = new LinhaExecutor();
    this.linhaExecutor.CPF = "";
    this.linhaExecutor.DataNascimento = "";
    this.linhaExecutor.Email = "";
    this.linhaExecutor.Nome = "";
    this.linhaExecutor.Telefone = "";
    this.isRegistroExisteExecutor = true;
  }

  limparBeneficiario() {
    this.linhaBeneficiario = new LinhaBeneficiario();
    this.linhaBeneficiario.CPF = "";
    this.linhaBeneficiario.DataNascimento = "";
    this.linhaBeneficiario.Email = "";
    this.linhaBeneficiario.Nome = "";
    this.linhaBeneficiario.Telefone = "";
    this.isRegistroExisteBeneficiario = true;
  }


  ngOnInit() {
    this.carregarExecutor();
    this.carregarBeneficiario();
  }

  async carregarExecutor() {
    this.auth.getTestamento();
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Executores";
    return await this.ajax.getAsync(url, response => {
      this.arrayExecutor = response.data;
      this.util.closeLoading();
    });
  }

  async addExecutor() {
    let dados = this.executoresBeneficiarios.montarExecutorJson(this.linhaExecutor);
    if (dados != undefined) {
      let url = API + "TestamentoDigital/Executor";
      return await this.ajax.postAsync(url, dados, async response => {
        this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        this.linhaExecutor = new LinhaExecutor();
        await this.carregarExecutor();
        this.homeValidacao.buscarTestamento();
        this.botaoFecharModalExecutor.nativeElement.click();

      });
    }
  }

  async tornarAdvogadoUmExecutor(){
    let idTestamento =  this.auth.getTestamento().guid;
    let dados = {
      "idTestamento": idTestamento
    }

      let url = API + "TestamentoDigital/ExecutorAdvogado";
      return await this.ajax.postAsync(url, dados, async response => {
        this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        this.linhaExecutor = new LinhaExecutor();
        await this.carregarExecutor();
        this.homeValidacao.buscarTestamento();
      });

  }

  async carregarBeneficiario() {
    this.auth.getTestamento();
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Beneficiarios";
    return await this.ajax.getAsync(url, response => {
      this.arrayBeneficiario = response.data;
    });
  }

  async addBeneficiario() {
    let dados = this.executoresBeneficiarios.montarExecutorJson(this.linhaBeneficiario);
    if (dados != undefined) {
      let url = API + "TestamentoDigital/Beneficiario";
      return this.ajax.postAsync(url, dados, async response => {
        this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        this.linhaBeneficiario = new LinhaBeneficiario();
        await this.carregarBeneficiario();
        this.homeValidacao.buscarTestamento();
        this.botaoFecharModalBeneficiario.nativeElement.click();
      });
    }
  }

  async setFormaEntregaChave(e: any) {
    var dados = { IdTipoEntregaChave: e.target.value };
    var checked = e.target.checked;
    if (this.auth.existeTestamento()) {
      if (checked) {

        let url = `${API}TestamentoDigital/${this.auth.testamentoAtivo.guid}/AlterarTipos`;
        await this.ajax.patchAsync(url, dados, async response => {

          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        });
      } else {
        let url = `${API}TestamentoDigital/${this.auth.testamentoAtivo.guid}/TipoEntregaChave/${dados.IdTipoEntregaChave}`;
        await this.ajax.deleteAsync(url, async response => {

          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        });
      }

    }
  }

  async alterarBeneficiarioCienciaTestamento(e:any,id:string, idTestamento:string){

    var url = `${API}TestamentoDigital/Testamento/${idTestamento}/Beneficiario/${id}/AlterarCiencia`;
    var dados = {TornarBeneficiarioCiente:e.target.checked};
    this.ajax.patchAsync(url,dados,async r=>{
      this.util.toasterShowSuccess("Atenção",dados.TornarBeneficiarioCiente? "O BENEFICIÁRIO será comunicado acerca da criação do TESTAMENTO DIGITAL" : "O BENEFICIÁRIO NÃO será comunicado acerca da criação do TESTAMENTO DIGITAL");

    });
  }

async deletarBeneficiario(id:string, idTestamento:string):Promise<void>{

  this.util.Confirmacao("Tem certeza que deseja remover o registro?",async ()=>{

    var url = `${API}TestamentoDigital/Beneficiario/${id}/${idTestamento}`;
    this.ajax.deleteAsync(url,async r=>{
      this.util.toasterShowSuccess("Atenção", "Deletado com Sucesso");
      await this.carregarBeneficiario();
    });

  });
}

async deletarExecutor(id:string, idTestamento:string):Promise<void>{

  this.util.Confirmacao("Tem certeza que deseja remover o registro?",async ()=>{

    var url = `${API}TestamentoDigital/Executor/${id}/${idTestamento}`;
    this.ajax.deleteAsync(url,async r=>{
      this.util.toasterShowSuccess("Atenção", "Deletado com Sucesso");
      await this.carregarExecutor();
    });

  });
}

  async verificarSeUsuarioExisteAsync(tipo:string):Promise<void>{

    if(tipo == 'executor')
    {
      if(this.linhaExecutor.CPF && this.utils.apenasNumeros(this.linhaExecutor.CPF).length >= 11){
        const url = `${API}TestamentoDigital/TestamentoPessoa/${this.auth.testamentoAtivo.guid}/${ this.linhaExecutor.CPF}`
        this.ajax.getAsync(url,response =>{

          this.isRegistroExisteExecutor=false;

          if(response){
            this.linhaExecutor.DataNascimento = response.dataNascimento;
            this.linhaExecutor.Email = response.email;
            this.linhaExecutor.Nome = response.nome;
            this.linhaExecutor.Telefone = response.telefone;
           }else{


           }
        });
         }

    }else{

      if(this.linhaBeneficiario.CPF && this.utils.apenasNumeros(this.linhaBeneficiario.CPF).length >= 11){
        const url = `${API}TestamentoDigital/TestamentoPessoa/${this.auth.testamentoAtivo.guid}/${ this.linhaBeneficiario.CPF}`
        this.ajax.getAsync(url,response =>{

          this.isRegistroExisteBeneficiario=false;

          if(response){
            this.linhaBeneficiario.DataNascimento = response.dataNascimento;
            this.linhaBeneficiario.Email = response.email;
            this.linhaBeneficiario.Nome = response.nome;
            this.linhaBeneficiario.Telefone = response.telefone;
           }else{

           }
        });
         }
    }


   }


}

import { Injectable, OnInit } from '@angular/core';
import { Component } from '@angular/core';

import { getContainers, createContainer, listBlob, BLOBItem, CONTENT, uploadFile, deleteBlob } from './azureStorage';

@Injectable({
  providedIn: 'root'
})

export class AzureComponent implements OnInit {

  containers: any = [];
  selectedContainer: string = '';
  listItems: any = [];
  constructor() {
  }

  ngOnInit(): void {
    this.selectContainer("testamentofiles");
  }

  async getContainers() {
    getContainers().then((res: Array<string>) => {
      this.containers = res;
    })
  }



  async upload(file: any, filename:string) {

    if (file) {
        let content: CONTENT = {
          containerName: this.selectedContainer,
          file: file,
          filename: filename
        };

        await uploadFile(content);
    }
  }

  create(value: string) {
    createContainer(value).then((resp) => {
    });

  }

  selectContainer(containerName: string) {
    this.selectedContainer = containerName;
  /*  listBlob(containerName).then((res: Array<BLOBItem>) => {
      this.listItems = res;
    })*/
  }
/*
  delete(value: string) {
    deleteBlob(this.selectedContainer, value).then((resp: string) => {
    });
  }

  deleteContainer(value: string) {
    deleteContainer(value).then((resp: string) => {
    });
  }*/
}

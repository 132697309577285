<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>

<div class="row" *ngIf="controlaDiv?.construir">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <app-card [options]="false" [hidHeader]="true">
        <h5 class="card-title"></h5>
        <br> <br>
        <div class="row ">
          <div class="col-md-12 " align="center">
            <button type="button" class="btn  button-rounded-green" style="padding-left: 40px; padding-right: 40px;"
              (click)="buildTestamento()"><i class="feather icon-upload-cloud"></i>
              Finalizar e Gerar Chave
            </button>
          </div>
        </div>
        <br> <br>
      </app-card>
    </div>
</div>

<div class="row" *ngIf="controlaDiv?.download">
    <div class="col-md-3"></div>

    <div class="col-md-6" *ngIf="chave?.dados?.seraConstruidoDepoisDasAssinaturasNoClickSign">
      <app-card [options]="false" [hidHeader]="true">
        <h5 class="card-title"></h5>
        <br> <br>
        <div class="row ">
            <div class="col-md-12 " align="center">
                {{mensagem}}}
            </div>
        </div>
        <br> <br>
      </app-card>
    </div>

    <div class="col-md-6" *ngIf="!chave?.dados?.seraConstruidoDepoisDasAssinaturasNoClickSign">
      <app-card [options]="false" [hidHeader]="true">
        <h5 class="card-title"></h5>
        <br> <br>
        <div class="row ">
            <div class="col-md-12 " align="center">
                <p>Parabéns!
                  Seu Testamento Digital foi criptografado. Guarde sua Chave de Acesso, mas não se preocupe, a CHAVE SERÁ ENVIADA POR E-MAIL.</p>
            </div>
          <div class="col-md-12 chaveTestamento" align="center">
              <p>Chave de Acesso</p>
              <h3  class="text-c-blue"> {{ chave?.dados?.chave }}</h3>
          </div>
        </div>
        <br> <br>
      </app-card>
    </div>
</div>


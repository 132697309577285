import { ResponseUser } from './../model/User.model';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../model/User.model';
import { API } from 'src/environments/environment';
import { AjaxService } from './ajax.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: ResponseUser;
  testamentoAtivo: any;
  lastUrl: string;
  storageName: string;
  storageNameTestamento: string;
  constructor(private router: Router) {
    this.storageName = "const_config_qrcode";
    this.storageNameTestamento = "const_config_testamento_ativo";
  }


  isLoggedIn(): boolean{

    this.getUser();
    return this.user !== null;
  }


  clearUser() {
    localStorage.removeItem(this.storageName);
  }

  setUser(user: ResponseUser) {
    localStorage.setItem(this.storageName, JSON.stringify(user));
  }

  getUser(): ResponseUser {
    this.user = JSON.parse(localStorage.getItem(this.storageName));
    return this.user;
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['\login'])
  }

  logout() {
    this.clearUser();
    this.clearTestamento();
    this.user = undefined;
    this.router.navigate(['\login'])

  }


  clearTestamento() {
    localStorage.removeItem(this.storageNameTestamento);
  }

  setTestamento(testamen: any) {
    localStorage.setItem(this.storageNameTestamento, JSON.stringify(testamen));
  }

  getTestamento(): any {
    this.testamentoAtivo = JSON.parse(localStorage.getItem(this.storageNameTestamento));
    return this.testamentoAtivo;
  }

  existeTestamento(): boolean {
    this.testamentoAtivo = JSON.parse(localStorage.getItem(this.storageNameTestamento));
    return this.testamentoAtivo != null && this.testamentoAtivo;
  }

  /*loginJson(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/login.json');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    return req.send();
  }*/
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF, maskData, maskTelefoneCelular, TIPO_USUARIO_EMPRESA } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.scss']
})
export class CadastroUsuarioComponent implements OnInit {

  // toDisplayValidarViaWhatsApp: any = true;
  // toggleValidarViaWhatsApp() {
  //   this.toDisplayValidarViaWhatsApp = !this.toDisplayValidarViaWhatsApp;
  // }

  @ViewChild('btnExibirModalTermosDeUso')
  btnExibirModalTermosDeUso: ElementRef;

  @ViewChild('btnFecharModalTermosDeUso')
  btnFecharModalTermosDeUso: ElementRef;
  perfilAdminId:string="acf963a1-cbc8-4e70-8a75-248a0a221dc5";
  nome: string;
  email: string;
  cpf: string;
  cpfCnpj : string;
  telefone: string;
  senha: string;
  numeroOAB:String;
  confirmacaoSenha: string;
  dataNascimento: string;
  maskTeleArea = maskTelefoneCelular;
  maskCpf = maskCPF;
  maskData = maskData;
  termoDeUsoAceito: boolean;
  public exibirRecuperacaoDeSenha: boolean = false;
  recuperarCpf: string;
  exibirPessoal: boolean;


  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService

  ) { }

  ngOnInit() {
    this.nome = "";
    this.email = "";
    this.senha = "";
    this.cpf = "";
    this.telefone = "";
    this.dataNascimento = "";
    this.confirmacaoSenha = "";
    this.termoDeUsoAceito = false;
    this.cpfCnpj='';
    this.numeroOAB = '';
    this.exibirPessoal = true;
  }

  validar(): boolean {

    if (this.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if(this.exibirPessoal){
      if (this.util.removeMask(this.cpfCnpj) == "") {
        this.util.toasterShowError("Atenção", "Preencha o campo CPF/CNPJ");
        return false;
      }

      if (this.util.removeMask(this.cpfCnpj).length >11) {
        if (this.numeroOAB == "") {
          this.util.toasterShowError("Atenção", "Preencha o Número da OAB");
          return false;
        }
      }
    }
    else{
      if (this.util.removeMask(this.cpf) == "") {
        this.util.toasterShowError("Atenção", "Preencha o campo CPF");
        return false;
      }

    }


    if (this.dataNascimento == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Data Nascimento");
      return false;
    }

    if (this.email == "") {
      this.util.toasterShowError("Atenção", "Preencha o Email.");
      return false;
    }

    if (this.util.removeMask(this.telefone) == "") {
      this.util.toasterShowError("Atenção", "Preencha o Telefone.");
      return false;
    }

    if (this.senha == "" || this.confirmacaoSenha == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Senha e Confirmação de Senha.");
      return false;
    }

    if (this.senha != this.confirmacaoSenha) {
      this.util.toasterShowError("Atenção", "Os campos Senha e Confirmação não são iguais.");
      return false;
    }

    if (!moment(this.dataNascimento, 'DD/MM/YYYY', true).isValid()) {
      this.util.toasterShowError("Atenção", "Erro na data de nascimento, data inválida!");
      return false;
    }

    return true;
  }

   // adicionar botão click exibir/ocultar div
   selectTipoPerfil() {
     this.exibirPessoal = !this.exibirPessoal;
   }


  mostrarRecuperacaoDeSenha() {
    this.recuperarCpf = '';
    this.exibirRecuperacaoDeSenha = true;
  }

  async salvar() {

    if (!this.termoDeUsoAceito) {
      this.util.toasterShowError("Atenção", "Para continuar você deverá aceitar o termo de uso!");
      return false;
    }
    let dados = {
      "nome": this.nome,
      "email": this.email,
      "login": this.email,
      "senha": this.senha,
      "dataNascimento": this.util.formatDateMaskSave(this.dataNascimento),
      "telefone": this.util.removeMask(this.telefone),
      "numeroOAB" : this.numeroOAB,
      "idTipoUsuarioEmpresa": TIPO_USUARIO_EMPRESA.Admin
    };

    if(this.exibirPessoal){
      dados['cpfCnpj'] =  this.cpfCnpj;
      dados['idTipoLogin'] =  this.perfilAdminId;
    }
    else{
      dados['cpfCnpj'] =   this.util.removeMask(this.cpf);
    }


    let url = API + "Auth/User";
    this.util.showLoading();
    await this.ajax.postAsync(url, dados, (resp) => {
      Swal.fire("Bem-Vindo", `O seu cadastro foi realizado com sucesso! Para continuar confirme sua identidade através do link enviado ao seu e-mail.<br /><br />Importante:<br />Registre nosso endereço "nao_responda@meutestamentodigital.com" em seu e-mail e garanta o recebimento de nossas comunicações.
      `);
      this.router.navigate(['/login']);
    }, errorMessage => {
      this.btnFecharModalTermosDeUso.nativeElement.click();
      let message = '';

      if (errorMessage && errorMessage.length)
        for (var i = 0; i < errorMessage.length; i++)
          message += errorMessage[i];
      else
        message += errorMessage;

      Swal.fire('Atenção', message);
    });
  }

  sair() {
    this.router.navigate(['/login']);
  }

  validarEAbrirTermos() {
    if (this.validar()) {
      this.btnExibirModalTermosDeUso.nativeElement.click();
    }
  }

  async cadastrar() {
    if (this.validar()) {
      await this.salvar();
    }
  }

  async enviarEmailRecuperacaoDeSenha() {

    if (this.recuperarCpf == "") {
      this.util.toasterShowError("Atenção", "Preencha o Cpf");
      return false;
    }

    var url = `${API}auth/User/${this.recuperarCpf}/GerarLinkRedefinicaoPorEmail`;
    await this.ajax.postAsync(url, null, response => {
      this.util.toasterShowSuccess("Sucesso", "E-mail de recuperação de senha enviado para o E-mail cadastrado!");
    });
  }
}

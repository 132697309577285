import { Component, Input, OnInit,ViewChild,ElementRef, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { Testamento, Testemunha } from 'src/app/model/Testamento.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { MOBILE_RES_VALUE, API, maskCPF, maskData, maskTelefoneCelular } from 'src/environments/environment';
import { TestamentoEdicao } from '../../model/TestamentoEdicao';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Validar } from 'src/app/model/Validar';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-tab-testamento',
  templateUrl: './tab-testamento.component.html',
  styleUrls: ['./tab-testamento.component.scss']
})
export class TabTestamentoComponent implements OnInit {

  @Input() homeValidacao: ValidacaoModel;

  @ViewChild('botaoFecharModalTestemunha')
  botaoFecharModalTestemunha: ElementRef;

  @ViewChild('botaoFecharModalPreencherTestamento')
  botaoFecharModalPreencherTestamento: ElementRef;

  @ViewChild('botaoFecharModalListarBens')
  botaoFecharModalListarBens: ElementRef;

  @Output()
  onChangeAba = new EventEmitter<string>();

  public isMobile = false;
  private testamentoEdicao :TestamentoEdicao;
  public isRegistroExiste:boolean;
  public urlDownload: string;
  public testamento: Testamento;
  public testemunha: Testemunha;
  public activeTabTestamento: string;
  public isAssinaturaDigital:boolean;
  public arrayTestemunhas: any[];
  public maskCPF = maskCPF;
  public maskData = maskData;
  public maskTelefoneCelular = maskTelefoneCelular;
  public haveraDisposicaoDeBens:boolean;
  public idTipoAssinaturaTestemunhaSelecionado:string;
  public objTestamento: any;
  public IdTipoAssinaturaManualTestemunha :string = 'cff963a1-cbc8-4e70-8a75-248a0a221dc1';
  public utils:UtilService;
  public fimBensEDireitos:string = ""
  public inicioBensEDireitos:string = "DECLARAÇÃO DE BENS E DIREITOS PRÉ-CADASTRADOS:\n\n"

  public bensEDireitos:Array<any> = [{ id:'switchImovelUrbano'
  , texto:'Imóvel urbano no Brasil'
  , quantidade : 0
  , ordem : 1
 },
 { id:'switchImovelRural'
  , texto:'Imóvel rural no Brasil'
  , quantidade : 0
  , ordem : 2
 },
 { id:'switchImovelExterior'
  , texto:'Imóvel no exterior'
  , quantidade : 0
  , ordem : 3
 },
 { id:'switchAcoesBolsaFii'
  , texto:'Ações em Bolsa, FII, ETF ou BDR'
  , quantidade : 0
  , ordem : 4
 },
 { id:'switchAcoesJudiciais'
  , texto:'Ações Judiciais/Precatórios'
  , quantidade : 0
  , ordem : 5
 },
 { id:'switchObrasDeArte'
 , texto:'Obras de arte'
  , quantidade : 0
  , ordem : 6
 },
 { id:'switchParticipacoesSocietarias'
 , texto:'Participações societárias'
  , quantidade : 0
  , ordem : 7
 },
 { id:'switchPrevidencia'
 , texto:'Previdência privada/seguro de vida'
  , quantidade : 0
  , ordem : 8
 },
 { id:'switchFgts'
 , texto:'Saldo de FGTS/PIS-PASEP'
  , quantidade : 0
  , ordem : 9
 },
 { id:'switchSaldoAplicacaoBancaria'
 , texto:'Saldo em contas/aplicações bancárias'
  , quantidade : 0
  , ordem : 10
 },
 { id:'switchSemovente'
 , texto:'Semovente'
  , quantidade : 0
  , ordem : 11
 },
 { id:'switchVeiculo'
 , texto:'Veículo'
  , quantidade : 0
  , ordem : 12
 },
 { id:'switchOutros'
 , texto:'Outros'
 , quantidade : 0
 , ordem : 13
}
 ];



  constructor(
    public util: UtilService,
    private ajax: AjaxService,
    public auth: AuthService,
    @Inject(DOCUMENT) document: Document
  ) {
    this.testamentoEdicao = new TestamentoEdicao();
    this.testamento = new Testamento("", [], true, false, false, "", this.util, this.ajax);
   // this.haveraDisposicaoDeBens = this.testamento.HaveraDisposicaoBens;
    this.homeValidacao = new ValidacaoModel(ajax, util, auth);
    this.limparTestemunha();
    this.activeTabTestamento = "testamento";
    this.buscarTestamento();
    this.isRegistroExiste=true;
    this.utils=util;

  }



  async alterarTipoTestamento(event: any)
  {
    let valorCorrente = this.haveraDisposicaoDeBens;
    let mensagem = this.haveraDisposicaoDeBens ? "Você optou por fazer um Testamento Particular. Deseja continuar?" : "Você optou por não fazer um Testamento Particular. Caso confirme, os dados das abas Testamento Particular e Testemunhas serão apagados. Deseja Continuar?";

    this.util.Confirmacao(mensagem,async ()=>{
      const disposicaoDeBens = !this.haveraDisposicaoDeBens;
      const url = `${API}TestamentoDigital/${ this.testamento.IdTestamento}/AlterarTipo`;
      const dados = {"PossuiDivisaoDeBens":disposicaoDeBens};

      await this.ajax.patchAsync(url, dados,(response) => {
        this.util.toasterShowSuccess("Atenção", "Alterado com Sucesso!");

        if(this.haveraDisposicaoDeBens)
           this.irParaAnexos();
      });

    },() => {
      this.haveraDisposicaoDeBens=valorCorrente;
    });
 }



  buscarTestamento() {

    if(this.testamentoEdicao.buscarTestamentoEmEdicao() ){
      this.inicializarTestamento(this.testamentoEdicao.Testamento);
      return true;
     }

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();
        if (response.testamento != null) {
         this.inicializarTestamento(response.testamento);
        } else {
          this.auth.clearTestamento();

        }

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  inicializarTestamento(testamento:any){
    this.auth.setTestamento(testamento);
    this.auth.getTestamento();
    this.testamento.HaveraDisposicaoBens = this.util.convertDisposicaoBens(this.auth.testamentoAtivo.tipo);
    this.isAssinaturaDigital = testamento.isAssinaturaDigital;
    this.inicializar();
}


  inicializar() {
    this.auth.getTestamento();
    let guid = this.auth.testamentoAtivo != null ? this.auth.testamentoAtivo.guid : null;
    this.testamento.IdTestamento = guid;
    this.carregarTestamento();
    this.carregarTemunhas();
    this.haveraDisposicaoDeBens =!this.util.convertDisposicaoBens(this.auth.testamentoAtivo.tipo);


    if(this.auth.existeTestamento()){
      this.idTipoAssinaturaTestemunhaSelecionado = this.auth.testamentoAtivo.idTipoAssinaturaTestemunha;
    }

    this.urlDownload = `${API}TestamentoDigital/Cliente/${this.auth.testamentoAtivo.idCliente}/Testamento/${guid}/DownloadApenasPrincipal`;

  }

  ngOnInit() {
    this.detectMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
     this.detectMobile();
   }

   detectMobile(){
    this.isMobile = window.innerWidth <= MOBILE_RES_VALUE;
   }


  irParaAnexos(){
    this.onChangeAba.emit('anexos');
  }

  limparTestemunha() {
    this.arrayTestemunhas = [];
    this.testemunha = new Testemunha();
    this.testemunha.CPF = "";
    this.testemunha.DataNascimento = "";
    this.testemunha.Email = "";
    this.testemunha.Nome = "";
    this.testemunha.Telefone = "";
    this.isRegistroExiste = true;
  }

  async deletarTestemunha(id:string, idTestamento:string):Promise<void>{

    this.util.Confirmacao("Tem certeza que deseja remover o registro?",async ()=>{

      var url = `${API}TestamentoDigital/Testemunha/${id}/${idTestamento}`;
    this.ajax.deleteAsync(url,async r=>{
      this.util.toasterShowSuccess("Atenção", "Deletado com Sucesso");
      await this.carregarTemunhas();
    });

    });
  }

  async verificarSeTestemunhaExisteAsync():Promise<void>{
    if(this.testemunha.CPF && this.utils.apenasNumeros(this.testemunha.CPF).length >= 11){
    const url = `${API}TestamentoDigital/TestamentoPessoa/${this.auth.testamentoAtivo.guid}/${ this.testemunha.CPF}`
    this.ajax.getAsync(url,response =>{

      this.isRegistroExiste=false;

      if(response){
        this.testemunha.DataNascimento = response.dataNascimento;
        this.testemunha.Email = response.email;
        this.testemunha.Nome = response.nome;
        this.testemunha.Telefone = response.telefone;
       }else{

       }
    });
     }
   }


   mais(elementId:any){
    var element = (<HTMLInputElement>document.getElementById(elementId));
    element.value = (parseInt(element.value)+1).toString();
    this.updateBensEDireitos(element.id,  element.value );
   }

   atualizarValorAtual(elementId:any){
    var element = (<HTMLInputElement>document.getElementById(elementId));

    if(!element.value || element.value.length > 8 || element.value.indexOf('-') >= 0 || element.value.indexOf('+') >= 0){
      element.value='0';
      this.updateBensEDireitos(element.id,  0 );
      return;
    }

    this.updateBensEDireitos(element.id,  element.value );
   }

   menos(elementId:any){
    var element = (<HTMLInputElement>document.getElementById(elementId));
    var valor = parseInt(element.value);

    if(valor >0){
      element.value = (parseInt(element.value)-1).toString();

      if(valor >=0)
        this.updateBensEDireitos(element.id,  element.value );
    }
   }

   updateBensEDireitos(elementId, value){
    this.bensEDireitos.find(e => e.id === elementId).quantidade = value;
   }

   salvarBensEDireitos(modal){
    let texto = "";

    if(!this.testamento.ComplementoTestamento)
      this.testamento.ComplementoTestamento = "";

      this.testamento.ComplementoTestamento += this.inserirInicioBensDireitos();

      for(let i=0; i<= this.bensEDireitos.length; i++){
      let item = this.bensEDireitos[i];

      if(item && item.quantidade)
        this.inserirBemEDireito(item);
    }

    this.testamento.ComplementoTestamento += this.inserirFimBensDireitos();

    //ToDo enviar para o back
    this.util.closeModal(modal);
   }

   abrirModalBensEDireitos(modal){
      this.util.openModal(modal);
      this.carregarBensEDireitos();
   }

   carregarBensEDireitos(){
    for(let i=0; i<= this.bensEDireitos.length; i++){
      let item = this.bensEDireitos[i];
      if(item && item.texto){

        var inicioTextoItem = this.testamento.ComplementoTestamento.indexOf(item.texto);
        var element = (<HTMLInputElement>document.getElementById(item.id));

        if(inicioTextoItem >=0){
           let itemTexto = this.copiarAte(inicioTextoItem,')');
           var quantidade =  itemTexto.replace(/[^0-9]/g,'');
           this.bensEDireitos.find(e => e.id === item.id).quantidade = quantidade;
           element.value = quantidade.toString();
       }
       else{
           element.value = '0';
           this.bensEDireitos.find(e => e.id === item.id).quantidade = 0;
      }
    }
    }
   }

   inserirBemEDireito(item){

    var inicio = this.testamento.ComplementoTestamento.indexOf(this.inicioBensEDireitos);
    var inicioTextoItem = this.testamento.ComplementoTestamento.indexOf(item.texto)
    debugger;
    if(inicioTextoItem >=0){

      let itemTexto = this.copiarAte(inicioTextoItem,')');
      let novoTextoItem = this.montarTextoItem(item);
      this.testamento.ComplementoTestamento = this.testamento.ComplementoTestamento.replace(itemTexto,novoTextoItem);
    }else{
      inicio = inicio + this.inicioBensEDireitos.length;
      this.testamento.ComplementoTestamento =  this.testamento.ComplementoTestamento.substring (0, inicio) + `\u2022 ${this.montarTextoItem(item)}: Complete as informações\n` + this.testamento.ComplementoTestamento.substring (inicio);
    }
   }

   copiarAte(inicioTextoItem, ate){

      var encontrado = false;
      let contador = 0;
      let itemTexto = '';

      while(!encontrado){

        itemTexto += this.testamento.ComplementoTestamento.charAt(inicioTextoItem);

        if(this.testamento.ComplementoTestamento.charAt(inicioTextoItem) == ate)
          break;

        contador++;
        inicioTextoItem++;

        if(contador == 1000)
        {
          alert(`Problemas ao inserir bem ${inicioTextoItem}`);
          return '';
        }
      }

      return itemTexto;
   }


   montarTextoItem(item){
     return `${item.texto}(${item.quantidade})`;
   }


   inserirFimBensDireitos(){
     return this.testamento.ComplementoTestamento &&
            this.testamento.ComplementoTestamento.indexOf(this.fimBensEDireitos) >= 0
            ? ''
            : `\n\n${this.fimBensEDireitos}`;
   }


   inserirInicioBensDireitos(){
    return this.testamento.ComplementoTestamento &&
           this.testamento.ComplementoTestamento.indexOf(this.inicioBensEDireitos) >= 0
           ? ''
           : `\n\n${this.inicioBensEDireitos}`;
  }

  carregarTestamento() {
    this.auth.getTestamento();
    if (this.auth.testamentoAtivo.guid != undefined) {
      this.util.showLoading();
      let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Principal";
      return this.ajax.get(url)
        .subscribe(response => {
          this.objTestamento = response.data[0];
          this.testamento.ComplementoTestamento = this.objTestamento.corpo;
          this.util.closeLoading();
        }, err => {
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar");
        });
    } else {
      this.util.toasterShowError("Atenção", "Escolha se ira dispor de bens primeiro");
    }
  }

  salvarTestamento() {
    this.auth.getTestamento();
    if (this.auth.testamentoAtivo.guid != undefined) {
      let dados = { corpo: this.testamento.ComplementoTestamento };
      this.util.showLoading();
      let url = API + "TestamentoDigital/TestamentoPrincipal/" + this.auth.testamentoAtivo.guid;
      return this.ajax.patch(url, dados)
        .subscribe(response => {
          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          this.homeValidacao.buscarTestamento();
    this.irParaAbaTestemunhas();
        }, err => {
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar");
        });
    } else {
      this.util.toasterShowError("Atenção", "Escolha se ira dispor de bens primeiro");
    }

  }

  irParaAbaTestemunhas(){
    this.activeTabTestamento = 'testemuntas';
  }
  irParaAbaTestamento(){
    this.activeTabTestamento = 'testamento';
  }


 async carregarTemunhas() {
    this.auth.getTestamento();
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Testemunhas";
    return await this.ajax.getAsync(url,response => {
        this.arrayTestemunhas = response.data;
      });
  }

  async addTestemunha() {
    let dados = this.testamento.montarTestemunhaJson(this.testemunha);
    if (dados != undefined) {
     let url = API + "TestamentoDigital/Testemunha";
      return await this.ajax.postAsync(url, dados, async response => {
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          await this.carregarTemunhas();
          this.testemunha = new Testemunha();
          this.homeValidacao.buscarTestamento();
          this.botaoFecharModalTestemunha.nativeElement.click();
          this.botaoFecharModalPreencherTestamento.nativeElement.click();
          this.botaoFecharModalListarBens.nativeElement.click();
        });
    }
  }

  salvarDisposicaoBens() {


  }

  salvarTestamentoDisposicaoBens() {
    let dados = this.testamento.montarTestamentoDisposicaoBensJson();
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento";
    return this.ajax.post(url, dados)
      .subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");

        this.testamento.VerificarTestamento()
          .subscribe(response => {
            //atualizo objLocal
            this.auth.setTestamento(response.testamento);
            this.auth.getTestamento();
            this.inicializar();
          }, err => {
            this.util.toasterShowError("Atenção", "Erro ao Salvar");
          });

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar");
      });
  }



  async setTipoAssinaturaTestemunha(e:any){

    var dados ={ IdTipoAssinaturaTestemunha : e.target.value};

     if(this.auth.existeTestamento()){
      let url = `${API}TestamentoDigital/${this.auth.testamentoAtivo.guid}/AlterarTipos`;
    await this.ajax.patchAsync(url, dados, async response => {

      this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");

      this.isAssinaturaDigital = !(dados.IdTipoAssinaturaTestemunha == this.IdTipoAssinaturaManualTestemunha);
      if(!this.isAssinaturaDigital){

      this.util.MensagemComCliqueOk("Você optou por obter as assinaturas de forma manual. Imprima o Testamento Particular em PDF e não se esqueça de juntá-lo na Aba Anexos.",
        () =>{
            this.irParaAbaTestamento();
        });
      }
   });

   }
}}

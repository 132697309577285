export class Cartao {
  nomeProprietario: string;
  numeroCartao: string;
  cpf: string;
  mesVencimento: number;
  anoVencimento: number;
  codigoVerificador: number;
  enderecoCobranca:EnderecoCobranca
}

export class CartaoCredito {
  idTestamento: string;
  idValorPlano: string;
  cupomDesconto: string;
  cartao: Cartao;
  quantidadeParcelas: number;
  idTipoPagamento:string;

}

export class EnderecoCobranca {
  cidade: string;
  estado: string;
  pais: string;
  rua: string;
  bairro: string;
  numero: string;
  cep: string;
  complemento:string;
  telefone:string;
  dDDTelefone:string;
  codPaisTelefone:string;
}


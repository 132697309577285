import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Mapa } from '../model/Mapa';
import { GeoLocation } from '../model/geoLocation';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  options: any;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {

    this.options = {
      "closeButton": true,
      "newestOnTop": true,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "7000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

  }

  apenasNumeros(string):string
  {
      var numsStr = string.replace(/[^0-9]/g,'');
      return numsStr;
  }

  showLoading() {
    this.spinner.show();
  }

  closeLoading() {
    this.spinner.hide();
    /*setTimeout(() => {
      this.spinner.hide();
    }, 3000);*/
  }

  toasterShowSuccess(titulo: string, mensagem: string) {
    this.toastr.success(mensagem, titulo, this.options);
  }

  toasterShowError(titulo: string, mensagem: string) {
    this.toastr.error(mensagem, titulo, this.options);
  }

  toasterShowInfo(titulo: string, mensagem: string) {
    this.toastr.info(mensagem, titulo, this.options);
  }

  toasterShowWarning(titulo: string, mensagem: string) {
    this.toastr.warning(mensagem, titulo, this.options);
  }


  Confirmacao(text:string,CliqueBotaoSim:any,CliqueBotaoNao:any=null, textoBotaoSim:string='Sim, Salvar'){
    Swal.fire({
      title: "Atenção",
      html: text,
      reverseButtons:true,
      showCancelButton: true,
      confirmButtonText: textoBotaoSim,
      cancelButtonText: 'Não, Voltar',
    }).then((result) => {
       if (result.dismiss && (result.dismiss === Swal.DismissReason.cancel || result.dismiss === Swal.DismissReason.backdrop  || result.dismiss === Swal.DismissReason.esc )) {
        if(CliqueBotaoNao)
        CliqueBotaoNao();
      }else{
        CliqueBotaoSim();
      }
    });
  }

  MensagemComCliqueOk(text:string,CliqueBotaoSim:any){
    Swal.fire({
      title: "Atenção",
      html: text,
      confirmButtonText: 'Ok',
    }).then((result) => {
            CliqueBotaoSim();
    });
  }

  SwalBasic(titulo: string, mensagem: string) {
    Swal.fire(titulo, 'Hello world!');
  }

  SwalSuccess(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'success');
  }

  SwalWarning(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'warning');
  }

  ExibirModalAtencao( mensagem: string) {
    this.SwalWarning("Atenção",mensagem);
  }

  Swaldanger(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'error');
  }

  SwalInfo(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'info');
  }

  pad(num, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }


  removeMask(value): string {
    return value.replace(/\D/g, '');
  }

  convertIoptions(array, value, label) {
    return array.map(item => {
      return { "value": item[value], "label": item[label] }
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  notImplement() {
    this.toasterShowWarning("Atenção", "Em Implementação!");
  }

  isAndroid() {
    return navigator.userAgent.match(/Android/i);
  }

  isBlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  }

  isiOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  isOpera() {
    return navigator.userAgent.match(/Opera Mini/i);
  }

  isWindows() {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  }
  isMobile() {
    return (this.isAndroid() || this.isBlackBerry() || this.isiOS() || this.isOpera() || this.isWindows());
  };

  getEnderecoUrlMapa(url): Mapa {
    //https://www.google.com.br/maps/place/alagoas,260,centro,belo horizonte,minas gerais
    let mapa = new Mapa();
    url = url.replace("https://www.google.com.br/maps/place/", "");
    let arrayEnd = url.split(',');
    mapa.rua = arrayEnd[0];
    mapa.numero = arrayEnd[1];
    mapa.bairro = arrayEnd[2];
    mapa.cidade = arrayEnd[3];
    mapa.estado = arrayEnd[4];
    return mapa;
  };

  getUrlParameter(url, sParam): string {
    var results = new RegExp('[\?&]' + sParam + '=([^&#]*)').exec(url);
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || "0";
  };

  montarUrl(tipo: string, conteudo: string, mensagem = ""): string {
    let url = "";

    switch (tipo.toLowerCase()) {
      case "link": {
        url = conteudo;
        break;
      }
      case "whatsapp": {
        conteudo = conteudo.replace(/\D+/g, '');
        url = "https://api.whatsapp.com/send?phone=+55" + conteudo + "&text=" + mensagem;
        break;
      }
      case "telegram": {
        url = "https://t.me/" + conteudo;
        break;
      }
      case "facebook": {
        url = "https://www.facebook.com/" + conteudo;
        break;
      }
      case "twitter": {
        url = "https://twitter.com/" + conteudo;
        break;
      }
      case "youtube": {
        url = "https://www.youtube.com/c/" + conteudo;
        break;
      }
      case "linkedin": {
        url = "https://www.linkedin.com/in/" + conteudo;
        break;
      }
      case "instagram": {
        url = "https://www.instagram.com/" + conteudo
        break;
      }
      case "mapa": {
        url = "https://www.google.com.br/maps/place/" + conteudo
        break;
      }
      case "telefone": {
        url = "tel:" + conteudo.replace(/\D+/g, '');
        break;
      }
      case "celular": {
        url = "tel:" + conteudo.replace(/\D+/g, '');
        break;
      }
      case "site": {
        url = this.validateLink(conteudo);
        break;
      }
      default: {
        url = conteudo;
        break;
      }
    }

    return url;
  }

  formatDateMaskSave(dateString: string) {
    let date = dateString.split('/');
    var day = date[0];
    var month = date[1];
    var year = date[2];
    return year + '-' + month + '-' + day;
  }

  formatDateString(dateString: string) {
    let date = new Date(dateString);

    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    var hour = date.getHours().toString();
    var minute = date.getMinutes().toString();
    var second = date.getSeconds().toString();

    if (hour.length == 1)
      hour = '0' + hour;

    if (minute.length == 1)
      minute = '0' + minute;

    if (second.length == 1)
      second = '0' + second;

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;

  }

  convertEstadoCivil(nome: any): string {
    switch (nome) {
      case 'Solteiro':
        return '1';
      case 'Casado':
        return '2';
      case 'Divorciado':
        return '3';
    }
  }

  convertDisposicaoBens(nome: any): boolean {
    switch (nome) {
      case 'Com divisão de Bens':
        return true;
      case 'Sem divisão de Bens':
        return false;
    }
  }

  formatDateStringOnlyDate(dateString: string) {
    let date = new Date(dateString);

    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    var hour = date.getHours().toString();
    var minute = date.getMinutes().toString();
    var second = date.getSeconds().toString();

    if (hour.length == 1)
      hour = '0' + hour;

    if (minute.length == 1)
      minute = '0' + minute;

    if (second.length == 1)
      second = '0' + second;

    return day + '/' + month + '/' + year;

  }

  messageError(err) {

    switch (err.status) {
      case 400: {
        this.toasterShowError("Atenção", err.error[0]);
        break;
      }
      case 400: {
        this.toasterShowError("Atenção", "Não Autorizado");
        break;
      }

      default: {
        this.toasterShowError("Atenção", "Erro Desconhecido");
        break;
      }
    }
  }


  buildFormData(formData, data, parentKey = null) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {

      const value = data == null ? '' : data;
      if (typeof (data) != 'object')
        formData.append(parentKey, value);
      else {
        let nomeArquivo = parentKey.replace("[ValorArquivo]", ".ValorArquivo")
        formData.append(nomeArquivo, value, value.name);
      }

    }
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  validateLink(str) {
    var tarea = str;
    if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0)
      return str;
    else
      return "https://" + str;
  }


  getLocation(): GeoLocation {
    let obj = new GeoLocation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        obj.longitude = position.coords.longitude;
        obj.latitude = position.coords.latitude;
        return obj;
      });
    } else {
      return null; //console.log("No support for geolocation")
    }

  }

  closeModal(modal){
    modal.hide();
  }

  openModal(modal) {
    modal.show();
  }

  /* toasterShowError(titulo: string, mensagem: string) {
     this.toastr.error(mensagem, titulo);
   }*/


}

import { Component, OnInit,ViewChild,ElementRef  } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { TestamentoList, ClienteEscritorioList, AdvogadoEscritorioList } from '../../model/TestamentoList';
import { API, GetStatusTestamento, GetTiposUsuarioEmpresa, maskCPF, maskData, maskTelefoneCelular,REDIRECT_SITE, TIPO_USUARIO_EMPRESA } from 'src/environments/environment';
import { AjaxService } from 'src/app/service/ajax.service';
import Swal from 'sweetalert2';
import { MENSAGEM } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TestamentoEdicao } from '../../model/TestamentoEdicao';
import { AuthService } from 'src/app/service/auth.service';
import { animate, style, transition, trigger, state } from '@angular/animations';
import * as moment from 'moment';
import { jwtDecode,  ResponseUser, User } from 'src/app/model/User.model';
import { Guid } from 'guid-typescript';
import { RelatoriosGerenciaisComponent } from '../relatorios-gerenciais/relatorios-gerenciais.component';

@Component({
  selector: 'app-gerenciar-testamento',
  templateUrl: './gerenciar-testamento.component.html',
  styleUrls: ['./gerenciar-testamento.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ transform: 'translateY(0)', opacity: '0' }),
        animate('0.3s 50ms ease-in')
      ]),
    ])
  ]
})


export class GerenciarTestamentoComponent implements OnInit {

  /*
     Dados do novo adv
  */
     nomeAdvogado: string;
     emailAdvogado: string;
     cpfAdvogado: string;
     telefoneAdvogado: string;
     senhaAdvogado: string;
     numeroOABAdvogado:String;
     funcaoAdvogado:String = TIPO_USUARIO_EMPRESA.Advogado;
     confirmacaoSenhaAdvogado: string;
     dataNascimentoAdvogado: string;
     maskTeleArea = maskTelefoneCelular;
     maskCpf = maskCPF;
     maskData = maskData;
     perfilAdvogadoId:string = 'acf963a1-cbc8-4e70-8a75-248a0a221dc6';
     toDisplayModalPagAntecipado:true;
  /**********************/

    /*
     Dados do novo cliente
  */
     nomeCliente: string;
     emailCliente: string;
     cpfCliente: string;
     telefoneCliente: string;
     senhaCliente: string;
     confirmacaoSenhaCliente: string;
     dataNascimentoCliente: string;
  /**********************/

  @ViewChild('btnExibirModal')
  btnExibirModal: ElementRef;

  @ViewChild('btnFecharModal')
  btnFecharModal: ElementRef;

  @ViewChild('btnExibirModalInsercaoChave')
  btnExibirModalInsercaoChave: ElementRef;

  @ViewChild('btnExibirModalAddUsuario')
  btnExibirModalAddUsuario: ElementRef;

  @ViewChild('btnExibirModalEditarDadosUsuario')
  btnExibirModalEditarDadosUsuario: ElementRef;

  @ViewChild('botaoFecharModalAddUsuario')
  botaoFecharModalAddUsuario: ElementRef;

  @ViewChild('botaoFecharModalAddCliente')
  botaoFecharModalAddCliente: ElementRef;

  @ViewChild
  (RelatoriosGerenciaisComponent) relatoriosGerenciaisComponent;

  jwt_decode: jwtDecode;
  user : ResponseUser = new ResponseUser();
  public testamentoIndex = null;
  public exibirCorpoEmail: boolean = false;
  public corpoEmail = '';
  public isTestadorClienteAdvogado:boolean = false;
  public senhaDesativacaoConta: string;
  private testamentoEdicao: TestamentoEdicao;
  public abas = { perfil: false, testamento: true, gerusuarios: false, acompanhamento: false, relatorios: false, gerclientes:false };
  public arrayTestamentos: TestamentoList[];
  public arrayClientes : ClienteEscritorioList[];
  public arrayAdvogados : AdvogadoEscritorioList[];
  public arrayStatusTestamento : any[];
  public arrayFuncaoUsuario:any[];
  public testamento: any;
  public testamentoEnAndamento: any;
  public chave: any;
  public exibirReenvioEmail: boolean = false;
  public isAdvogadoUtilizando: boolean = false;
  public isClienteAcessoTestadorDireto: boolean = false;
  public isAdministradorEmpresa: boolean = false;
  public mensagem: string;
  public controlaDiv: any;
  public classeMaximizar: string;
  public idTestamentoEdicao: string;
  public idClienteEdicao: string;
  public chaveDeAcessoEdicao: string;
  public logEmails: any;
  public statusTestamentoFiltroSelecionado:string = '';
  public advogadoFiltroSelecionado:string = '';
  public periodoDeFiltroDashboard:string = ''
  public periodoAteFiltroDashboard:string = ''

  public estaPago:boolean;
  constructor(public util: UtilService, private ajax: AjaxService, private router: Router, private auth: AuthService) {

    this.user.user = new User();
    this.user.token = "";
  }

  async ngOnInit() {
    this.testamentoEdicao = new TestamentoEdicao();
    this.testamento = null;
    this.testamentoEnAndamento = null;
    this.chave = { chave: '' };
    this.mensagem = '';
    this.selectAba('testamento');
    this.buscarTestamentosUsuario();
    this.controlaDivs('esconderTodas');
    this.classeMaximizar = "";
    this.exibirReenvioEmail = false;
    this.senhaDesativacaoConta = '';
    this.limparDadosAdvogado();
    this.limparDadosCliente();
    let usuarioLogado = this.auth.getUser().user;
    this.isAdvogadoUtilizando = usuarioLogado.isAdvogado || usuarioLogado.isAdministradorEmpresa
    this.isClienteAcessoTestadorDireto = usuarioLogado.isClienteAcessoTestadorDireto;
    this.isAdministradorEmpresa = usuarioLogado.isAdministradorEmpresa;
    this.listarStatusTestamento();

    if(this.isAdministradorEmpresa ||  this.isAdvogadoUtilizando){
      this.resetarCliente();
      this.listarAdvogados();
      this.listarFuncaoUsuario();

      setTimeout(async () =>{
      await this.verificarSePlanoEstaPago();
      },500);
     }
  }

async verificarSePlanoEstaPago(){
  debugger;
    var estaPago = await this.verificarSeEstaPago();
    this.estaPago = estaPago;
    if(!estaPago)
    this.router.navigate(['/pagamento'],
      { queryParams: { construir: 'false' } }
    );

}

async verificarSeEstaPago(){

  let estaPago = false;
  let url = API + "Financeiro/Pagamento/Testamento/" + this.auth.getUser().user.idUsuarioAdministradorEmpresa + "/EstaPagoUsuarioEmpresa";
  await this.ajax.getAsync(url,  response => {
    estaPago = response.pago;
  });
  return estaPago;
}

  limparDadosAdvogado() {
    this.nomeAdvogado = "";
    this.emailAdvogado = "";
    this.senhaAdvogado = "";
    this.cpfAdvogado = "";
    this.telefoneAdvogado = "";
    this.dataNascimentoAdvogado = "";
    this.confirmacaoSenhaAdvogado = "";
    this.numeroOABAdvogado = '';
  }

  limparDadosCliente(){
    this.nomeCliente="";
    this.emailCliente="";
    this.cpfCliente="";
    this.telefoneCliente="";
    this.senhaCliente="";
    this.confirmacaoSenhaCliente="";
    this.dataNascimentoCliente="";
  }




  controlaDivs(plano: string) {
    switch (plano) {
      case 'plano':
        this.controlaDiv = { plano: true, cotacao: false, pagamento: false, download: false };
        break;
      case 'cotacao':
        this.controlaDiv = { plano: false, cotacao: true, pagamento: false, download: false };
        break;
      case 'pagamento':
        this.controlaDiv = { plano: false, cotacao: false, pagamento: true, download: false };
        break;
      case 'download':
        this.controlaDiv = { plano: false, cotacao: false, pagamento: false, download: true };
        break;
      case 'esconderTodas':
        this.controlaDiv = { plano: false, cotacao: false, pagamento: false, download: false };
        break;
    }
  }

 async selectAba(selecionar: string) {
  debugger;
    switch (selecionar) {
      case 'perfil':
        await this.resetarCliente();
        this.abas = { perfil: true, testamento: false, gerusuarios: false, acompanhamento: false, relatorios: false , gerclientes:false };
        break;
      case 'testamento':
        this.abas = { perfil: false, testamento: true, gerusuarios: false, acompanhamento: false, relatorios: false, gerclientes:false  };
        break;
      case 'gerusuarios':
        this.abas = { perfil: false, testamento: false, gerusuarios: true, acompanhamento: false, relatorios: false, gerclientes:false };
        await this.resetarCliente();
        this.listarAdvogados();
        break;
      case 'gerclientes':
          this.abas = { perfil: false, testamento: false, gerusuarios: false, acompanhamento: false, relatorios: false, gerclientes:true };
          await this.resetarCliente();
          this.listarClientes();
          break;
      case 'acompanhamento':
        this.abas = { perfil: false, testamento: false, gerusuarios: false, acompanhamento: true, relatorios: false, gerclientes:false  };
        await this.resetarCliente();
        break;
      case 'relatorios':
        this.abas = { perfil: false, testamento: false, gerusuarios: false, acompanhamento: false, relatorios: true , gerclientes:false };
        await this.resetarCliente();
        break;
    }

  }

  exibirLogEnvioEmails(idTestamento: any) {
    const url = `${API}TestamentoDigital/LogEmail/${idTestamento}`;
    this.ajax.getAsync(url, response => {
      if (response && response.logs) {
        this.logEmails = response.logs;
        this.idTestamentoEdicao = idTestamento;
        this.exibirReenvioEmail = true;
      }
    });
  }

  exibirConteudoEmail(conteudo: string) {
    this.exibirCorpoEmail = true;
    this.corpoEmail = conteudo;
  };

  fecharConteudoEmail() {
    this.exibirCorpoEmail = false;
    this.corpoEmail = '';
  };


  fecharModalReenvioEmail() {
    this.idTestamentoEdicao = '';
    this.exibirReenvioEmail = false;
  }

  reenviarEmail(id: any) {
    const url = `${API}TestamentoDigital/LogEmail/${this.idTestamentoEdicao}/${id}`;
    this.ajax.postAsync(url, {}, response => {
      this.util.toasterShowSuccess("Atenção", "E-mail reenviado com sucesso!");

    });

  }

  adicionarUsuarioAdvogado(){
    if(this.validarDadosAdvogado())
      this.salvarAdvogado();
  }

  validarDadosAdvogado(): boolean {

    if (this.nomeAdvogado == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

     if (this.util.removeMask(this.cpfAdvogado) == "") {
        this.util.toasterShowError("Atenção", "Preencha o campo CPF");
        return false;
      }

    if (this.dataNascimentoAdvogado == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Data Nascimento");
      return false;
    }

    if (this.emailAdvogado == "") {
      this.util.toasterShowError("Atenção", "Preencha o Email.");
      return false;
    }

    if (this.util.removeMask(this.telefoneAdvogado) == "") {
      this.util.toasterShowError("Atenção", "Preencha o Telefone.");
      return false;
    }

    if (this.senhaAdvogado == "" || this.confirmacaoSenhaAdvogado == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Senha e Confirmação de Senha.");
      return false;
    }

    if (this.senhaAdvogado != this.confirmacaoSenhaAdvogado) {
      this.util.toasterShowError("Atenção", "Os campos Senha e Confirmação não são iguais.");
      return false;
    }

    if (!moment(this.dataNascimentoAdvogado, 'DD/MM/YYYY', true).isValid()) {
      this.util.toasterShowError("Atenção", "Erro na data de nascimento, data inválida!");
      return false;
    }

    return true;
  }

  async salvarAdvogado() {

    if(!this.funcaoAdvogado)
    {
      Swal.fire("Erro", `Favor selecionar uma função para o advogado`);
    }

    let dados = {
      "nome": this.nomeAdvogado,
      "email": this.emailAdvogado,
      "login": this.emailAdvogado,
      "senha": this.senhaAdvogado,
      "dataNascimento": this.util.formatDateMaskSave(this.dataNascimentoAdvogado),
      "telefone": this.util.removeMask(this.telefoneAdvogado),
      "numeroOAB" : this.numeroOABAdvogado,
      'cpfCnpj' :   this.util.removeMask(this.cpfAdvogado),
      'idTipoLogin':  this.perfilAdvogadoId,
      'idUsuarioAdministradorEmpresa' : this.auth.getUser().user.idUsuarioAdministradorEmpresa,
      'idTipoUsuarioEmpresa' : this.funcaoAdvogado
    };

    let url = API + "Auth/User";
    this.util.showLoading();

    await this.ajax.postAsync(url, dados, (resp) => {
      Swal.fire("Sucesso", `O cadastro foi realizado com sucesso`);
      this.botaoFecharModalAddUsuario.nativeElement.click();
      this.limparDadosAdvogado();
      this.listarAdvogados();
    }, errorMessage => {

      let message = '';

      if (errorMessage && errorMessage.length)
        for (var i = 0; i < errorMessage.length; i++)
          message += errorMessage[i];
      else
        message += errorMessage;

      Swal.fire('Atenção', message);
    });
  }


  adicionarUsuarioCliente(){
    if(this.validarDadosCliente())
      this.salvarCliente();
  }

  validarDadosCliente(): boolean {

    if (this.nomeCliente == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

      if (this.util.removeMask(this.cpfCliente) == "") {
        this.util.toasterShowError("Atenção", "Preencha o campo CPF");
        return false;
      }

    if (this.dataNascimentoCliente == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Data Nascimento");
      return false;
    }

    if (this.emailCliente == "") {
      this.util.toasterShowError("Atenção", "Preencha o Email.");
      return false;
    }

    if (this.util.removeMask(this.telefoneCliente) == "") {
      this.util.toasterShowError("Atenção", "Preencha o Telefone.");
      return false;
    }

      if (!moment(this.dataNascimentoCliente, 'DD/MM/YYYY', true).isValid()) {
      this.util.toasterShowError("Atenção", "Erro na data de nascimento, data inválida!");
      return false;
    }

    return true;
  }

    async salvarCliente() {
      let senha = Guid.create();

      let dados = {
      "nome": this.nomeCliente,
      "email": this.emailCliente,
      "login": this.emailCliente,
      "senha": senha.toString().substring(0,18),
      "dataNascimento": this.util.formatDateMaskSave(this.dataNascimentoCliente),
      "telefone": this.util.removeMask(this.telefoneCliente),
      "cpfCnpj" : this.util.removeMask(this.cpfCliente),
      "idUsuarioEmpresa" :  this.auth.getUser().user.idUsuarioEmpresa
    };

    let url = API + "Auth/User";
    this.util.showLoading();
    await this.ajax.postAsync(url, dados, (resp) => {
      Swal.fire("Sucesso", `O cadastro foi realizado com sucesso!`);
      this.botaoFecharModalAddCliente.nativeElement.click();
      this.limparDadosCliente();
      this.listarClientes();
    }, errorMessage => {
      let message = '';

      if (errorMessage && errorMessage.length)
        for (var i = 0; i < errorMessage.length; i++)
          message += errorMessage[i];
      else
        message += errorMessage;

      Swal.fire('Atenção', message);
    });
  }

  async buscarDadosDashbord(){
    await this.relatoriosGerenciaisComponent.buscarDadosDashbord();
  }

  openModal(modal, guidTestamento) {



    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + guidTestamento;
    this.ajax.get(url)
      .subscribe(response => {
        this.testamento = response.testamento;
        this.util.openModal(modal);
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  maximizar() {
    if (this.classeMaximizar)
      this.classeMaximizar = "";
    else
      this.classeMaximizar = "maximizarDivPrincipal col-md-12";
  }

async filtroChange(){
  debugger;
  await this.buscarTestamentosUsuario();
}

async buscarTestamentosUsuario(): Promise<void> {

    let usuarioLogado = this.auth.getUser().user;
    this.arrayTestamentos = [];

if (usuarioLogado.isAdvogado || usuarioLogado.isAdministradorEmpresa)
{
var dados= {
   IdTipoStatus:this.statusTestamentoFiltroSelecionado ? this.statusTestamentoFiltroSelecionado : null,
   IdAdvogadoTestador : this.advogadoFiltroSelecionado ? this.advogadoFiltroSelecionado : null
};

  const url = `${API}TestamentoDigital/TestamentoEmpresa/${usuarioLogado.idUsuarioAdministradorEmpresa}`;
  this.ajax.postAsync(url, dados,response => {
    if (response && response.testamentos) {
      this.arrayTestamentos = response.testamentos;
    }
  });
}
else{
  const url = `${API}TestamentoDigital/Testamento`;
  this.ajax.getAsync(url, response => {
    if (response && response.testamentos) {
      this.arrayTestamentos = response.testamentos;
    }
  });
}


  }

  async desativarConta() {
    const url = `${API}Auth/User/Desativar`;
    let data = {
      "senha": this.senhaDesativacaoConta
    };

    this.ajax.patchAsync(url, data, response => {
      this.auth.clearUser();
      setTimeout(() => {
        this.router.navigate(['/login']);
        this.util.toasterShowSuccess("Atenção", "Conta DESATIVADA com sucesso!");
      }, 500);
    });
  }

  async salvarTestamentoDisposicaoBens(): Promise<any> {
    let url = `${API}TestamentoDigital/Testamento/CriarComDisposicaoDeBens`;
    return await this.ajax.getAsync(url, (response) => {

      this.router.navigate(['/home']);
    }, () => {
      this.router.navigate(['/gerenciar-testamento']);
    });
  }

  semAcao() {

  }

  async novoTestamento(): Promise<any> {

    if(this.isAdvogadoUtilizando || this.isAdministradorEmpresa)
      {
        this.util.MensagemComCliqueOk("Para iniciar um novo testamento ou dar continuidade a um testamento existente, selecione novo testamento na tela de adição e edição de clientes, ou, conclua o testamento de seu cliente clicando no botão CONCLUIR, do testamento disponível na lista abaixo!",() =>{
          this.selectAba('gerclientes');
        });
      }else{
        return await this.salvarTestamentoDisposicaoBens();
     }
  }

  async cancelarTestamento(idTestamento: string) {
    this.util.Confirmacao('Tem certeza que deseja EXCLUIR este TESTAMENTO DIGITAL?', async () => {
      let url = `${API}TestamentoDigital/RevogarTestamento/${idTestamento}`;
      await this.ajax?.deleteAsync(url, async response => {
        await this.buscarTestamentosUsuario();
      });
    });
  }

  async editarTestamento() {
    debugger;
    if (!this.chaveDeAcessoEdicao) {
      this.util.toasterShowError("Atenção", "Favor informar a CHAVE DE ACESSO!");
      return false;
    }

    this.chaveDeAcessoEdicao = this.chaveDeAcessoEdicao.toLocaleLowerCase();
    let url = `${API}TestamentoDigital/Testamento/${this.idTestamentoEdicao}/${this.chaveDeAcessoEdicao}/${this.idClienteEdicao}`;
    await this.ajax?.getAsync(url, response => {
      response.testamento.chave = this.chaveDeAcessoEdicao;
      if(!response && response.testamento)
        {
          this.util.toasterShowError("Atenção", "Testamento não localizado com esta chave");
        }else{
      this.testamentoEdicao.addTestamentoEdicao(response.testamento);
      this.selecionarCliente(response.testamento.idCliente);
      this.router.navigate(['/home']);
        }
    });
  }

  async buscarTestamento() {

    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        if (response && response.testamento) {
          this.testamentoEnAndamento = response.testamento;
        }
      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  async finalizarTestamento(guidTestamento: string): Promise<void> {
    var that = this;

    let urlGetTestamento = API + `TestamentoDigital/Testamento/${guidTestamento}`;
    await this.ajax.getAsync(urlGetTestamento, async response => {
      if (response && response.testamento) {
        let testamentoRetorno = response.testamento;
        this.util.showLoading();
        this.util.showLoading();
        let url = API + `TestamentoDigital/Testamento/${guidTestamento}/Construir`;

        await this.ajax.postAsync(url, null, response => {

          let chave = response.dados;
          that.chave = chave;
          that.controlaDivs('download');

          if (chave.seraConstruidoDepoisDasAssinaturasNoClickSign) {

            if (!testamentoRetorno.isTestamentoComBens || !testamentoRetorno.isAssinaturaDigital) {
              that.mensagem = MENSAGEM.construcaoExecutoresAceitarTermo;
            } else {
              that.mensagem = MENSAGEM.construcaoTestemunhasAssinarem;
            }
          }

          this.classeMaximizar = "";
          that.btnExibirModal.nativeElement.click();
          that.buscarTestamentosUsuario();
        });
      }
    });
  }

  async pagarTestamento(guidTestamento: string): Promise<void> {

    alert(guidTestamento);
  }

  irParaGerenciarTestamentos() {
    this.controlaDivs('esconderTodas');
    this.chave = { chave: '' };
    this.mensagem = '';
    this.btnFecharModal.nativeElement.click();
  }

  abrirModalInsercaoChave(idTestamentoEdicao: string, idClienteEdicao:string) {
    this.idTestamentoEdicao = idTestamentoEdicao;
    this.btnExibirModalInsercaoChave.nativeElement.click();
    this.idClienteEdicao  = idClienteEdicao;
  }


listarAdvogados(){
  let idUsuarioAdministradorEmpresa= this.auth.getUser().user.idUsuarioAdministradorEmpresa;
  const url = `${API}Auth/AdvogadoEmpresa/${idUsuarioAdministradorEmpresa}`;

    this.ajax.getAsync(url,response => {
      this.arrayAdvogados=[];
      this.arrayAdvogados = response.data;
  });
}

listarStatusTestamento(){
  this.arrayStatusTestamento = GetStatusTestamento();
}

listarFuncaoUsuario(){
  this.arrayFuncaoUsuario = GetTiposUsuarioEmpresa();
}

listarClientes(){

  let idUsuarioAdministradorEmpresa= this.auth.getUser().user.idUsuarioAdministradorEmpresa;
  const url = `${API}Auth/ClienteEmpresa/${idUsuarioAdministradorEmpresa}`;

    this.ajax.getAsync(url,response => {
      this.arrayClientes=[];
      this.arrayClientes = response.data;
  });
}


async selecionarCliente(idCliente:string){
//ToDO - IdTestamento
    let url = API + 'Auth/User/SetCliente';
    let dados= {'idClienteSimular':idCliente
              ,'idUsuario':this.auth.getUser().user.id};

    await this.ajax.postAsync(url,dados,response =>{
      this.jwt_decode = this.util.getDecodedAccessToken(response.token);
      this.user.user.nome = response.user.nomeSimulado;
      this.user.user.idImutavel = response.user.idImutavel;
      this.user.user.nomeSimular = response.user.nomeSimulado;
      this.user.user.idCliente = response.user.idCliente;
      this.user.user.id = response.user.usuarioCliente.id;
      this.user.user.email = response.user.usuarioCliente.email;
      this.user.user.telefone = response.user.usuarioCliente.telefone;
      this.user.user.dataNascimento = response.user.usuarioCliente.dataNascimento;
      this.user.user.cpf = response.user.usuarioCliente.cpf;
      this.user.user.isAdvogado = response.user.isAdvogado;
      this.user.user.isClienteAcessoTestadorDireto = response.user.isClienteAcessoTestadorDireto;
      this.user.user.isAdministradorEmpresa = response.user.isAdministradorEmpresa;
      this.user.user.idUsuarioEmpresa = response.user.idUsuarioEmpresa;
      this.user.user.idUsuarioAdministradorEmpresa = response.user.idUsuarioAdministradorEmpresa;
      this.user.user.isTestador = response.user.isTestador;
      this.user.user.isTestadorClienteAdvogado = response.user.isTestadorClienteAdvogado;
      this.isTestadorClienteAdvogado = response.user.isTestadorClienteAdvogado;
      this.user.token = response.token;
      this.user.user.urlDiretaAcessoCliente = response.user.urlAcessoDiretoCliente;
      this.auth.setUser(this.user);
      this.router.navigate(['/home']);
    });
}

//tOdO IsTestadorClienteAdvogado
async resetarCliente(){
  debugger;
      let userComparar = this?.auth?.getUser()?.user;
      if(!userComparar || (userComparar.idImutavel == userComparar.id))
      {
        return false;
      }
      else{

      let url = API + 'Auth/User/ResetarCliente';
      let dados= {'idUsuario':this.auth.getUser().user.idImutavel};
        await this.ajax.postAsync(url,dados,response =>{
        this.jwt_decode = this.util.getDecodedAccessToken(response.token);
        this.user.user.nome = response.user.nome;
        this.user.user.idCliente = response.user.idCliente;
        this.user.user.id = response.user.id;
        this.user.user.idImutavel = response.user.idImutavel;
        this.user.user.email = response.user.email;
        this.user.user.telefone = response.user.telefone;
        this.user.user.dataNascimento = response.user.dataNascimento;
        this.user.user.cpf = response.user.cpf;
        this.user.user.isAdvogado = response.user.isAdvogado;
        this.user.user.isClienteAcessoTestadorDireto = response.user.isClienteAcessoTestadorDireto;
        this.user.user.isAdministradorEmpresa = response.user.isAdministradorEmpresa;
        this.user.user.idUsuarioEmpresa = response.user.idUsuarioEmpresa;
        this.user.user.idUsuarioAdministradorEmpresa = response.user.idUsuarioAdministradorEmpresa;
        this.user.user.isTestador = response.user.isTestador;
        this.user.user.isTestadorClienteAdvogado = response.user.isTestadorClienteAdvogado;
        this.user.token = response.token;
        this.user.originalUser = this.user.user;
        this.auth.setUser(this.user);
      });
  }
}
}

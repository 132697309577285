

<div *ngIf="controle.login">
    <app-aceite-login (acaoProximo)="irAceiteentrar($event)"></app-aceite-login>
</div>

<div *ngIf="controle.aceite">
   <app-aceite></app-aceite>

</div>


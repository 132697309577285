import { Component, EventEmitter, Input, OnInit, ViewChild, ElementRef, Output } from '@angular/core';
import { Anexos, LinhaAnexo } from 'src/app/model/Anexos.model';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { AzureComponent } from 'src/app/service/azure.component';
import { UtilService } from 'src/app/service/util.service';
import { API, MENSAGEM, TIPO_ARQUIVO } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TestamentoEdicao } from '../../model/TestamentoEdicao';

@Component({
  selector: 'app-tab-anexos',
  templateUrl: './tab-anexos.component.html',
  styleUrls: ['./tab-anexos.component.scss']
})
export class TabAnexosComponent implements OnInit {

  @Input() homeValidacao: ValidacaoModel;

  @ViewChild('botaoFecharModalEnvioEmMassa')
  botaoFecharModalEnvioEmMassa: ElementRef;

  @Output()
  onChangeAba = new EventEmitter<string>();

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  private testamentoEdicao :TestamentoEdicao;
  public haveraDisposicaoDeBens:boolean;
  public activeTabAnexos: string;
  public idTipoArquivoSalvar:string = "eff963a1-cbc8-4e70-8a75-248a0a221dc5";
  public anexos: Anexos;
  public arrayDoc: any[];
  public arrayFotos: any[];
  public arrayAudios: any[];
  public arrayVideos: any[];
  public arrayOutro: any[];
  public arrayTestamento: any[];
  public linhaAnexoDoc: LinhaAnexo;
  public linhaAnexoFoto: LinhaAnexo;
  public linhaAnexoAudio: LinhaAnexo;
  public linhaAnexoVideo: LinhaAnexo;
  public linhaAnexoOutro: LinhaAnexo;
  public linhaAnexoTestamento: LinhaAnexo;
  public tipoAnexo = TIPO_ARQUIVO;
  public arrayTipoArquivo = [];
  public exibirAbaTestamento:boolean =false;
  private tamanhoMaximoUploadDireto = 15000000;
  public arraySenhasCount:number = 0;
  public isAdvogadoUtilizando: boolean = false;
  public isTestadorClienteAdvogado: boolean = false;

  constructor(
    public util: UtilService,
    private auth: AuthService,
    private ajax: AjaxService,
    private azureBlob:AzureComponent
  ) {
   this.testamentoEdicao = new TestamentoEdicao();
    // this.activeTabAnexos = 'documentos';
    this.activeTabAnexos = 'anexos';
    this.homeValidacao = new ValidacaoModel(ajax, util, auth);
    this.limpaInputs();
    this.limparArrays();
    this.buscarTestamento();
    let usuarioLogado = this.auth.getUser().user;
    this.isAdvogadoUtilizando = usuarioLogado.isAdvogado || usuarioLogado.isAdministradorEmpresa;
    this.isTestadorClienteAdvogado = usuarioLogado.isTestadorClienteAdvogado;
  }

  exibirBoxUpload: any = true;
  toggleBoxUpload() {
    this.exibirBoxUpload = !this.exibirBoxUpload;
  }

  ngOnInit() {

this.preencherArrayArquivo();

    if (!this.auth.existeTestamento()) {
      this.util.SwalWarning("Atenção", "É necessário ir na aba Testamento e verificar e assinalar se quer dispor de bens");
    }
  }

  preencherArrayArquivo(){
    this.arrayTipoArquivo = [];
    for(var p in TIPO_ARQUIVO){
      this.arrayTipoArquivo.push({value:TIPO_ARQUIVO[p],name:p});
    }
  }

  async deletarArquivo(id:string, idTestamento:string):Promise<void>{


this.util.Confirmacao("Tem certeza que deseja remover o registro?",async ()=>{

  await this.deletarArquivoSemConfirmacao(id,idTestamento,true);

});
  }


  async deletarArquivoSemConfirmacao(id:string, idTestamento:string, dispararAletra:boolean):Promise<void>{

    var url = `${API}TestamentoDigital/Arquivo/${id}/${idTestamento}`;
    this.ajax.deleteAsync(url,async r=>{

      if(dispararAletra)
        this.util.toasterShowSuccess("Atenção", "Deletado com Sucesso");
      this.carregarDoc();});
 }

 baixarArquivo(idArquivo,idTestamento){
  let url = '';
  if(this.testamentoEdicao.Testamento){
    url = `${API}TestamentoDigital/Testamento/${idTestamento}/DownloadArquivo/${idArquivo}/${this.testamentoEdicao.Testamento.chave}`;
  }
  else{
   url = `${API}TestamentoDigital/Testamento/${idTestamento}/DownloadArquivo/${idArquivo}`;
  }
  location.href = url;
 }

  buscarTestamento() {

   if(this.testamentoEdicao.buscarTestamentoEmEdicao() ){
    this.inicializarTestamento(this.testamentoEdicao.Testamento);
    return true;
   }

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();
        if (response.testamento != null) {
          this.inicializarTestamento(response.testamento);
        }else{

        }

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  // irParaDocumentos(){
  //   this.activeTabAnexos='anexos';
  // }

  irParaFotos(){
    this.activeTabAnexos='fotos';
  }

  irParaAudios(){
    this.activeTabAnexos='audios';
  }

  irParaVideos(){
    this.activeTabAnexos='videos';
  }

  irParaOutros(){
    this.activeTabAnexos='livre';
  }

  irParaSenhasTab(){
    this.activeTabAnexos='senhasTab';
  }

  selectAbaVindoAnexos(quantidade:number){
    this.arraySenhasCount = quantidade;
  }
  // irParaAbaSenhas(){
  //   this.onChangeAba.emit('senhas');
  // }

  changeAbaLeitura(to:string){
    this.onChangeAba.emit('leitura');
  }

inicializarTestamento(testamento:any){
      this.auth.setTestamento(testamento);
      this.auth.getTestamento();
      this.inicializar();

      this.exibirAbaTestamento = !testamento.isAssinaturaDigital && testamento.isTestamentoParticularComBens;

      if(this.exibirAbaTestamento)
        this.activeTabAnexos='anexos';
}


  inicializar() {
    this.auth.existeTestamento()
    this.anexos = new Anexos([], [], [], [], [], this.auth.testamentoAtivo.guid, this.util);
    this.carregarDoc();
  }

  limpaInputs(){
    this.linhaAnexoDoc = new LinhaAnexo();
    this.linhaAnexoFoto = new LinhaAnexo();
    this.linhaAnexoAudio = new LinhaAnexo();
    this.linhaAnexoVideo = new LinhaAnexo();
    this.linhaAnexoOutro = new LinhaAnexo();
    this.linhaAnexoTestamento = new LinhaAnexo();
  }

  limparArrays() {
    this.arrayDoc = [];
    this.arrayAudios = [];
    this.arrayFotos = [];
    this.arrayVideos = [];
    this.arrayOutro = [];
    this.arrayTestamento = [];
  }

  preencheArrays(arrayGeral: any[]) {
    this.limparArrays();
    arrayGeral.forEach(item => {

      this.arrayOutro.push(item);
    /*  switch (item.idTipoArquivo.toLowerCase()) {
        case this.tipoAnexo.Testamento.toLocaleLowerCase():
          this.arrayTestamento.push(item);
          break;
        case this.tipoAnexo.Documento.toLocaleLowerCase():
          this.arrayDoc.push(item);
          break;
        case this.tipoAnexo.Foto.toLocaleLowerCase():
          this.arrayFotos.push(item);
          break;
        case this.tipoAnexo.Video.toLocaleLowerCase():
          this.arrayVideos.push(item);
          break;
        case this.tipoAnexo.Audio.toLocaleLowerCase():
          this.arrayAudios.push(item);
          break;
        case this.tipoAnexo.Outro.toLocaleLowerCase():
          this.arrayOutro.push(item);
          break;
      }*/
    });

  }

  carregarDoc() {
    this.auth.getTestamento();
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Arquivos";
    return this.ajax.get(url)
      .subscribe(response => {
        this.preencheArrays(response.data);

        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar");
      });
  }

  async salvarArquivo(guidTipoArquivo: string, linhaAnexo: LinhaAnexo, modal) : Promise<void>{
    linhaAnexo.IdTipoArquivo = linhaAnexo.IdTipoArquivo ? linhaAnexo.IdTipoArquivo : this.idTipoArquivoSalvar;
    let dados = this.anexos.montarJson(linhaAnexo);

    if (dados != undefined) {
      if(linhaAnexo.Multiplo)
        dados.Arquivo =  linhaAnexo.Arquivo;
      else
        this.util.showLoading();

        linhaAnexo.Arquivo = [];
      let url = API + "TestamentoDigital/Arquivo";

      if(this.testamentoEdicao.Testamento){
        dados.Chave = this.testamentoEdicao.Testamento.chave;
      }

      if( dados.Arquivo && dados.Arquivo.size > this.tamanhoMaximoUploadDireto ){
           return await this.salvarArquivoGrandeAsync(dados,linhaAnexo.Multiplo);
        }
        else{

        return await this.ajax.postFileAsync(url, this.util.jsonToFormData(dados),
        response => {
          debugger;
          if(modal && !linhaAnexo.Multiplo){
          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
          this.homeValidacao.buscarTestamento();
          this.carregarDoc();
          this.limpaInputs();
          this.util.closeModal(modal);
          }
        });
      }
    }
  }

  private ArquivoUpload:any;
  async salvarArquivoGrandeAsync(dados:any,multiplo:boolean) : Promise<void>{
    let context = this;
debugger;
    if (dados != undefined) {
      let url = API + "TestamentoDigital/Arquivo/Grande";
      let deletado = false;

        let arquivo = dados.Arquivo;
        this.ArquivoUpload = arquivo;
        let dadosEnviar = dados;
        dadosEnviar.Arquivo = null;
        dadosEnviar.tamanho = arquivo.size;
        dadosEnviar.nome = arquivo.name;

        return await this.ajax.postAsync(url, dadosEnviar,async (response)=>{

          await context.SucessoUploadArquivoAsync(context,response,multiplo);

        }, (error,dadosResposta) =>{
          this.ajax.catchError(null,null, error);

          if(dadosResposta && !deletado){
            this.deletarArquivoSemConfirmacao(dadosResposta.id,dadosResposta.idTestamento,false);
            this.util.closeLoading();
          }
  },true);
    }
  }


   async SucessoUploadArquivoAsync(context:any, response:any,multiplo:boolean):Promise<void>{


      if(!response.path){
        context.util.toasterShowSuccess("Atenção", "Problemas ao salvar Arquivo.");
      }
      else{
        //Enviar para o azure
        let caminho = response.path;
        try{
        await context.azureBlob.upload( context.ArquivoUpload,caminho);
        }catch(e){
          throw new Error('Erro ao salvar arquivo');
        }
        if(!multiplo){
        context.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        context.carregarDoc();
        }
      }
  }

  /*Drag and Drop*/

  async onFileDropped($event) {
    await this.prepareFilesList($event,null);
  }

  /**
   * handle file from browsing
   */
  async fileBrowseHandler(files,modalMultipleFileStatus) {
    this.util.openModal(modalMultipleFileStatus);
   await this.prepareFilesList(files,modalMultipleFileStatus);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFiles(index: number,modalMultipleFileStatus) {
    setTimeout(async () => {
      if (index === this.files.length) {
        this.util.closeLoading();
        this.homeValidacao.buscarTestamento();
        this.carregarDoc();
        this.limpaInputs();
        setTimeout(() =>{
          this.fecharModalMultipleFileStatus(modalMultipleFileStatus);
        },3000);
        return;
      } else {
        debugger;
        try{
          var file = this.files[index];
          var idTipoArquivo = this.getFileType(file.name);
          let linha = new LinhaAnexo();
          linha.IdTipoArquivo = idTipoArquivo;
          linha.Arquivo = file;
          linha.Descricao = file.name;
          linha.Multiplo=true;
          await this.salvarArquivo(idTipoArquivo,linha,null)
          this.files[index].error =false ;
          this.files[index].progress = 100;
        }catch(err){
            this.files[index].error = true;
        }
        finally{
          this.uploadFiles(index+1,modalMultipleFileStatus);
        }
      }
    }, 1000);
  }

  fecharModalMultipleFileStatus(modalMultipleFileStatus){
    this.files=[];
    if(modalMultipleFileStatus)
      this.util.closeModal(modalMultipleFileStatus);
  }

  getFileType(fileName){
    const name = fileName.toLowerCase();
    if(fileName.endsWith('docx')
       || fileName.endsWith('doc')
       || fileName.endsWith('pdf')
       || fileName.endsWith('xls')
       || fileName.endsWith('xlsx')
    ){
      return TIPO_ARQUIVO.Documento;
    }
    else if(fileName.endsWith('mp4')
      || fileName.endsWith('vid')
      || fileName.endsWith('mov')
      || fileName.endsWith('wmv')
      || fileName.endsWith('avi')
      || fileName.endsWith('flv')
      || fileName.endsWith('mkv')
      || fileName.endsWith('avchd')
      || fileName.endsWith('swf')
      || fileName.endsWith('f4v'))
      {
        return TIPO_ARQUIVO.Video;
      }
      else if(fileName.endsWith('jpg')
        || fileName.endsWith('jpeg')
        || fileName.endsWith('gif')
        || fileName.endsWith('png')
        || fileName.endsWith('svg')
        || fileName.endsWith('psd')
        || fileName.endsWith('webp')
        || fileName.endsWith('raw')
        || fileName.endsWith('tiff')
        || fileName.endsWith('bmp'))
        {
          return TIPO_ARQUIVO.Foto;
        }
        else if(fileName.endsWith('mp3')
          || fileName.endsWith('wma')
          || fileName.endsWith('wav')
          || fileName.endsWith('pcm')
          || fileName.endsWith('flac'))
          {
            return TIPO_ARQUIVO.Audio;
          }
          else
         {
              return TIPO_ARQUIVO.Outro;
         }
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>,modalMultipleFileStatus) {
    for (const item of files) {
      item.progress = 0;
      item.error=null;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    await this.uploadFiles(0,modalMultipleFileStatus);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}

import { UtilService } from "../service/util.service";

export class Anexos {

  Documentos: LinhaAnexo[];
  Fotos: LinhaAnexo[];
  Audios: LinhaAnexo[];
  Videos: LinhaAnexo[];
  TextoLivre: LinhaAnexo[];
  IdTestamento: string;


  constructor(
    documentos: LinhaAnexo[],
    fotos: LinhaAnexo[],
    audios: LinhaAnexo[],
    videos: LinhaAnexo[],
    textoLivre: LinhaAnexo[],
    idTestamento: string,

    private util?: UtilService
  ) {
    this.Documentos = documentos;
    this.Fotos = fotos;
    this.Audios = audios;
    this.Videos = videos;
    this.TextoLivre = textoLivre;
    this.IdTestamento = idTestamento;
  }

  public validaAnexo(objAnexo: LinhaAnexo): boolean {
debugger;
    if (objAnexo.Descricao == "") {
      this.util.toasterShowError("Atenção", "O Campo Descricao não pode ser Vazio!");
      return false;
    }

    if (objAnexo.IdTipoArquivo == "") {
      this.util.toasterShowError("Atenção", "Tipo Arquivo Inválido!");
      return false;
    }
    /*if (objAnexo.Classificacao == "") {
      this.util.toasterShowError("Atenção", "O Campo Classificacão não pode ser Vazio!");
      return false;
    }*/
    /*if (objAnexo.Descricao == "") {
      this.util.toasterShowError("Atenção", "O Campo Descrição não pode ser Vazio!");
      return false;
    }*/
    /*if (objAnexo.Tamanho == "") {
      this.util.toasterShowError("Atenção", "O Campo Tamanho não pode ser Vazio!");
      return false;
     }*/

    if (objAnexo.Arquivo == null || objAnexo.Arquivo == undefined) {
      this.util.toasterShowError("Atenção", "Não foi enviado o arquivo!");
      return false;
    }

    return true;
  }

  public montarJson(objAnexo: LinhaAnexo) {
    if (this.validaAnexo(objAnexo)) {
      return {
        "IdTestamento": this.IdTestamento,
        "Descricao": objAnexo.Descricao,
        "Arquivo": objAnexo.Arquivo[0],
        "IdTipoArquivo": objAnexo.IdTipoArquivo,
        "Chave": objAnexo.Chave
      }
    }
  }


  public addDocumento(objAnexo: LinhaAnexo) {
    if (this.validaAnexo(objAnexo))
      this.Documentos.push(objAnexo);
  }

  public addFoto(objAnexo: LinhaAnexo) {
    if (this.validaAnexo(objAnexo))
      this.Fotos.push(objAnexo);
  }

  public addAudio(objAnexo: LinhaAnexo) {
    if (this.validaAnexo(objAnexo))
      this.Audios.push(objAnexo);
  }

  public addVideo(objAnexo: LinhaAnexo) {
    if (this.validaAnexo(objAnexo))
      this.Videos.push(objAnexo);
  }

  public addTextoLivre(objAnexo: LinhaAnexo) {
    if (this.validaAnexo(objAnexo))
      this.TextoLivre.push(objAnexo);
  }

}

export class LinhaAnexo {

  Descricao: string;
  Classificacao: string;
  Tamanho: string;
  IdTipoArquivo: string;
  Chave:string;
  Arquivo: any[];
  Multiplo:boolean=false;
  /*constructor(
    descricao: string,
    classificacao: string,
    tamanho: string,
    idTipoArquivo: string,
    arquivo: File
  ) {
    descricao = this.Descricao,
    classificacao =  this.Classificacao,
    tamanho = this.Tamanho,
    idTipoArquivo = this.IdTipoArquivo,
    arquivo = this.Arquivo
  }*/


}

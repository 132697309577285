import { UtilService } from "../service/util.service";
import * as moment from 'moment';

export class ExcecutoresBeneficiarios{

    Executor: LinhaExecutor[];
    EntregarChaveMorte: boolean;
    EntregarChaveAcidente: boolean;
    EntregarChaveDesaparecimento: boolean;
    EntregarChaveOutroMotivo: boolean;
    EntregarChaveOutroMotivoDescricao: string;
    IdTestamento: string;

    constructor(
      executor: LinhaExecutor[],
      entregarChaveMorte: boolean,
      entregarChaveAcidente: boolean,
      entregarChaveDesaparecimento: boolean,
      entregarChaveOutroMotivo: boolean,
      entregarChaveOutroMotivoDescricao: string,
      idTestamento: string,

      private util?: UtilService
    ) {
      this.Executor = executor;
      this.EntregarChaveMorte = entregarChaveMorte;
      this.EntregarChaveAcidente = entregarChaveAcidente;
      this.EntregarChaveDesaparecimento = entregarChaveDesaparecimento;
      this.EntregarChaveOutroMotivo = entregarChaveOutroMotivo;
      this.EntregarChaveOutroMotivoDescricao = entregarChaveOutroMotivoDescricao;
      this.IdTestamento = idTestamento;
    }

    public validar(objExecutor: LinhaExecutor): boolean{

      if(objExecutor.Nome == ""){
        this.util.toasterShowError("Atenção", "O Campo Nome não pode ser Vazio!");
        return false;
      }

      if(this.util.removeMask(objExecutor.CPF) == ""  || this.util.removeMask(objExecutor.CPF).length < 11){
        this.util.toasterShowError("Atenção", "O Campo CPF não pode ser Vazio ou estar incompleto!!");
        return false;
      }

      if(objExecutor.DataNascimento == undefined || this.util.removeMask(objExecutor.DataNascimento) == "" || this.util.removeMask(objExecutor.DataNascimento).length < 8){
        this.util.toasterShowError("Atenção", "O Campo Data Nascimento não pode ser Vazio ou estar incompleto!");
        return false;
      }

      if(objExecutor.Email == ""){
        this.util.toasterShowError("Atenção", "O Campo Email não pode ser Vazio!");
        return false;
      }

      if(objExecutor.Telefone == undefined || this.util.removeMask(objExecutor.Telefone) == "" || this.util.removeMask(objExecutor.Telefone).length < 11){
        this.util.toasterShowError("Atenção", "O Campo Telefone não pode ser Vazio ou estar incompleto!!");
        return false;
      }

      if(!moment(objExecutor.DataNascimento , 'DD/MM/YYYY',true).isValid()){
        this.util.toasterShowError("Atenção", "Erro na data de nascimento, data inválida!");
        return false;
      }

      return true;
    }



    public addExcecutor(objExecutor: LinhaExecutor ){
      if(this.validar(objExecutor))
        this.Executor.push(objExecutor);
    }

    public montarExecutorJson(objExecutor: LinhaExecutor) {
      if (this.validar(objExecutor)) {
        return {
          "idTestamento": this.IdTestamento,
          "pessoas": [{
            "nome": objExecutor.Nome,
            "email": objExecutor.Email,
            "telefone": this.util.removeMask(objExecutor.Telefone),
            "cpf": this.util.removeMask(objExecutor.CPF),
            "dataNascimento": this.util.formatDateMaskSave(objExecutor.DataNascimento),
          }]
        }
      }
    }

    public montarBeneficiarioJson(objBeneficiario: LinhaBeneficiario) {
      if (this.validar(objBeneficiario)) {
        return {
          "idTestamento": this.IdTestamento,
          "pessoas": [{
            "nome": objBeneficiario.Nome,
            "email": objBeneficiario.Email,
            "telefone": this.util.removeMask(objBeneficiario.Telefone),
            "cpf": this.util.removeMask(objBeneficiario.CPF),
            "dataNascimento": this.util.formatDateMaskSave(objBeneficiario.DataNascimento),
          }]
        }
      }
    }


  }

  export class LinhaExecutor{
    Nome: string;
    DataNascimento: string;
    CPF: string;
    Email: string;
    Telefone: string;
  }

  export class LinhaBeneficiario{
    Nome: string;
    DataNascimento: string;
    CPF: string;
    Email: string;
    Telefone: string;
  }

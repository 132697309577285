<div class="d-none-sm">

  <ul class="nav nav-tabs profile-tabs nav-fill" style="margin-top:-37px;">
    <li class="nav-item">
      <a class="nav-link text-reset" [ngClass]="{'myActive': this.activeTabAnexos === 'anexos'}"
        (click)="this.activeTabAnexos = 'anexos'" data-toggle="tab" href="javascript:void" role="tab">
        ARQUIVOS ({{arrayDoc.length+arrayFotos.length+arrayAudios.length+arrayVideos.length+arrayOutro.length}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-reset" [ngClass]="{'myActive': this.activeTabAnexos === 'senhasTab'}"
        (click)="this.activeTabAnexos = 'senhasTab'" data-toggle="tab" href="javascript:void" role="tab">
        SENHAS ({{arraySenhasCount}})</a>
    </li>
  </ul>

  <div class="tab-content position-relative" id="myTabContent">

    <div class="tab-pane fade show active" [ngClass]="{'show active': this.activeTabAnexos === 'anexos'}" role="tabpanel">
      <div class="row align-items-center" style="padding: 30px 0px 14px 0px; margin:0px;">

        <div class="row img-topo img-anexos d-none-sm mt-0">
          <p><span>O que devo anexar aqui?</span>
            <br /> Apólices de seguro, escrituras, registro de imóveis, contratos, seu testamento particular assinado
            fisicamente, fotos, áudios, vídeos, mensagens de despedida, orientações, etc.
          </p>
        </div>

        <div class="col-md-12 pr-0">
          <a class="float-right text-muted ">
            <button type="button" class="btn btn-icon btn-outline-info" (click)="toggleBoxUpload()">
              <i class="feather icon-plus"></i>Adicionar arquivo</button>
          </a>
        </div>
      </div>

      <!-- aqui a div de upload de arquivos -->
      <div class="col-md-12 boxUploadArquivos dropzone" *ngIf="!exibirBoxUpload"  (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files,modalMultipleFileStatus)" />
          <button type="button" class="btnFecharModal" data-dismiss="modal" aria-label="Close"
          (click)="toggleBoxUpload()"><span aria-hidden="true">&times;</span></button>


        <div class="col-md-4 img-upload-arquivos">
          <a class="linkBoxVazio" (click)="util.openModal(modalEnvioEmMassa)"> </a>
        </div>
        <div class="col-md-8 text-uppercase d-flex flex-direction-column align-items-center text-center py-3">

          <a class="text-muted" (click)="util.openModal(modalEnvioEmMassa)">
          <h5 class="corAzul fw-300 pb-2 pt-3 px-4">arraste e solte aqui seus arquivos
          </h5>
              </a>
          <a id="fileManual" class="float-right text-muted">
            <button type="button" class="btn btn-icon btn-outline-info" (click)="util.openModal(modalAnexoOutro)">
              <i class="feather icon-plus"></i>Adição Manual</button>
          </a>
        </div>

      </div>

      <!-- aqui finaliza a div de upload de arquivos -->

      <div class="table-responsive" *ngIf="exibirBoxUpload">
        <table class="table">
          <thead>
            <tr>
              <th>TÍTULO</th>
              <th>NOME</th>
              <th>EXTENSÃO</th>
              <th>TIPO</th>
              <th class="headerAcoes">Ações</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let item of arrayOutro">
              <td>{{item.descricao}}</td>
              <td [ngClass]="{'d-none': this.activeTabAnexos === 'senhasTab'}">{{item.nome}}</td>
              <td>{{item.extensao}}</td>
              <td>{{item.tipoArquivo}}</td>
              <td class="gridBtnAcoes">

                <button *ngIf="!isAdvogadoUtilizando" type="button" (click)="baixarArquivo(item.guid,item.idTestamento)"
                  class="btn btn-outline-info"><i  class="feather icon-eye"></i></button>

                <button type="button" (click)="deletarArquivo(item.guid,item.idTestamento)"
                  class="btn btn-outline-danger"><i class="feather icon-trash"></i></button>
              </td>
            </tr>

          </tbody>
        </table>

        <div class="col-md-12 mt-5">
          <button type="button" class="btn button-rounded col-md-3" style="margin: 0 auto;" (click)="irParaSenhasTab()">
            <i class="feather icon-arrow-right"></i>AVANÇAR
          </button>
        </div>
      </div>

    </div>

    <div class="tab-pane fade" [ngClass]="{'show active': this.activeTabAnexos === 'senhasTab'}" role="tabpanel">
      <div class="row align-items-center" style="padding: 30px 0px 14px 0px; margin:0px;">

      <app-tab-senhas (onChangeAbaLeitura)="changeAbaLeitura($event)"  (onChangeAbaVindoAnexos)="selectAbaVindoAnexos($event)" ></app-tab-senhas>

    </div>
    </div>


  </div>


  <div class="row img-topo img-anexos d-mobile">
    <p>Junte apólices, testamentos particulares, fotos, vídeos, etc.</p>
  </div>


</div>
<div class="d-mobile">

  <button type="button" class="col-12 btn button-rounded mobile" (click)="util.openModal(modalAnexoOutro)">
    <img class="iconMobileHome" src="/assets/images/icones-mobile/anexo.gif">
    <span>Adicionar anexo (s)</span>
  </button>


</div>


<app-ui-modal #modalEnvioEmMassa [hideFooter]="true" [dialogClass]="'modal-md'" class="modalMobile">
    <div class="app-modal-header">
        <h5 class="modal-title titulo">Envio de multiplos arquivos</h5>
        <button type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" #botaoFecharModalEnvioEmMassa
            (click)="util.closeModal(modalEnvioEmMassa)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

      <!-- cada arquivo fica aqui dentro -->
      <div class="row mb-3">

        <div class="col-md-12 mt-1 d-flex">
          <label>Arquivo <span>XX:</span>
            <span class="corAzul pl-1">
                <i class="feather icon-paperclip"></i> nome-do-arquivo.pdf
              </span>
              </label>
              </div>
              <div class="col-md-12 d-flex position-relative">
                <input type="text" class="form-control"
                placeholder="Insira uma descrição para o arquivo">
                <a class="btnExcluirItem">x</a>
              </div>
            </div>
            <!-- cada arquivo fica aqui dentro -->
      <!-- cada arquivo fica aqui dentro -->
      <div class="row mb-3">

        <div class="col-md-12 mt-1 d-flex">
          <label>Arquivo <span>XX:</span>
            <span class="corAzul pl-1">
                <i class="feather icon-paperclip"></i> nome-do-arquivo.pdf
              </span>
              </label>
              </div>
              <div class="col-md-12 d-flex position-relative">
                <input type="text" class="form-control"
                placeholder="Insira uma descrição para o anexo">
                <a class="btnExcluirItem">x</a>
              </div>
            </div>
            <!-- cada arquivo fica aqui dentro -->

            <div class="row mt-4 mb-1">

            <div class="col-md-12">
              <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;" (click)="util.closeModal(modalEnvioEmMassa)">
                <i class="feather icon-upload-cloud"></i>
                SALVAR
              </button>
            </div>

        </div>

    </div>
</app-ui-modal>


<app-ui-modal #modalAnexoDoc [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ANEXAR DOCUMENTO</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalAnexoDoc)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [(ngModel)]="linhaAnexoDoc.Descricao"
          name="linhaAnexoDoc.Descricao" placeholder="Descrição">
      </div>
      <div class="col-md-12">
        <file-upload class="fundoInput" [(ngModel)]="linhaAnexoDoc.Arquivo" [fileslimit]="1"
          accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
          simple></file-upload>
      </div>

      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
          (click)="salvarArquivo(tipoAnexo.Documento, linhaAnexoDoc, modalAnexoDoc)">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
      <div class="lineDividerH d-mobile"></div>

      <div class="col-md-12 mt-3 d-mobile justify-content-center" align="center">
        <p class="titulo mb-0">Já adicionado(s)</p>
      </div>
      <div class="col-md-12 d-flex align-items-center d-mobile" style="min-height: 31px;" *ngFor="let item of arrayDoc">
        <div class="col-9">
          {{item.descricao}}
        </div>
        <div class="col-1">
          {{item.extensao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
          <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
      </div>
      <div class="m-2 d-mobile"></div>

    </div>
  </div>
</app-ui-modal>

<app-ui-modal #modalAnexoImage [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ANEXAR IMAGEM</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalAnexoImage)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [(ngModel)]="linhaAnexoFoto.Descricao"
          name="linhaAnexoFoto.Descricao" placeholder="Descrição">
      </div>
      <div class="col-md-12">
        <file-upload class="fundoInput" [(ngModel)]="linhaAnexoFoto.Arquivo" [fileslimit]="1" accept="image/*"
          simple></file-upload>
      </div>
      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
          (click)="salvarArquivo(tipoAnexo.Foto, linhaAnexoFoto, modalAnexoImage)">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>

      <div class="lineDividerH d-mobile"></div>
      <div class="col-md-12 mt-3 d-mobile justify-content-center" align="center">
        <p class="titulo mb-0">Fotos adicionada(s)</p>
      </div>

      <div class="col-md-12 d-flex align-items-center d-mobile" style="min-height: 31px;"
        *ngFor="let item of arrayFotos">
        <div class="col-9">
          {{item.descricao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;">
            <i class="feather icon-download text-muted"></i></button>
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
      </div>

      <div class="m-2 d-mobile"></div>

    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalAnexoAudio [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ANEXAR ÁUDIO</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalAnexoAudio)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [(ngModel)]="linhaAnexoAudio.Descricao"
          name="linhaAnexoAudio.Descricao" placeholder="Descrição">
      </div>
      <div class="col-md-12">
        <file-upload class="fundoInput" [(ngModel)]="linhaAnexoAudio.Arquivo" [fileslimit]="1" accept="audio/*"
          simple></file-upload>
      </div>
      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
          (click)="salvarArquivo(tipoAnexo.Audio, linhaAnexoAudio, modalAnexoAudio)">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalAnexoVideo [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ANEXAR VÍDEO</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalAnexoVideo)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [(ngModel)]="linhaAnexoVideo.Descricao"
          name="linhaAnexoVideo.Descricao" placeholder="Descrição">
      </div>
      <div class="col-md-12">
        <file-upload class="fundoInput" [(ngModel)]="linhaAnexoVideo.Arquivo" [fileslimit]="1" accept="video/*"
          simple></file-upload>
      </div>
      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
          (click)="salvarArquivo(tipoAnexo.Video, linhaAnexoVideo, modalAnexoVideo)">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalAnexoTestamento [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ADICIONAR TESTAMENTO</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalAnexoTestamento)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="text" class="form-control fundoInput" [(ngModel)]="linhaAnexoTestamento.Descricao"
          name="linhaAnexoTestamento.Descricao" placeholder="Descrição">
      </div>
      <div class="col-md-12">
        <file-upload class="fundoInput" [(ngModel)]="linhaAnexoTestamento.Arquivo" [fileslimit]="1"
          simple></file-upload>
      </div>
      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
          (click)="salvarArquivo(tipoAnexo.Testamento, linhaAnexoTestamento, modalAnexoTestamento)">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>
      <!-- <div class="col-md-12 " align="center">
        <br><br>
        <button type="button" class="btn  button-rounded" style="padding-left: 40px; padding-right: 40px;"
          (click)="salvarArquivo(tipoAnexo.Testamento, linhaAnexoTestamento, modalAnexoTestamento)">
          <i class="feather icon-edit"></i>
          SALVAR
        </button>
      </div> -->
    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalAnexoOutro [hideFooter]="true" [dialogClass]="'modal-md'" class="modalMobile">
  <div class="app-modal-header">
    <h5 class="modal-title titulo d-none-sm">ANEXAR ARQUIVOS</h5>
    <h5 class="modal-title titulo d-mobile">ANEXAR ARQUIVOS</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalAnexoOutro)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12 mt-3">
        <input type="text" class="form-control fundoInput" [(ngModel)]="linhaAnexoOutro.Descricao"
          name="linhaAnexoOutro.Descricao" placeholder="Descrição">
      </div>
      <div class="col-md-12">
        <file-upload class="fundoInput" [(ngModel)]="linhaAnexoOutro.Arquivo" [fileslimit]="1" simple></file-upload>
      </div>

      <div class="col-md-12">
         <select class="form-control fundoInput"  [(ngModel)]="idTipoArquivoSalvar" name="idTipoArquivoSalvar">
           <option *ngFor="let item of arrayTipoArquivo" value="{{item.value}}">{{item.name}}</option>
        </select>
      </div>

      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
          (click)="salvarArquivo(tipoAnexo.Outro, linhaAnexoOutro, modalAnexoOutro)">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>

      <div class="lineDividerH d-mobile"></div>
      <div class="col-md-12 mt-3 mb-2 d-mobile justify-content-center" align="center">
        <p class="titulo mb-0">ARQUIVO(s) ADICIONADO(s)</p>
      </div>


      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" style="min-height: 31px; text-transform: uppercase;"
        *ngFor="let item of arrayDoc">
        <div class="col-5 limitadorCaracteres">
          {{item.descricao}}
        </div>
        <div class="col-5">
          Tipo: {{item.extensao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>
      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" style="min-height: 31px; text-transform: uppercase;"
        *ngFor="let item of arrayFotos">
        <div class="col-5 limitadorCaracteres">
          {{item.descricao}}
        </div>
        <div class="col-5">
          Tipo: {{item.extensao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>
      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" style="min-height: 31px; text-transform: uppercase;"
        *ngFor="let item of arrayAudios">
        <div class="col-5 limitadorCaracteres">
          {{item.descricao}}
        </div>
        <div class="col-5">
          Tipo: {{item.extensao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>
      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" style="min-height: 31px; text-transform: uppercase;"
        *ngFor="let item of arrayVideos">
        <div class="col-5 limitadorCaracteres">
          {{item.descricao}}
        </div>
        <div class="col-5">
          Tipo: {{item.extensao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>
      <div class="col-md-12 d-flex align-items-center d-mobile flex-wrap" style="min-height: 31px; text-transform: uppercase;"
        *ngFor="let item of arrayOutro">
        <div class="col-5 limitadorCaracteres">
          {{item.descricao}}
        </div>
        <div class="col-5">
          Tipo: {{item.extensao}}
        </div>
        <div class="col-1">
          <button type="button" class="btn btn-outline-danger bloquearNaEdicao" style="border: none !important;"
            (click)="deletarArquivo(item.guid,item.idTestamento)">
            <img class="iconMobileAnimated topNeg8" src="/assets/images/icones-mobile/excluir.gif">
          </button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>

      <div class="m-2 d-mobile"></div>

    </div>

  </div>
</app-ui-modal>

<app-ui-modal #modalMultipleFileStatus [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">Status do Upload</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="fecharModalMultipleFileStatus(modalMultipleFileStatus)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">

        <div class="files-list" *ngIf="!exibirBoxUpload">
          <div class="single-file" *ngFor="let file of files; let i = index">
            <img src="assets/img/dnd/ic-file.svg" width="45px" alt="file">
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
              <app-progress [progress]="file?.progress"></app-progress>
              <div class="divStatusFile" [ngClass]="{'fileSuccess': file?.error != null && !file?.error,'fileError': file?.error}">Processing - {{file?.error == null ? '' : (file?.error ? 'Error' : 'Success')}}</div>
            </div>
            <img src="assets/img/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
          </div>
        </div>
      </div>

      </div>
      <div class="m-2 d-mobile"></div>

    </div>
</app-ui-modal>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, REDIRECT_SITE } from 'src/environments/environment';

@Component({
  selector: 'app-aceite',
  templateUrl: './aceite.component.html',
  styleUrls: ['./aceite.component.scss']
})
export class AceiteComponent implements OnInit {

  @Input() donoTestamento;
  public user: ResponseUser;
  public arrayTestamentos: any[];
  public testamentoSelecionado: any;
  public aceiteTermo: boolean;
  public urlPdf: string;
  public textoTermo: string;


  constructor(
    public util: UtilService,
    public ajax: AjaxService,
    private auth: AuthService
  ) {
    this.user = this.auth.getUser();
    this.aceiteTermo = false;
    this.urlPdf = "";
    this.textoTermo = "";
  }

  ngOnInit() {
    this.buscarTestamentos();
    this.testamentoSelecionado = null;
  }

  downloadTermo(){
    window.open(this.urlPdf, '_blank').focus();
  };

  buscarTextoTermoCompromisso(){
    let url = API + "TestamentoDigital/Testamento/"+this.testamentoSelecionado.idTestamento+"/TextoTermoCompromissoExecutor/"+this.user.user.idExecutor;
    this.ajax.getAsync(url, response => {

      this.textoTermo = response?.conteudoTexto;
      this.textoTermo += '<style>.htmlTermo h2{font-size: 18px !important;}</style>';
    });
  }

  buscarTestamentos(){
    let url = API + "TestamentoDigital/AssinaturaTermoAceiteExecutor";
    this.ajax.getAsync(url, response => {

      if(response?.testamentos.length > 0){
        this.arrayTestamentos = response?.testamentos;
      }
      else
        this.util.toasterShowError("Atenção","Não existem Testamentos para você aprovar");
    });
  }




  acaoAceitar(modal, item){
    this.testamentoSelecionado = item;
    this.urlPdf = API + "TestamentoDigital/Testamento/"+this.testamentoSelecionado.idTestamento+"/DownloadTermoCompromissoExecutor/"+this.user.user.idExecutor;
    this.util.openModal(modal);
    this.buscarTextoTermoCompromisso();
  }

  voltarLogin(){
    this.auth.clearUser();
    setInterval(() => {
        location.href = REDIRECT_SITE;
    }, 500);
  }

  salvarAceite(modal){
    this.util.showLoading();
    if(this.testamentoSelecionado != null && this.aceiteTermo){
      let url = API + "TestamentoDigital/AssinaturaTermoAceiteExecutor/"+this.testamentoSelecionado.idTestamento+"/"+this.user.user.idExecutor
      let dados = {};
      this.ajax.patchAsync(url, dados, response => {
        if(response?.atualizado){
          this.buscarTestamentos();
          this.util.toasterShowSuccess("Atenção","Você se tornou o(a) Executor(a)");
          this.util.closeModal(modal);
        }
        else
          this.util.toasterShowError("Atenção","Erro ao processar dados");

        this.util.closeLoading();
      });
    }else{
      this.util.toasterShowError("Atenção","É necessário aceitar os termos");
      this.util.closeLoading();
    }
  }



}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { jwtDecode,  ResponseUser, User } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-redefinirSenha',
  templateUrl: './redefinirSenha.component.html',
  styleUrls: ['./redefinirSenha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {

  confirmacaoSenha: string;
 public novaSenha: string;
  data:string;
  public exibirRecuperacaoDeSenha:boolean = false;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) {

   }

  ngOnInit() {
    this.confirmacaoSenha = '';
    this.novaSenha = '';

    this.route.queryParams
    .filter(params => params.data)
    .subscribe(params => {
      this.data = params.data;
    });
  }

  validar(): boolean{

    if (!this.data) {
      this.util.toasterShowError("Atenção", "Chave não informada!");
      return false;
    }


    if (!this.novaSenha) {
      this.util.toasterShowError("Atenção", "Entre com a nova senha!");
      return false;
    }

    if (!this.confirmacaoSenha) {
      this.util.toasterShowError("Atenção", "Entre com a confirmação da nova senha!");
      return false;
    }


    if (this.confirmacaoSenha != this.novaSenha) {
      this.util.toasterShowError("Atenção", "A nova senha e o campo confirmação senha deverão ser idênticos!");
      return false;
    }

    return true;
  }

irParaTelaDeLogin(){
  this.router.navigate(['/login']);
}

async alterarSenha(){

  if(this.validar()){

  var url = `${API}auth/User/RedefinirSenha`;
let dados ={
   "CriptoData":this.data,
  "NovaSenha" : this.novaSenha,
  "ConfirmacaoNovaSenha":this.confirmacaoSenha
 };

  await this.ajax.postAsync(url,  dados,response =>{
    this.util.toasterShowSuccess("Sucesso", "Senha redefinida com sucesso!");
    this.irParaTelaDeLogin();
  });
}
}


}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TestamentoEdicao } from '../../model/TestamentoEdicao';

@Component({
  selector: 'app-preview-testamento',
  templateUrl: './preview-testamento.component.html',
  styleUrls: ['./preview-testamento.component.scss']
})
export class PreviewTestamentoComponent implements OnInit {

  //public objValidarTestamento: any;
  @Input() homeValidacao: ValidacaoModel;

  public fraseTituloTestamento:string='';
  private testamentoEdicao :TestamentoEdicao;
  public isEdicao:boolean;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
  ) {

    this.testamentoEdicao = new TestamentoEdicao();
    this.isEdicao = this.testamentoEdicao.buscarTestamentoEmEdicao()
    this.homeValidacao = new ValidacaoModel(ajax, util, auth);

   }

  ngOnInit() {
    this.initTypeWriter(200,"CONCLUA SEU TESTAMENTO");
  }

  buscarTestamento() {
    if(this.testamentoEdicao.buscarTestamentoEmEdicao() ){
      this.inicializarTestamento(this.testamentoEdicao.Testamento);
      this.isEdicao=true;
      return true;
     }

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        if (response.testamento != null) {
          this.isEdicao=false;
           this.inicializarTestamento(response.testamento);
        }else{
          this.util.toasterShowWarning("Atenção", "É necessário terminar o preenchimento do testamento");
        }
        this.util.closeLoading();

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  inicializarTestamento(testamento:any){
    this.homeValidacao.Andamento = {testamento};
    this.auth.setTestamento(testamento);
    this.auth.getTestamento();
    this.validacaoTestamento();
}

encerrarEdicao(){

  this.testamentoEdicao.buscarTestamentoEmEdicao();

  let url =`${API}TestamentoDigital/Testamento/${this.testamentoEdicao.Testamento.guid}/${this.testamentoEdicao.Testamento.chave}/Reconstruir`;
  this.ajax.putAsync(url,null, r =>{

    this.testamentoEdicao.limparTestamentoEmEdicao();
    this.router.navigate(['/gerenciar-testamento']);
  });
}

  validacaoTestamento() {
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Validar";
    return this.ajax.get(url)
      .subscribe(response => {
        this.homeValidacao.Validar = response;
        this.util.closeLoading();
        this.validarProximaTela(response);
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  initTypeWriter(textSpeed:any,text:string){

    var speed = typeof textSpeed == 'undefined' ? 200 : textSpeed;
    var cont = 0;
    var that = this;
    function typeWriter() {
        if (cont < text.length) {
          that.fraseTituloTestamento += text.charAt(cont);
            cont++;
            setTimeout(typeWriter, speed);
        } else {
            cont = 0;
        }
    }
    typeWriter();
  }


  escolhaPlano(){
    this.buscarTestamento();
  }

  validarProximaTela(obj: any){
    if(obj.status.arquivosEnviados &&
      obj.status.beneficiariosEnviados &&
      obj.status.dadosTestadorEnviados &&
      obj.status.executoresEnviados &&
      obj.status.testemunhasEnviadas
       ){
        this.router.navigate(['/pagamento']);
       }else{
        Swal.fire(
          'Atenção!',
          obj.mensagensValidacao,
          'warning'
        );
       }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-acompanhar-assinaturas',
  templateUrl: './modal-acompanhar-assinaturas.component.html',
  styleUrls: ['./modal-acompanhar-assinaturas.component.scss']
})
export class ModalAcompanharAssinaturasComponent implements OnInit {

  public exibirModalEdicaoTestemunhas:boolean =false;
  public arrayTestemunhas: any[];

  @Input() objTestamento: any;
  public validado:any = false;
  public mensagensValidacao:string;
  constructor(
    private ajax: AjaxService,
    public util: UtilService
  ) {
    debugger;
    this.exibirModalEdicaoTestemunhas =false;
  }

  async editarTestemunha(idTestamento:string,testemunha:any):Promise<any> {

    this.util.Confirmacao("Tem certeza que deseja editar o registro? A edição de dados só deve ser realizada em caso de divergência ou erro nas informações dos signatários.",async ()=>{
      let dados =  {
        "idTestamento": idTestamento,
        "email": testemunha.email,
        "cpf": testemunha.cpf,
      };

      let url = API + "TestamentoDigital/Testemunha/"+testemunha.guid;
      return await this.ajax.putAsync(url, dados, async response => {
            this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
            await this.carregarTestemunhasTestamento(idTestamento);
      });
    });
  }

  ngOnChanges() {
    debugger;
    this.exibirModalEdicaoTestemunhas =false;
    this.arrayTestemunhas=[];
    if(this.objTestamento){
      let url = API + "TestamentoDigital/Testamento/" + this.objTestamento.guid + "/Validar";
      return this.ajax.get(url)
        .subscribe(response => {
          this.validado = response.status;
          this.mensagensValidacao = response.mensagensValidacao;
        }, err => {

        });
    }
  }

  voltarParaAcompanhamento()
  {
    this.exibirModalEdicaoTestemunhas=false;
  }

  async carregarTestemunhasTestamento(id:string):Promise<any>{

    let url = `${API}TestamentoDigital/Testamento/${id}/Testemunhas`;
    return await this.ajax.getAsync(url,response => {
        this.arrayTestemunhas = response.data;
        this.exibirModalEdicaoTestemunhas =true;
      });
  }

  async ngOnInit() {
    debugger;
    this.exibirModalEdicaoTestemunhas=false;
  }

  showMessage(message:string){
    if(message)
      Swal.fire("Atenção",message);
    else
      Swal.fire("Atenção","Ainda não há dados a serem exibidos!");
  }
}

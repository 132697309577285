<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div *ngIf="estaPago" class="row" id="gerenciarTestamentos">

  <!--<div style="margin-top:40px;" class="row img-topo img-cadastro" >
    <p>
      Para criar um novo testamento adicione um Cliente na Aba “Gerenciar Clientes” e clique no botão Criar/Continuar testamento.
    </p>
</div>-->

  <div class="col-12 col-md-2 nav menuLateralEsquerdo">
    <ul>

      <li class="iten" [ngClass]="{'active': this.abas.testamento}"
        (click)="selectAba('testamento')">
        <svg id="cls-icon-logo" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 33.85 50.78">
          <defs>
            <style>
                .cls-icon-logo {
                  fill: #353b4c;
                }
              </style>
          </defs>
          <path class="iconeMenuLateralEsquerdo cls-icon-logo"
            d="M27.51,20.65V10.58C27.51,4.74,22.77,0,16.93,0S6.35,4.74,6.35,10.58v10.07c-3.87,3.1-6.35,7.86-6.35,13.2,0,9.35,7.58,16.93,16.93,16.93s16.93-7.58,16.93-16.93c0-5.34-2.48-10.1-6.35-13.2ZM10.58,10.58c0-3.5,2.85-6.35,6.35-6.35s6.35,2.85,6.35,6.35v7.59c-1.96-.8-4.1-1.24-6.35-1.24s-4.39,.45-6.35,1.24v-7.59Zm6.35,35.97c-7,0-12.69-5.69-12.69-12.69s5.69-12.69,12.69-12.69,12.69,5.69,12.69,12.69-5.69,12.69-12.69,12.69Z" />
          <path class="cls-icon-logo"
            d="M6.42,33.73c.04,1.22,.54,2.39,1.37,3.28,.97,1.02,2.29,1.59,3.69,1.58,1.36,0,2.65-.59,3.61-1.55l.27-.27c.46-.46,.46-1.2,0-1.66s-1.2-.46-1.66,0l-.29,.29c-.47,.47-1.09,.78-1.75,.83-1.03,.08-2-.42-2.55-1.34-.13-.21-.22-.44-.28-.67-.28-1.16,.17-2.31,1.16-2.96,.27-.18,.58-.3,.9-.37,.92-.18,1.83,.09,2.47,.74l1.9,1.9h0s3.57,3.58,3.57,3.58c.95,.95,2.22,1.48,3.57,1.48,1.35,0,2.61-.52,3.57-1.48,1-1,1.52-2.34,1.47-3.75-.04-1.22-.54-2.39-1.37-3.28-.97-1.02-2.29-1.59-3.69-1.58-1.36,0-2.65,.59-3.61,1.55l-.27,.27c-.46,.46-.46,1.2,0,1.66,.46,.46,1.2,.46,1.66,0l.29-.29c.47-.47,1.09-.78,1.75-.83,1.03-.08,2,.42,2.55,1.34,.13,.21,.22,.44,.28,.67,.28,1.16-.17,2.31-1.16,2.96-.27,.18-.58,.3-.9,.37-.92,.18-1.83-.09-2.47-.74l-1.9-1.9h0s-3.57-3.58-3.57-3.58c-.95-.95-2.22-1.48-3.57-1.48s-2.61,.52-3.57,1.48c-1,1-1.52,2.34-1.47,3.75Z" />
        </svg>
        <span class="text-uppercase">gerenciar testamentos</span>
      </li>
      <li *ngIf="isAdvogadoUtilizando && isAdministradorEmpresa" class="iten" [ngClass]="{'active': this.abas.gerusuarios}"
        (click)="selectAba('gerusuarios')"> <i
          class="feather icon-users f-20 iconeMenuLateralEsquerdo"></i>
        <span>GERENCIAR USUÁRIOS</span>
      </li>
      <li *ngIf="isAdvogadoUtilizando" class="iten" [ngClass]="{'active': this.abas.gerclientes}"
      (click)="selectAba('gerclientes')"> <i
        class="feather icon-user-check f-20 iconeMenuLateralEsquerdo"></i>
      <span>GERENCIAR CLIENTES</span>
    </li>
      <li *ngIf="!isClienteAcessoTestadorDireto" class="iten" [ngClass]="{'active': this.abas.perfil}"
        (click)="selectAba('perfil')"> <i
          class="feather icon-user f-20 iconeMenuLateralEsquerdo"></i>
        <span>MINHA CONTA</span>
      </li>
      <li *ngIf="isAdvogadoUtilizando && isAdministradorEmpresa" class="iten" [ngClass]="{'active': this.abas.relatorios}"
        (click)="selectAba('relatorios')"> <i
          class="feather icon-file-text f-20 iconeMenuLateralEsquerdo"></i>
        <span>RELATÓRIOS GERENCIAIS</span>
      </li>
    </ul>

  </div>
  <div class="col-12 col-sm-12 col-md-10 minhaConta" *ngIf="abas.perfil">
    <app-card [options]="false" [hidHeader]="true">
      <h3 class="mt-4 card-title titulo d-none-sm">MINHA CONTA</h3>
      <div class="lineBottom d-none-sm"></div>

      <div class="row">
        <div class="col-12 col-md-8 barraDivisao mb-5">
          <p class="titulo d-none-sm">ALTERAR DADOS CADASTRAIS</p>
          <app-componente-cad-usuario
            [TodosCampos]="false"></app-componente-cad-usuario>
        </div>
        <div class="col-12 col-md-4">
          <p class="titulo d-none-sm text-center">GOSTARIA DE DESATIVAR SUA
            CONTA?</p>
          <div class="row">
            <div class="col-md-12 desativarConta" align="center">
              <!-- GOSTARIA DE DESATIVAR SUA CONTA? -->
              <button (click)="util.openModal(modalDesativarConta);"
                class="btn button-rounded btnDesativar col-md-6">Clique Aqui
                <i class="feather icon-power" aria-hidden="true"></i>
              </button>
              <!--<p class="mb-0 text-muted">Não tem uma conta ainda? <br><a   class="f-w-400 link-blue">Cadastrar</a></p><br>-->
            </div>

          </div>
        </div>
      </div>

    </app-card>

  </div>
  <div class="col-12 col-sm-12 col-md-10 Testamentos" *ngIf="abas.testamento">

    <app-card [options]="false" [hidHeader]="true">

      <div style="margin-top:40px;" class="row img-topo img-cadastro" >
        <p>
          Para criar um novo testamento adicione um Cliente na Aba “Gerenciar Clientes” e clique no botão Criar/Continuar testamento.
        </p>
    </div>

      <h3 class="mt-4 card-title titulo d-none-sm">gerenciar testamentos</h3>
      <div class="lineBottom d-none-sm"></div>

      <div class="filtros col-12">
        <div class="form-group form-primary mb-0 col-md col-12">
          <label class="label-control">filtrar testamentos
            <select (change)="filtroChange()" [(ngModel)]="statusTestamentoFiltroSelecionado" name="select" class="form-control form-control-sm">
              <option value="">Selecione</option>
              <option *ngFor="let item of arrayStatusTestamento" value="{{item.value}}">{{item.name}}</option>
            </select>
            <span class="form-bar"></span>
          </label>
        </div>
        <div *ngIf="isAdministradorEmpresa" class="form-group form-primary mb-0 col-md-3 col-12">
          <label class="label-control">Responsável
            <select (change)="filtroChange()" [(ngModel)]="advogadoFiltroSelecionado" name="select" class="form-control form-control-sm">
              <option value="">Selecione</option>
              <option value="{{item.id}}"  *ngFor="let item of arrayAdvogados; let i = index ">{{item.nome}}</option>
            </select>
            <span class="form-bar"></span>
          </label>
        </div>
      </div>

      <div class="table-responsive pagination">

        <table class="table table-xs comScroll text-center">
          <thead>
            <tr class="dadosTituloTestamento">
              <!-- <th class="">NOME TESTAMENTO</th>
                <th class="">DATA INÍCIO</th>
                <th class="">STATUS</th> -->
              <th  class>TESTAMENTO</th>
              <th *ngIf="isAdvogadoUtilizando" class>ADV.RESPONSÁVEL</th>
              <th *ngIf="isAdvogadoUtilizando" class>CLIENTE</th>
              <th class>DATA INÍCIO</th>
              <th class>STATUS</th>
              <th class="headerAcoes">Ações</th>
              <th class="exibirDetalhesTamanhoColuna">EXIBIR DETALHES</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of arrayTestamentos; let i = index ">

              <tr class="dadosTestamento">
                <td>{{item.nome}}<br />{{item.isPlanoAssinatura ?
                  'Plano Assinatura' : 'Plano Pagamento
                  Único'}}</td>
                <td class title *ngIf="isAdvogadoUtilizando">{{item.nomeAdvogado}}</td>
                <td class title *ngIf="isAdvogadoUtilizando">{{item.nomeCliente}}</td>
                <td class>{{item.dataCadastro | date: "dd/MM/yyyy HH:mm"}}</td>
                <td class="statusItem" title="{{item.status}}">

                   <i *ngIf="item.status == 'Cancelado' || item.status == 'Aguardando Assinaturas'" ngbTooltip="{{item.status}}" placement="auto"
                  class="feather" [ngClass]="{'icon-x-circle corPrincipal': item.status === 'Cancelado', 'icon-x-circle corAzul': item.status === 'Aguardando Assinaturas'}">
                  </i>

                  <i *ngIf="item.status == 'Concluido'" class="feather icon-check-circle"
                    ngbTooltip="{{item.status}}" placement="auto"></i>

                  <span *ngIf="item.status != 'Concluido' && item.status != 'Cancelado' && item.status != 'Aguardando Assinaturas'">{{item.status}}</span>

                </td>
                <td class="gridAcao">
                  <button *ngIf="item.status != 'Concluido' && item.status != 'Cancelado' && item.status != 'Sinistro Aberto'" class="btn btnVerde" ngbTooltip="Informação sobre" (click)="selecionarCliente(item.idCliente)"
                    placement="auto">
                    <i class="far fa-credit-card" aria-hidden="true"></i>
                    CONCLUIR
                  </button>

                </td>
                <td class="exibirDetalhes exibirDetalhesTamanhoColuna">
                  <button type="button" class="btn btn-link"
                    [class.rotate]="testamentoIndex === i"
                    (click)="testamentoIndex = testamentoIndex === 0 ||  testamentoIndex? null : i "></button>
                </td>

              </tr>

              <!-- <tr @fade *ngIf="testamentoIndex === i " [id]="'testamento_info' + i" class="tableInfoTestamento">

                  <td colspan="4" class="testamento_accordion">
                    <table style="width: 100%;" class="tableDadosTestamento">
                      <thead>
                        <tr>
                          <th class="col-md-2">DATA CONCLUSÃO</th>
                          <th class="col-md-2">PARTICULAR COM 03 TESTEMUNHAS?</th>
                          <th class="col-md-2">EXECUTOR(ES)</th>
                          <th class="col-md-2">BENEFICIÁRIO(S)</th>
                          <th class="col-md-2">Nº DE ARQUIVOS</th>
                          <th class="col-md-2">TAMANHO (MB)</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>23/10/2022</td>
                        <td>Sim</td>
                        <td>2</td>
                        <td>1</td>
                        <td>5</td>
                        <td>42</td>
                      </tr>
                    </tbody>
                    </table>
                  </td>
                </tr> -->
              <tr @fade *ngIf="testamentoIndex === i "
                [id]="'testamento_accordion' + i" class="tableInfoBotoes">
                <td colspan="12" class="testamento_accordion">
                  <table style="width: 100%;">
                    <tr class="tableInfoBotoesRow">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2"
                        [ngClass]="item.cancelado ? 'acaoDisabled' : 'enabled'">

                        <div *ngIf="!item.cancelado" class="btnGerenciar"
                          style="border-color: #29D8A2;">
                          <svg id="iconEnviar"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-icon-envio {
                                    fill: #29D8A2;
                                  }
                                </style>
                              <filter id="drop-shadow-11"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-icon-envio"
                              d="M28.5,5.5l-4.5,16.5-6.1-5.43,5.85-6.18-7.84,5.42-5.41-1.32L28.5,5.5Zm-11,12.5v5.5l2.19-3.32-2.19-2.18Zm-8.7,6.82l-1.44-.51,5.7-5.22,1.44,.4-5.7,5.33Zm3.26,4.29l-1.32-.57,4.44-4.5,1.32,.45-4.44,4.61Zm-4.08,.39l-1.48-.51,8-7.89,1.44,.4-7.96,8Z" />
                          </svg>

                          <a (click)="exibirLogEnvioEmails(item.guid)"
                            href="javascript:void"
                            style="cursor:pointer; color: #29D8A2;">
                            Envio de E-mails
                          </a>

                        </div>

                        <div *ngIf="item.cancelado" class="btnGerenciar"
                          style="border-color: #b9b9b97a;">

                          <svg id="iconEnviar"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-icon-envio2 {
                                    fill: #b9b9b97a;
                                  }
                                </style>
                              <filter id="drop-shadow-11"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-icon-envio2"
                              d="M28.5,5.5l-4.5,16.5-6.1-5.43,5.85-6.18-7.84,5.42-5.41-1.32L28.5,5.5Zm-11,12.5v5.5l2.19-3.32-2.19-2.18Zm-8.7,6.82l-1.44-.51,5.7-5.22,1.44,.4-5.7,5.33Zm3.26,4.29l-1.32-.57,4.44-4.5,1.32,.45-4.44,4.61Zm-4.08,.39l-1.48-.51,8-7.89,1.44,.4-7.96,8Z" />
                          </svg>

                          <a href="javascript:void;" style="color: #b9b9b97a;">
                            Envio de E-mails
                          </a>
                        </div>

                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2"
                        [ngClass]="item.cancelado ? 'acaoDisabled' : 'enabled'">
                        <div *ngIf="!item.cancelado" class="btnGerenciar"
                          style="border-color: #ff9a79; color: #ff9a79;">

                          <svg id="iconAssina"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 20.64 18.54">
                            <defs>
                              <style>.cls-icon-assina{fill:#ff9a79;}</style>
                              <clippath id="clippath">
                                <rect class="cls-2" x="-574.8" y="-1168.93"
                                  width="1920" height="1080" />
                              </clippath>
                            </defs>
                            <path class="cls-icon-assina"
                              d="M16.07,11.44c-.34,0-.58.27-.59.63,0,.32,0,.63,0,.95s0,.63,0,.95c0,.37.25.63.59.63.34,0,.59-.26.6-.62,0-.64,0-1.27,0-1.91,0-.36-.26-.63-.6-.62Z" />
                            <path class="cls-icon-assina"
                              d="M16.09,15.39c-.32,0-.59.26-.6.58,0,.32.26.59.57.6.33,0,.61-.26.61-.59,0-.32-.26-.59-.58-.59Z" />
                            <path class="cls-icon-assina"
                              d="M18.15,10.19c-.47-.26-.98-.41-1.51-.49v-.25c0-1.76,0-3.51,0-5.27,0-.41-.13-.74-.42-1.02-.86-.85-1.71-1.7-2.56-2.55-.28-.28-.61-.41-1.01-.41-2.46,0-4.91,0-7.36,0-.8,0-1.29.49-1.29,1.29,0,1.62,0,3.24,0,4.86v.24l-.15-.15c-.36-.36-.71-.73-1.08-1.07-.86-.81-2.22-.41-2.52.73l-.04.11v.47c.1.47.39.8.72,1.13.98.96,1.94,1.93,2.91,2.9.11.11.17.23.17.39,0,1.46,0,2.92,0,4.38,0,.83.48,1.31,1.31,1.31,2.41,0,4.82,0,7.23,0,.14,0,.23.04.32.14,1.05,1.13,2.35,1.61,3.88,1.39,1.87-.27,3.31-1.72,3.64-3.61.31-1.77-.6-3.61-2.23-4.5ZM1.18,6.92c-.26-.29-.25-.71.02-.98.27-.27.69-.29.98-.04.17.15.32.31.48.47l.1.11c-.34.34-.67.67-1.02,1.02-.18-.18-.38-.37-.57-.57ZM2.43,8.17l-.05-.06c.35-.34.68-.67,1.01-1.01l.14.13c1.23,1.23,2.47,2.46,3.69,3.7.09.09.16.21.2.33.15.43.29.87.45,1.34-.52-.17-1.01-.34-1.5-.5l-.11-.09c-1.28-1.28-2.56-2.56-3.84-3.84ZM9.96,13.67h1.79c-.04.81.09,1.57.46,2.31h-.21c-2.23,0-4.46,0-6.69,0-.38,0-.51-.13-.52-.51,0-1.26,0-2.51,0-3.83.34.34.63.65.94.94.13.12.28.22.44.27.69.24,1.39.47,2.09.7.19.06.36.09.51-.07.15-.15.12-.33.06-.51-.24-.7-.46-1.41-.71-2.11-.05-.15-.14-.3-.26-.41-.98-.99-1.97-1.97-2.95-2.97-.07-.07-.13-.2-.13-.3,0-1.91,0-3.82,0-5.72,0-.34.14-.49.47-.49,2.42,0,4.84,0,7.26,0h.16v.22c0,.7,0,1.4,0,2.09,0,.51.33.84.84.84h.08s2.22,0,2.22,0v5.51c-.32.06-.65.11-.97.2-.2.05-.37.15-.56.23h0s-4.89,0-4.89,0c-.27,0-.48.22-.48.48s.22.48.48.48h3.39c-.07.08-.17.23-.38.61-.13.26-.26.51-.37.77-.06.15-.11.23-.15.29h-1.94c-.27,0-.48.22-.48.48s.22.48.48.48ZM16.07,17.56c-1.96,0-3.55-1.6-3.54-3.57,0-1.96,1.6-3.55,3.57-3.54,1.96,0,3.55,1.61,3.54,3.57,0,1.96-1.61,3.55-3.57,3.54Z" />
                            <path class="cls-icon-assina"
                              d="M12.96,5.6c.08-.08.13-.18.12-.29,0,0,0,0,0,0,0,0,0-.01,0-.02,0-.27-.22-.48-.48-.48h-6.24c-.27,0-.48.22-.48.48s.22.48.48.48h6.24c.15,0,.27-.07.36-.17Z" />
                            <path class="cls-icon-assina"
                              d="M13.08,7.92c0-.27-.22-.48-.48-.48h-4.99c-.27,0-.48.22-.48.48s.22.48.48.48h4.99c.27,0,.48-.22.48-.48Z" />
                          </svg>

                          <a href="javascript:"
                            (click)="item.cancelado ? semAcao() : openModal(modalAcompanharAssinaturas, item.guid)"
                            style="cursor:pointer; color: #ff9a79;">
                            Acompanhar Assinaturas
                          </a>
                        </div>
                        <div *ngIf="item.cancelado" class="btnGerenciar"
                          style="border-color: #b9b9b97a;">
                          <svg id="iconAssina2"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 20.64 18.54">
                            <defs>
                              <style>.cls-icon-assina2{fill:#b9b9b97a;}</style>
                              <clippath id="clippath">
                                <rect class="cls-2" x="-574.8" y="-1168.93"
                                  width="1920" height="1080" />
                              </clippath>
                            </defs>
                            <path class="cls-icon-assina2"
                              d="M16.07,11.44c-.34,0-.58.27-.59.63,0,.32,0,.63,0,.95s0,.63,0,.95c0,.37.25.63.59.63.34,0,.59-.26.6-.62,0-.64,0-1.27,0-1.91,0-.36-.26-.63-.6-.62Z" />
                            <path class="cls-icon-assina2"
                              d="M16.09,15.39c-.32,0-.59.26-.6.58,0,.32.26.59.57.6.33,0,.61-.26.61-.59,0-.32-.26-.59-.58-.59Z" />
                            <path class="cls-icon-assina2"
                              d="M18.15,10.19c-.47-.26-.98-.41-1.51-.49v-.25c0-1.76,0-3.51,0-5.27,0-.41-.13-.74-.42-1.02-.86-.85-1.71-1.7-2.56-2.55-.28-.28-.61-.41-1.01-.41-2.46,0-4.91,0-7.36,0-.8,0-1.29.49-1.29,1.29,0,1.62,0,3.24,0,4.86v.24l-.15-.15c-.36-.36-.71-.73-1.08-1.07-.86-.81-2.22-.41-2.52.73l-.04.11v.47c.1.47.39.8.72,1.13.98.96,1.94,1.93,2.91,2.9.11.11.17.23.17.39,0,1.46,0,2.92,0,4.38,0,.83.48,1.31,1.31,1.31,2.41,0,4.82,0,7.23,0,.14,0,.23.04.32.14,1.05,1.13,2.35,1.61,3.88,1.39,1.87-.27,3.31-1.72,3.64-3.61.31-1.77-.6-3.61-2.23-4.5ZM1.18,6.92c-.26-.29-.25-.71.02-.98.27-.27.69-.29.98-.04.17.15.32.31.48.47l.1.11c-.34.34-.67.67-1.02,1.02-.18-.18-.38-.37-.57-.57ZM2.43,8.17l-.05-.06c.35-.34.68-.67,1.01-1.01l.14.13c1.23,1.23,2.47,2.46,3.69,3.7.09.09.16.21.2.33.15.43.29.87.45,1.34-.52-.17-1.01-.34-1.5-.5l-.11-.09c-1.28-1.28-2.56-2.56-3.84-3.84ZM9.96,13.67h1.79c-.04.81.09,1.57.46,2.31h-.21c-2.23,0-4.46,0-6.69,0-.38,0-.51-.13-.52-.51,0-1.26,0-2.51,0-3.83.34.34.63.65.94.94.13.12.28.22.44.27.69.24,1.39.47,2.09.7.19.06.36.09.51-.07.15-.15.12-.33.06-.51-.24-.7-.46-1.41-.71-2.11-.05-.15-.14-.3-.26-.41-.98-.99-1.97-1.97-2.95-2.97-.07-.07-.13-.2-.13-.3,0-1.91,0-3.82,0-5.72,0-.34.14-.49.47-.49,2.42,0,4.84,0,7.26,0h.16v.22c0,.7,0,1.4,0,2.09,0,.51.33.84.84.84h.08s2.22,0,2.22,0v5.51c-.32.06-.65.11-.97.2-.2.05-.37.15-.56.23h0s-4.89,0-4.89,0c-.27,0-.48.22-.48.48s.22.48.48.48h3.39c-.07.08-.17.23-.38.61-.13.26-.26.51-.37.77-.06.15-.11.23-.15.29h-1.94c-.27,0-.48.22-.48.48s.22.48.48.48ZM16.07,17.56c-1.96,0-3.55-1.6-3.54-3.57,0-1.96,1.6-3.55,3.57-3.54,1.96,0,3.55,1.61,3.54,3.57,0,1.96-1.61,3.55-3.57,3.54Z" />
                            <path class="cls-icon-assina2"
                              d="M12.96,5.6c.08-.08.13-.18.12-.29,0,0,0,0,0,0,0,0,0-.01,0-.02,0-.27-.22-.48-.48-.48h-6.24c-.27,0-.48.22-.48.48s.22.48.48.48h6.24c.15,0,.27-.07.36-.17Z" />
                            <path class="cls-icon-assina2"
                              d="M13.08,7.92c0-.27-.22-.48-.48-.48h-4.99c-.27,0-.48.22-.48.48s.22.48.48.48h4.99c.27,0,.48-.22.48-.48Z" />
                          </svg>


                          <a href="javascript:void;" style="color: #b9b9b97a;">
                            Acompanhar Assinaturas
                          </a>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2"
                        [ngClass]="item.cancelado ? 'acaoDisabled' : 'enabled'">
                        <div *ngIf="!item.cancelado" class="btnGerenciar"
                          style="border-color: #8CA2E0;">
                          <svg id="iconDoc" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-3 {
                                    fill: #8CA2E0;
                                  }
                                </style>
                              <filter id="drop-shadow-13"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-3"
                              d="M13,16.6l-2.25-1.8-2.25,1.8V6.7h4.5v9.9Zm9,.9h-7.2v.9h7.2v-.9ZM14.8,6.7v1.8h9.9v10.39c0,3.7-5.4,2.21-5.4,2.21,0,0,1.37,5.4-2.37,5.4h-6.63v-9.04l-1.8,1.44v9.39h9.17c2.85,0,8.83-6.5,8.83-8.65V6.7H14.8Zm7.2,5.4h-7.2v.9h7.2v-.9Zm0,2.7h-7.2v.9h7.2v-.9Z" />
                          </svg>
                          <a
                            href="{{item.baseDownload}}TestamentoDigital/CertidaoTestamento/{{item.guid}}"
                            target="_blank"
                            style="cursor:pointer; color: #8CA2E0;">
                            Emitir Certidão
                          </a>
                        </div>
                        <div *ngIf="item.cancelado" class="btnGerenciar"
                          style="border-color: #b9b9b97a;">
                          <svg id="iconDoc" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-3 {
                                    fill: #b9b9b97a;
                                  }
                                </style>
                              <filter id="drop-shadow-13"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-3"
                              d="M13,16.6l-2.25-1.8-2.25,1.8V6.7h4.5v9.9Zm9,.9h-7.2v.9h7.2v-.9ZM14.8,6.7v1.8h9.9v10.39c0,3.7-5.4,2.21-5.4,2.21,0,0,1.37,5.4-2.37,5.4h-6.63v-9.04l-1.8,1.44v9.39h9.17c2.85,0,8.83-6.5,8.83-8.65V6.7H14.8Zm7.2,5.4h-7.2v.9h7.2v-.9Zm0,2.7h-7.2v.9h7.2v-.9Z" />
                          </svg>
                          <a href="javascript:void;" style="color: #b9b9b97a;">
                            Emitir Certidão
                          </a>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2"
                        [ngClass]="item.cancelado  ? 'acaoDisabled' : 'enabled'">
                        <div
                          *ngIf="(((item.aguardandoAssinaturaClickSign || item.concluido) && item.status != 'Sinistro Aberto'))"
                          class="btnGerenciar"
                          style="border-color: #B2779D; color: #B2779D;">
                          <svg id="iconEdit1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-11 {
                                    fill: #B2779D;
                                  }
                                </style>
                              <filter id="drop-shadow-15"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-11"
                              d="M23.18,9.25l2.58,2.58-13.53,13.53-3.22,.65,.65-3.23,13.53-13.53Zm0-2.55L7.99,21.88l-1.29,6.42,6.41-1.29,15.19-15.19-5.12-5.12Zm-.02,4.5l-11.46,11.46,.64,.64,11.46-11.46-.64-.64Z" />
                          </svg>
                          <a title="Editar"
                            (click)="abrirModalInsercaoChave(item.guid,item.idCliente)"
                            style="cursor:pointer; color: #B2779D;">
                            Editar
                          </a>
                        </div>

                        <div
                          *ngIf="(((!item.aguardandoAssinaturaClickSign && !item.concluido) || item.status == 'Sinistro Aberto'))"
                          class="btnGerenciar">
                          <svg id="iconEdit1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-12 {
                                    fill: #b9b9b97a;
                                  }
                                </style>
                              <filter id="drop-shadow-15"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-12"
                              d="M23.18,9.25l2.58,2.58-13.53,13.53-3.22,.65,.65-3.23,13.53-13.53Zm0-2.55L7.99,21.88l-1.29,6.42,6.41-1.29,15.19-15.19-5.12-5.12Zm-.02,4.5l-11.46,11.46,.64,.64,11.46-11.46-.64-.64Z" />
                          </svg>
                          <a href="javascript:void;" style="color: #b9b9b97a;">
                            Editar
                          </a>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2"
                        [ngClass]="item.cancelado ? 'acaoDisabled' : 'enabled'">
                        <div *ngIf="item.concluido" class="btnGerenciar"
                          style="border-color: #ff6679;">
                          <svg id="iconDownload"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-4 {
                                    fill: #ff6679;
                                  }
                                </style>
                              <filter id="drop-shadow-17"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-4"
                              d="M18.44,24.7h2.7l-4.52,3.6-4.48-3.6h2.7s0-7.2,7.03-7.2c-4.16,1.72-3.43,7.2-3.43,7.2Zm5.79-11.62c-.19-3.56-3.13-6.38-6.73-6.38s-6.54,2.83-6.73,6.38c-2.31,.42-4.07,2.44-4.07,4.87,0,2.73,2.22,4.95,4.95,4.95h1.59c.11-.54,.27-1.15,.52-1.8h-2.11c-1.74,0-3.15-1.41-3.15-3.15,0-2.52,2.23-3.45,3.99-3.35-.15-3.8,1.99-6.1,5.01-6.1s5.3,2.52,5.01,6.1c1.57-.04,3.99,.68,3.99,3.35,0,1.74-1.41,3.15-3.15,3.15h-2.68c-.26,.6-.39,1.24-.45,1.8h3.13c2.73,0,4.95-2.22,4.95-4.95,0-2.43-1.76-4.45-4.07-4.87Z" />
                          </svg>
                          <a href="{{item.downloadUrl}}" target="_blank"
                            style="color: #ff6679;">
                            Download do Testamento Criptografado
                          </a>
                        </div>
                        <div *ngIf="!item.concluido  || item.cancelado"
                          class="btnGerenciar acaoDisabledDiv"
                          style="border-color: #b9b9b97a;">
                          <svg id="iconDownload"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-4 {
                                    fill: #b9b9b97a;
                                  }
                                </style>
                              <filter id="drop-shadow-17"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-4"
                              d="M18.44,24.7h2.7l-4.52,3.6-4.48-3.6h2.7s0-7.2,7.03-7.2c-4.16,1.72-3.43,7.2-3.43,7.2Zm5.79-11.62c-.19-3.56-3.13-6.38-6.73-6.38s-6.54,2.83-6.73,6.38c-2.31,.42-4.07,2.44-4.07,4.87,0,2.73,2.22,4.95,4.95,4.95h1.59c.11-.54,.27-1.15,.52-1.8h-2.11c-1.74,0-3.15-1.41-3.15-3.15,0-2.52,2.23-3.45,3.99-3.35-.15-3.8,1.99-6.1,5.01-6.1s5.3,2.52,5.01,6.1c1.57-.04,3.99,.68,3.99,3.35,0,1.74-1.41,3.15-3.15,3.15h-2.68c-.26,.6-.39,1.24-.45,1.8h3.13c2.73,0,4.95-2.22,4.95-4.95,0-2.43-1.76-4.45-4.07-4.87Z" />
                          </svg>
                          <a href="javascript:void;" style="color: #b9b9b97a;">
                            Download do Testamento Criptografado
                          </a>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2"
                        [ngClass]="item.cancelado ? 'acaoDisabled' : 'enabled'">
                        <div *ngIf="!item.cancelado" class="btnGerenciar"
                          style="border-color: #FF6679;">
                          <svg id="iconExcluir"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-5 {
                                    fill: #FF6679;
                                  }
                                </style>
                              <filter id="drop-shadow-19"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-5"
                              d="M23.35,20.2c-2.24,0-4.05,1.81-4.05,4.05s1.81,4.05,4.05,4.05,4.05-1.81,4.05-4.05-1.82-4.05-4.05-4.05Zm2.25,4.5h-4.5v-.9h4.5v.9Zm-4.5-9.9v4.05c-.67,.28-1.28,.69-1.8,1.19v-5.24c0-.5,.4-.9,.9-.9s.9,.4,.9,.9Zm-3.6,0v9c0,.5-.4,.9-.9,.9s-.9-.4-.9-.9V14.8c0-.5,.4-.9,.9-.9s.9,.4,.9,.9Zm1.64,13.5H8.5V12.1h1.8v14.4h7.65c.28,.67,.69,1.28,1.19,1.8Zm-6.14-14.4c.5,0,.9,.4,.9,.9v9c0,.5-.4,.9-.9,.9s-.9-.4-.9-.9V14.8c0-.5,.4-.9,.9-.9Zm12.6-3.6H7.6v-1.8h5.14c.81,0,1.47-.99,1.47-1.8h4.78c0,.81,.66,1.8,1.47,1.8h5.14v1.8Zm-.9,1.8v6.46c-.43-.1-.88-.16-1.35-.16l-.45,.02v-6.32h1.8Z" />
                          </svg>
                          <a href="javascript:void"
                            (click)="cancelarTestamento(item.guid)"
                            style="cursor:pointer; color: #FF6679;">
                            Excluir Testamento
                          </a>
                        </div>
                        <div *ngIf="item.cancelado" class="btnGerenciar"
                          style="border-color: #b9b9b97a;">
                          <svg id="iconExcluir1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 35 35">
                            <defs>
                              <style>
                                  .cls-55 {
                                    fill: #b9b9b97a;
                                  }
                                </style>
                              <filter id="drop-shadow-19"
                                filterUnits="userSpaceOnUse">
                                <feoffset dx="2" dy="2" />
                                <fegaussianblur result="blur"
                                  stdDeviation="1" />
                                <feflood flood-color="#1d283d"
                                  flood-opacity=".2" />
                                <fecomposite in2="blur" operator="in" />
                                <fecomposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <path class="cls-55"
                              d="M23.35,20.2c-2.24,0-4.05,1.81-4.05,4.05s1.81,4.05,4.05,4.05,4.05-1.81,4.05-4.05-1.82-4.05-4.05-4.05Zm2.25,4.5h-4.5v-.9h4.5v.9Zm-4.5-9.9v4.05c-.67,.28-1.28,.69-1.8,1.19v-5.24c0-.5,.4-.9,.9-.9s.9,.4,.9,.9Zm-3.6,0v9c0,.5-.4,.9-.9,.9s-.9-.4-.9-.9V14.8c0-.5,.4-.9,.9-.9s.9,.4,.9,.9Zm1.64,13.5H8.5V12.1h1.8v14.4h7.65c.28,.67,.69,1.28,1.19,1.8Zm-6.14-14.4c.5,0,.9,.4,.9,.9v9c0,.5-.4,.9-.9,.9s-.9-.4-.9-.9V14.8c0-.5,.4-.9,.9-.9Zm12.6-3.6H7.6v-1.8h5.14c.81,0,1.47-.99,1.47-1.8h4.78c0,.81,.66,1.8,1.47,1.8h5.14v1.8Zm-.9,1.8v6.46c-.43-.1-.88-.16-1.35-.16l-.45,.02v-6.32h1.8Z" />
                          </svg>
                          <a href="javascript:void;" style="color: #b9b9b97a;">
                            Excluir Testamento
                          </a>
                        </div>

                      </div>

                    </tr>
                  </table>
                </td>

              </tr>
            </ng-container>

          </tbody>
        </table>
      </div>

    </app-card>
  </div>

  <div class="col-12 col-sm-12 col-md-10 Testamentos" *ngIf="abas.gerusuarios">

    <app-card [options]="false" [hidHeader]="true">


      <a href="javascript:void" (click)="util.openModal(modalAddUsuario)"
        class="btn btnNovoTestamento">
        <i class="feather icon-plus" aria-hidden="true"></i>
        Novo Usuário
      </a>



      <h3 class="mt-4 card-title titulo d-none-sm">GERENCIAR USUÁRIOS</h3>
      <div class="lineBottom d-none-sm"></div>

      <div class="table-responsive pagination">

        <table class="table table-xs comScroll text-center">
          <thead>
            <tr class="dadosTituloTestamento">
              <th class>Nome</th>
              <th class>E-mail</th>
              <th class>Função</th>
              <!-- <th class>Telefone</th> -->
              <!-- <th class>Data Nasc.</th> -->
              <!-- <th class>CPF</th> -->
                <!-- <th class>Testamentos<br /> Cadastrados</th> -->
              <th class="headerAcoes">Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of arrayAdvogados; let i = index ">
              <tr class="dadosTestamento">
                <td class>{{item.nome}}</td>
                <td class>{{item.email}}</td>
                <td>{{item.funcao}}                </td>
               <!-- <td>
                  <span ngbTooltip="Inserir contador de testamentos cadastrados por este usuário" placement="top">XX</span>
                </td>-->
                <!-- <td class>{{item.telefone}}</td> -->
                <!-- <td class>{{item.dataNascimento}}</td> -->
                <!-- <td class>{{item.cpf}}</td> -->
                <td class="gridBtnAcoes">
                  <!--<button type="button" ngbTooltip="Editar dados usuário" placement="top" (click)="util.openModal(modalEditarDadosUsuario)"
                  class="btn btn-outline-senha"><i class="feather icon-edit"></i></button>

                  <button type="button" ngbTooltip="Excluir usuário" placement="top"
                  class="btn btn-outline-danger bloquearNaEdicao"><i class="feather icon-trash"></i></button>

                  <button type="button" ngbTooltip="Desativar minha conta" placement="top"
                  class="btn btn-outline-danger corSecundaria bloquearNaEdicao"><i class="feather icon-power"></i></button>
                -->
                </td>

              </tr>



            </ng-container>
            <!-- <ng-container #empty>
                <tr>
                  <td colspan="4">
                        Nenhum testamento cadastrado
                    </td>
                </tr>
              </ng-container> -->
          </tbody>
        </table>
      </div>

    </app-card>
  </div>

  <div class="col-12 col-sm-12 col-md-10 Testamentos" *ngIf="abas.gerclientes">

    <app-card [options]="false" [hidHeader]="true">

      <a href="javascript:void" (click)="util.openModal(modalAddCliente)"
        class="btn btnNovoTestamento">
        <i class="feather icon-plus" aria-hidden="true"></i>
        Novo Cliente
      </a>

      <h3 class="mt-4 card-title titulo d-none-sm">GERENCIAR CLIENTES</h3>
      <div class="lineBottom d-none-sm"></div>

      <div class="table-responsive pagination">

        <table class="table table-xs comScroll text-center">
          <thead>
            <tr class="dadosTituloTestamento">
              <th class>Nome</th>
              <th class>E-mail</th>
              <th class>Telefone</th>
              <th class>Data Nasc.</th>
              <th class>CPF</th>
              <th class>Cadastrado Por</th>
              <th class="headerAcoes">Testamento / Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of arrayClientes; let i = index ">

              <tr class="dadosTestamento">
                <td class>{{item.nome}}</td>
                <td class>{{item.email}}</td>
                <td class>{{item.telefone}}</td>
                <td class>{{item.dataNascimento | date: "dd/MM/yyyy"}}</td>
                <td class>{{item.cpf}}</td>
                <td class>{{item.advogadoCadastrou}}</td>
                 <td class="gridBtnAcoes">

                  <button type="button" style="font-size: 15px;" ngbTooltip="Criar/Continuar Testamento de '{{item.nome}}'" placement="top" (click)="selecionarCliente(item.idCliente)"
                   class="btn btn-outline-senha">
                    <i class="feather icon-file-text"></i>
                  </button>

                   <!--<button type="button" ngbTooltip="Editar dados usuário" placement="top" (click)="util.openModal(modalEditarDadosUsuario)"
                  class="btn btn-outline-senha"><i class="feather icon-edit"></i></button>

                  <button type="button" ngbTooltip="Excluir usuário" placement="top"
                  class="btn btn-outline-danger bloquearNaEdicao"><i class="feather icon-trash"></i></button>-->
                </td>
              </tr>
            </ng-container>
            <!-- <ng-container #empty>
                <tr>
                  <td colspan="4">
                        Nenhum testamento cadastrado
                    </td>
                </tr>
              </ng-container> -->
          </tbody>
        </table>
      </div>

    </app-card>
  </div>

  <div class="col-12 col-sm-12 col-md-10 Testamentos" *ngIf="abas.relatorios">

    <app-card [options]="false" [hidHeader]="true">

      <h3 class="mt-4 card-title titulo d-none-sm">RELATÓRIOS GERENCIAIS</h3>
      <div class="lineBottom d-none-sm"></div>

      <div class="filtros filtroRelatorios col-12">
        <div class="form-group form-primary mb-0 mr-5 col-6 col-md-1">
          <label class="label-control"><strong>período selecionado:</strong>
          </label>
        </div>
        <div class="form-group form-primary mb-0 col-3 col-md-3">
          <label class="label-control">De:
          <input  [(ngModel)]="periodoDeFiltroDashboard" type="date" class="form-control" />
          <span class="form-bar"></span>
        </label>
        </div>
        <div class="form-group form-primary mb-0 col-3 col-md-3">
          <label class="label-control">Até:
          <input  [(ngModel)]="periodoAteFiltroDashboard" type="date" class="form-control" />
          <span class="form-bar"></span>
        </label>
        </div>
        <div class="col-12 col-md-4 d-flex gridBtnFiltros">
          <button (click)="buscarDadosDashbord()" class="col-6 btn btnVerde">
            <i class="feather icon-search" aria-hidden="true"></i>
            filtrar
          </button>
          <button class="col-6 btn btnLimpar">
            <i class="fas fa-times" aria-hidden="true"></i>
            Limpar
          </button>
        </div>

      </div>

      <app-relatorios-gerenciais #dashboard [periodoDeFiltroDashboard]="periodoDeFiltroDashboard" [periodoAteFiltroDashboard]="periodoAteFiltroDashboard"></app-relatorios-gerenciais>
    </app-card>
  </div>

</div>



<div class="maximizarDivPrincipal col-md-12 Testamentos" style="z-index:2000" *ngIf="exibirReenvioEmail">

  <app-card [options]="false" [hidHeader]="true" [ngClass]="{'gridExibirCorpoEmail': exibirCorpoEmail}">


    <div *ngIf="exibirCorpoEmail">

        <a (click)="fecharConteudoEmail()" class="btn btnCloseModal basic basic-close"><span
      aria-hidden="true">&times;</span></a>

      <div [innerHTML]="corpoEmail"></div>
    </div>

    <a *ngIf="!exibirCorpoEmail" href="javascript:void"
      (click)="fecharModalReenvioEmail()" class="btn btnCloseModal basic basic-close"><span
      aria-hidden="true">&times;</span></a>

    <h5 *ngIf="!exibirCorpoEmail" class="card-title titulo pt-4">E-MAILS ENVIADOS</h5>
    <div *ngIf="!exibirCorpoEmail"
      class="emailEnviado table-responsive table-responsive-mobile pagination">

      <table class="table table-xs comScroll">
        <thead>
          <tr>
            <th>PARA</th>
            <th>ASSUNTO</th>
            <th>DATA</th>
            <th class="e-mail-enviado">AÇÕES</th>
            <!-- <th></th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of logEmails">
            <td>
              <span class="p-column-title">PARA</span>
              <div>
                {{item.para}}
              </div>
            </td>
            <td>
              <span class="p-column-title">ASSUNTO</span>
              <div>
                {{item.assunto}}
              </div>
            </td>
            <td>
              <span class="p-column-title">DATA</span>
              <div>
                {{item.data | date: "dd/MM/yyyy HH:mm:ss"}}
              </div>
            </td>
            <td class="e-mail-enviado d-flex">
              <span class="p-column-title">AÇÕES</span>
              <div>
                <button type="button"
                  class="btn button-rounded btnDesativar col-md-12"
                  style="margin: 0 auto;"
                  (click)="exibirConteudoEmail(item.corpo);">
                  Exibir E-mail Enviado
                </button>

                <button type="button" class="btn button-rounded col-md-12"
                  style="margin: 0 auto;"
                  (click)="reenviarEmail(item.id)">
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  Reenviar
                </button>
              </div>
              <!-- <div>
                  <button (click)="exibirConteudoEmail(item.corpo);" class="btn btn-primary btnAcaoEmail col-md-4">Exibir
                    E-mail
                    Enviado</button>
                  <button class="btn btn-primary btnAcaoEmail col-md-4" (click)="reenviarEmail(item.id)">Reenviar</button>
                </div> -->
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    <!--<app-modal-acompanhar-assinaturas></app-modal-acompanhar-assinaturas>-->
  </app-card>
</div>


<button type="button" class="close basic-close" aria-label="Close"
style="display: none; width: 0px; height: 0px"
#btnExibirModal (click)="util.openModal(modalchave)">.</button>

<button type="button" class="close basic-close" aria-label="Close"
  style="display: none; width: 0px; height: 0px"
  #btnFecharModal (click)="util.closeModal(modalchave)">.</button>

<button type="button" class="close basic-close" aria-label="Close"
  style="display: none; width: 0px; height: 0px"
  #btnExibirModalInsercaoChave
  (click)="util.openModal(modalInsercaochave)">.</button>


<!-- inicio modais  -->

  <app-ui-modal #modalAddUsuario [hideFooter]="true" [dialogClass]="'modal-md'">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">Adicionar Novo Usuário</h5>
          <button type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" #botaoFecharModalAddUsuario
            (click)="util.closeModal(modalAddUsuario)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

      <div class="card-body">


        <div class="input-group mb-2">
          <input type="text" class="form-control" placeholder="Nome"
            [(ngModel)]="nomeAdvogado" name="nomeAdvogado">
        </div>
        <div class="input-group mb-2">
          <input type="text" class="form-control" placeholder="CPF"
            [(ngModel)]="cpfAdvogado" [textMask]="{mask: maskCpf}" name="cpfAdvogado">
        </div>

          <div class="form-group form-primary mb-2">
            <!-- <label class="label-control"> -->
              <select name="select" class="form-control" [(ngModel)]="funcaoAdvogado" >
                <option value="">Selecione a Função</option>
                <option *ngFor="let item of arrayFuncaoUsuario" value="{{item.value}}">{{item.name}}</option>
              </select>
              <span class="form-bar"></span>
            <!-- </label> -->
          </div>

          <div class="input-group mb-2">
            <input type="text" class="form-control"   [(ngModel)]="numeroOABAdvogado" name="numeroOABAdvogado" placeholder="ESTADO/NÚMERO DA OAB (UF 999999)"
            name="estado-oab">
          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control"
              placeholder="Data Nascimento" [(ngModel)]="dataNascimentoAdvogado"
              [textMask]="{mask: maskData}" name="dataNascimentoAdvogado">
          </div>
          <div class="input-group mb-2">
            <input type="email" class="form-control" placeholder="E-mail"
              [(ngModel)]="emailAdvogado" name="emailAdvogado">
          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="Telefone"
              [(ngModel)]="telefoneAdvogado"
              [textMask]="{mask: maskTeleArea}" name="telefoneAdvogado">
          </div>
          <div class="input-group mb-2">
            <input type="password" class="form-control" placeholder="Senha"
              [(ngModel)]="senhaAdvogado" name="senhaAdvogado">
          </div>
          <div class="input-group mb-2">
            <input type="password" class="form-control"
              placeholder="Confirmação de Senha"
              [(ngModel)]="confirmacaoSenhaAdvogado" name="confirmacaoSenhaAdvogado">
          </div>


        <div class="col-md-12 mt-5">
          <button (click)="adicionarUsuarioAdvogado();" class="btn btn-block button-rounded mb-4">
            <i class="feather icon-upload-cloud"></i>
            <strong>Cadastrar</strong></button>
        </div>
      </div>


    </div>
  </app-ui-modal>

  <app-ui-modal #modalAddCliente [hideFooter]="true" [dialogClass]="'modal-md'">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">Adicionar Novo Cliente</h5>
          <button type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" #botaoFecharModalAddCliente
            (click)="util.closeModal(modalAddCliente)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

      <div class="card-body">

        <div class="input-group mb-2">
          <input type="text" class="form-control" placeholder="Nome"
            [(ngModel)]="nomeCliente" name="nomeCliente">
        </div>
        <div class="input-group mb-2">
          <input type="text" class="form-control" placeholder="CPF"
            [(ngModel)]="cpfCliente" [textMask]="{mask: maskCpf}" name="cpfCliente">
        </div>
        <div class="input-group mb-2">
          <input type="text" class="form-control"
            placeholder="Data Nascimento" [(ngModel)]="dataNascimentoCliente"
            [textMask]="{mask: maskData}" name="dataNascimentoCliente">
        </div>
        <div class="input-group mb-2">
          <input type="email" class="form-control" placeholder="E-mail"
            [(ngModel)]="emailCliente" name="emailCliente">
        </div>
        <div class="input-group mb-2">
          <input type="text" class="form-control" placeholder="Telefone"
            [(ngModel)]="telefoneCliente"
            [textMask]="{mask: maskTeleArea}" name="telefoneCliente">
        </div>
        <div class="col-md-12 mt-5">
          <button (click)="adicionarUsuarioCliente();" class="btn btn-block button-rounded mb-4">
            <i class="feather icon-upload-cloud"></i>
            <strong>Cadastrar Cliente</strong></button>
        </div>
      </div>


    </div>
  </app-ui-modal>


  <app-ui-modal #modalEditarDadosUsuario [hideFooter]="true" [dialogClass]="'modal-md'">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">Editar Usuário</h5>
          <button type="button" class="close basic-close" data-dismiss="modal"
            aria-label="Close" #btnExibirModalEditarDadosUsuario
            (click)="util.closeModal(modalEditarDadosUsuario)"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

      <div class="card-body">


          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="Nome" name="nome">
          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="CPF"
            name="cpf">
          </div>


          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="FUNÇÃO"
            name="funcao">
          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="ESTADO/NÚMERO DA OAB (UF 999999)"
            name="estado-oab">
          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control"
              placeholder="Data Nascimento" name="dataNascimento">
          </div>
          <div class="input-group mb-2">
            <input type="email" class="form-control" placeholder="E-mail"
              name="email">
          </div>
          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="Telefone"
              name="telefone">
          </div>
          <div class="input-group mb-2">
            <input type="password" class="form-control" placeholder="Senha"
             name="senha">
          </div>
          <div class="input-group mb-2">
            <input type="password" class="form-control"
              placeholder="Confirmação de Senha" name="confirmacaoSenha">
          </div>


        <div class="col-md-12 mt-5">
          <button class="btn btn-block button-rounded mb-4">
            <i class="feather icon-upload-cloud"></i>
            <strong>Salvar</strong></button>
        </div>
      </div>


    </div>
  </app-ui-modal>

  <app-ui-modal #modalAcompanharAssinaturas [hideFooter]="true"
    [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">Assinaturas do Testamento</h5>
      <button type="button" class="close basic-close" data-dismiss="modal"
        aria-label="Close"
        (click)="util.closeModal(modalAcompanharAssinaturas)"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <app-modal-acompanhar-assinaturas
        [objTestamento]="testamento"></app-modal-acompanhar-assinaturas>
    </div>
  </app-ui-modal>

  <app-ui-modal #modalChaveAcesso [hideFooter]="true"
    [dialogClass]="'modal-md modalChave'">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">INFORME A CHAVE DE ACESSO</h5>
      <button type="button" class="close basic-close" data-dismiss="modal"
        aria-label="Close"
        (click)="util.closeModal(modalChaveAcesso)"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <div class="col-md-12" style="padding-top: 22px;">
        <div class="form-group">
          <input type="text" class="form-control fundoInput"
            placeholder="CHAVE DE ACESSO">

          <a class="f-w-400 link-blue titulo">Esqueci minha CHAVE DE ACESSO</a>
        </div>
      </div>
      <div class="row" style="padding-top: 22px;">

        <div class="col-md-12 " align="center">
          <button type="button" class="btn  button-rounded"
            style="padding-left: 40px; padding-right: 40px;">
            <i class="feather icon-edit"></i>
            DESCRIPTOGRAFAR E EDITAR
          </button>
        </div>

      </div>
    </div>
  </app-ui-modal>

  <app-ui-modal #modalCertidaoInventario [hideFooter]="true"
    [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
      <h5 class="modal-title titulo">Certidão de Inventário</h5>
      <button type="button" class="close basic-close" data-dismiss="modal"
        aria-label="Close"
        (click)="util.closeModal(modalCertidaoInventario)"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

    </div>
  </app-ui-modal>

  <app-ui-modal #modalDesativarConta [hideFooter]="true" [hideHeader]="true"
    [dialogClass]="'modal-md'"
    [containerClick]="false" class="modalConstrucaoTestamento">

    <div class="app-modal-body" style="margin-top: 27px;">
      <a href="javascript:void" class="botaoFecharModal"
        (click)="util.closeModal(modalDesativarConta);">
        X
      </a>
      <app-card [customHeader]="false" [options]="false"
        blockClass="pt-0 users-contact">
        <div class="imgChaveModal text-center">
          <div class="row align-items-end">
            <div class="col"></div>
            <div class="col">
              <div class="position-relative d-inline-block">
                <img class=" " src="assets/images/img-chave-gerada.png"
                  alt="User image" width="230">
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="text-center">
          <br> <br> <br> <br> <br> <br>
          <h3 class="mb-1 mt-3">Desativação de conta</h3>

          <div class="col-md-12">
            <br>
            <label>Para desativar sua conta entre com sua SENHA de Usuário</label>
            <input type="password" class="form-control fundoInput text-center"
              [(ngModel)]="senhaDesativacaoConta">
          </div>

        </div>

        <br />
        <div class="col-md-12">
          <br>
          <b>
            <label style="text-align: justify; width: 100%;; text-align: center">
              Prezado(a) Usuário(a)</label> <br />
            <label style="text-align: justify">
              Ao desativar sua conta todos os seus Testamentos Digitais serão
              apagados. Seus dados cadastrais serão
              preservados caso opte por retornar. Alerta: Se houve elaboração de
              um testamento particular assinado pelo
              Usuário e por três testemunhas o cancelamento do mesmo depende da
              elaboração de outro o revogando. Deseja
              prosseguir?
            </label></b>
          <br />
        </div>

        <div class="row mt-3 text-center">
          <div align="center" style="float: left;" class="col-6">
            <button class="btn btnCancelar w-100"
              (click)="util.closeModal(modalDesativarConta);">
              <i class="fa" aria-hidden="true"></i>
              Não, voltar
            </button>
          </div>
          <div align="center" style="float: right;" class="col-6">
            <button class="btn button-rounded w-100" (click)="desativarConta()">
              <i class="fa" aria-hidden="true"></i>
              Sim, prosseguir
            </button>
          </div>

        </div>
      </app-card>

    </div>
  </app-ui-modal>

  <app-ui-modal #modalInsercaochave [hideFooter]="true" [hideHeader]="true"
    [dialogClass]="'modal-md'"
    [containerClick]="false" class="modalConstrucaoTestamento">

    <div class="app-modal-body" style="margin-top: 27px;">
      <a href="javascript:void" class="botaoFecharModal"
        (click)="util.closeModal(modalInsercaochave); chaveDeAcessoEdicao='';">
        X
      </a>
      <app-card [customHeader]="false" [options]="false"
        blockClass="pt-0 users-contact">
        <div class="imgChaveModal text-center">
          <div class="row align-items-end">
            <div class="col"></div>
            <div class="col">
              <div class="position-relative d-inline-block">
                <img class=" " src="assets/images/img-chave-gerada.png"
                  alt="User image" width="230">
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="text-center">
          <br> <br> <br> <br> <br> <br>
          <h3 class="mb-1 mt-3">Editar Testamento</h3>

          <p class="msgChave"
            *ngIf="!chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
            Por favor, entre com a chave de Acesso do Testamento:
          </p>

          <div class="col-md-12"
            *ngIf="!chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
            <br>
            <label>CHAVE DE ACESSO</label>
            <input type="text"
              class="form-control fundoInput chaveAcesso text-center"
              [(ngModel)]="chaveDeAcessoEdicao">
          </div>

        </div>
        <hr class="wid-80 b-wid-3 my-4">
        <div class="row text-center">

          <div class="col-md-12 mt-4 mb-2" align="center">
            <button type="button" class="btn button-rounded col-md-6"
              style="margin: 0 auto;"
              (click)="editarTestamento()">
              <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              Editar
            </button>
          </div>

        </div>
      </app-card>

    </div>
  </app-ui-modal>

  <app-ui-modal #modalchave [hideFooter]="true" [hideHeader]="true"
    [dialogClass]="'modal-md'" [containerClick]="false"
    class="modalConstrucaoTestamento">

    <div class="app-modal-body" style="margin-top: 27px;">

      <app-card [customHeader]="false" [options]="false"
        blockClass="pt-0 users-contact">
        <div class="imgChaveModal text-center">
          <div class="row align-items-end">
            <div class="col"></div>
            <div class="col">
              <div class="position-relative d-inline-block">
                <img class=" " src="assets/images/img-chave-gerada.png"
                  alt="User image" width="230">
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="text-center">
          <br> <br> <br> <br> <br> <br>
          <h3 class="mb-1 mt-3">Parabéns!</h3>

          <p class="msgChave"
            *ngIf="!chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
            O TESTAMENTO DIGITAL foi criptografado.
            Guarde sua Chave de Acesso, mas não se preocupe,
            a chave será
            enviada por e-mail.
          </p>

          <p class="msgChave"
            *ngIf="chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
            {{mensagem}}}
          </p>

          <div class="col-md-12"
            *ngIf="!chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
            <br>
            <label>CHAVE DE ACESSO</label>
            <input type="text"
              class="form-control fundoInput chaveAcesso text-center"
              [(ngModel)]="chave.chave"
              readonly="true">
          </div>

        </div>
        <hr class="wid-80 b-wid-3 my-4">
        <div class="row text-center">

          <div class="col-md-12 mt-4 mb-2" align="center">
            <button type="button" class="btn button-rounded col-md-6"
              style="margin: 0 auto;"
              (click)="irParaGerenciarTestamentos()">
              <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              Fechar
            </button>
          </div>

        </div>
      </app-card>

    </div>
  </app-ui-modal>
<!-- ---------  modal plano antecipado ---------  -->
<!-- <app-ui-modal #modalPlanoAntecipado [hideFooter]="true" [dialogClass]="'modal-md'" *ngIf="toDisplayModalPagAntecipado" style="display: flex; justify-content: center;"> -->
  <!-- <div class="basic modal fade in modalPagamento" *ngIf="toDisplayModalPagAntecipado" style="display: flex;">

    <div class="modalPagAntecipado">
      <app-pagamento class="planoAntecipado"></app-pagamento>
      <div class="col-sm-12 col-md-8 col-lg-6 gridBtnPlanoAntecipado">
        <button type="button" class="btn button-rounded color-1 col-md-10" style="margin: 0 auto;" data-dismiss="modal"
          aria-label="Close" (click)="toggleDataModalPagAntecipado()"><i class="close basic-close"></i>
          Não escolher agora
        </button>
      </div>
    </div>
  </div> -->
<!-- </app-ui-modal> -->

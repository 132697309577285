<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<app-ui-modal #modalSinistro [hideFooter]="true" [dialogClass]="'modal-md'" class="modalComunicarSinistro">

  <div class="app-modal-header">
    <h5 class="modal-title titulo">COMUNICAR SINISTRO</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="util.closeModal(modalSinistro)"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12 px-0">
        <input type="text" class="form-control fundoInput" [(ngModel)]="chaveAcesso" name="chaveAcesso"
          placeholder="Chave do Testamento">
      </div>
      <div class="col-md-12 px-0">
        <input type="text" class="form-control fundoInput" [(ngModel)]="chaveAcessoExecutor" name="chaveAcesso"
          placeholder="Chave de acesso do Executor">
      </div>


      <div class="col-md-12 px-0 align-items-center mt-3 mb-4">

        <p class="mb-3 text-muted"><strong>Motivo: </strong></p>
        <div class="col-md-12 mb-2">
          <input class="form-check-input" type="checkbox" id="gridRadios1" value="dff963a1-cbc8-4e70-8a75-248a0a221dc0"
            (click)="selecionarMotivo($event)">
          <label class="form-check-label" for="gridRadios1">MORTE</label>
        </div>
        <div class="col-md-12 mb-2">
          <input class="form-check-input" type="checkbox" id="gridRadios2" name="gridRadios"
            value="dff963a1-cbc8-4e70-8a75-248a0a221dc1" (click)="selecionarMotivo($event)">
          <label class="form-check-label" for="gridRadios2">ACIDENTE QUE IMPOSSIBILITE A MANIFESTAÇÃO DE VONTADE POR
            MAIS DE 48 HORAS</label>
        </div>
        <div class="col-md-12 mb-2">
          <input class="form-check-input" type="checkbox" id="gridRadios3" name="gridRadios"
            value="dff963a1-cbc8-4e70-8a75-248a0a221dc2" (click)="selecionarMotivo($event)">
          <label class="form-check-label" for="gridRadios3">DESAPARECIMENTO DO USUÁRIO POR MAIS DE 15 DIAS</label>
        </div>
        <!-- </ul> -->
      </div>

      <div class="col-md-12 px-0 mb-2" align="center">
        <button class="btn button-rounded col-md-6" style="margin: 0 auto;" (click)="comunicarSinistro()">
          <i class="feather icon-edit"></i>
          COMUNICAR</button>
      </div>


    </div>

  </div>
</app-ui-modal>


<div class="divSinistroComunicado" *ngIf="beneficiariosSinistroAberto">
  <div (click)="esconderModalSinistroComunicado(); util.closeModal(modalSinistro); "
    class="divBtnFecharSinistroComunicado">x</div>
  <div class="divSinistroComunicadoTopo">
    <h3>A Chave de Acesso foi confirmada!</h3>
    <p class="textoSinistro">
      Prezado(a) Executor(a),
      Lamentamos sua perda e a dor que está sentindo. Informamos que os demais Executores foram notificados do sinistro
      e um e-mail de alerta de segurança foi encaminhado ao Usuário. Caso nenhuma das partes se manifeste em 24 horas, o
      link para download do Testamento Digital será encaminhado ao(s) Beneficiário(s).

    </p>

  </div>
  <div class="pessoasAbracando">
    <img src="assets/images/pessoasAbracando.png" />
  </div>

  <div class="divSinistroComunicadoRodape">

    <div class="divBeneficiarioSinistro" *ngFor="let item of beneficiariosSinistroAberto">
      <div class="divBeneficiarioSinistroImagem">
        <img width="60" src="assets/images/imgPessoaAnonima.png" />
      </div>
      <div class="divBeneficiarioSinistroTexto">
        <div class="textoNomeBeneficiario">{{item.nome}}</div>
        <div class="textoBeneficiario">
          <div class="textoBeneficiarioTitulo">CPF: </div>
          <div>{{item.cpf}}</div>
        </div>
        <div class="textoBeneficiario">
          <div class="textoBeneficiarioTitulo">E-MAIL: </div>
          <div>{{item.email}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <img src="assets/images/auth/logo.png" alt="" class="img-fluid logoPrincipal">
        <br><br><br><br><br><br>
        <h4 class="text-white font-weight-normal"
          style="font-size:30px; font-family: 'Roboto Condensed'; font-weight: 100 !important;">Crie um testamento digital em 5 etapas.</h4>
        <h1 class="tituloLogin text-white my-4">
          Resguarde os interesses<br>de seus clientes com <br>praticidade e segurança.</h1>
        <br>
        <button class="btn button-rounded mb-0"
          style="padding-left: 10% ; padding-right: 10%; display:none !important;" (click)="this.util.notImplement()">
          <strong>VER VÍDEO DE DEMONSTRAÇÃO</strong> </button>
      </div>

    </div>
    <div class="auth-side-form">
      <div class=" auth-content" *ngIf="!exibirRecuperacaoDeSenha">
        <img src="assets/images/logo-azul-2.png" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none logoMobile">
        <h3 class="mb-4 f-w-400 text-blue text-uppercase">Login</h3>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-user"></i></span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="email" name="email" placeholder="E-mail">
        </div>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-lock"></i></span>
          </div>
          <input type="password" class="form-control" [(ngModel)]="senha" name="senha" placeholder="Senha">
        </div>
          <div class="col-md-12 mb-2 p-0" align="center">
            <button class="btn button-rounded w-100" (click)="login()">
              Acessar
            </button>
          </div>
        <div class="text-center">
          <div class="saprator my-4"></div>
          <br><br>
          <p class="text-muted">Não tem uma conta ainda?</p>
              <a class="btn btnBordasRed" [routerLink]="['/cadastro-usuario']">Cadastrar</a>

          <p class="text-muted">Você é um Executor?</p>
             <a class="btn btnBordasBlue" (click)="this.util.openModal(modalSinistro)">Comunicar sinistro e chave de acesso</a>

          <p class="text-muted">Esqueceu sua senha? <br><a class="f-w-400 link-blue"
              (click)="mostrarRecuperacaoDeSenha()">Clique aqui para recuperá-la</a></p>
        </div>
      </div>

      <div class="auth-content" *ngIf="exibirRecuperacaoDeSenha">
        <img src="assets/images/logo-azul-2.png" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none logoMobile">
        
        <h3 class="text-center text-uppercase mb-4 f-w-400 link-blue">Recuperar Senha</h3>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-user"></i></span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="recuperarCpf" name="recuperarCpf" maxlength="11"
            placeholder="Cpf">
        </div>
        <button class="btn button-rounded col-md-8 col-sm-12" style="margin: 0 auto;" (click)="enviarEmailRecuperacaoDeSenha()">
          <strong>Enviar</strong> </button>
        <div class="text-center">
          <div class="saprator my-4"></div>
          <br><br>
          <p class="mb-0"> <br><a class="f-w-400 link-blue"
              (click)="mostrarTelaDeLogin()">Voltar</a></p>
        </div>
      </div>

    </div>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { typeSourceSpan } from '@angular/compiler';
import { Component, OnInit, TemplateRef, ViewChild,ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from 'ng-select';
import { Cartao, CartaoCredito, EnderecoCobranca } from 'src/app/model/CartaoCredito';
import { Cotacao } from 'src/app/model/Cotacao';
import { Plano, Planos } from 'src/app/model/Planos';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { API, maskAno, maskCEP, maskCPF, maskCVV, maskMes, maskNumeroCartao,maskOnlyNumbers } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MENSAGEM } from '../../../environments/environment';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent implements OnInit {


  tipoPagamento = {
    credito:'bff963a1-cbc8-4e70-8a75-248a0a221dc0',
    debito:'bff963a1-cbc8-4e70-8a75-248a0a221dc1',
    pix:'bff963a1-cbc8-4e70-8a75-248a0a221dc2'
  };

  //@ViewChild('modalchave') private modalchave: any
  //private modalRef: NgbModalRef;
  @ViewChild('btnExibirModal')
   btnExibirModal: ElementRef;

   @ViewChild('selectEstado')
   selectEstado : SelectComponent;

   public cepPreenchido:boolean;
  public maskCEP = maskCEP;
  public maskCPF = maskCPF;
  public maskMes = maskMes;
  public maskAno = maskAno;
  public maskCVV = maskCVV;
  public maskOnlyNumbers = maskOnlyNumbers;
  public maskNumeroCartao = maskNumeroCartao;
  public chave: any;
  public cotacaoComCupomRealizada:boolean =false;
  public mensagemPagamento ="";
  public cupom100 = false;
 public idAdministradorEmpresa;
  public maximoQuantidadeParcelas = 7;
  public mensagemPagamentoProcessando = "Processando ...";
  public mensagemPagamentoJaEfetuado = "Processando ...";
  mensagem: string;

  originalArrayPais: any[];
  originalArrayEstado: any[];
  originalArrayCidade: any[];

  arrayPais: any[];
  arrayEstado: any[];
  arrayCidade: any[];
  paisSelecionado: any;
  estadoSelecionado;
  cidadeSelecionado: string;
  isConstruir:boolean=true;

  arrayPlanos: Planos;
  cotacao: Cotacao;
  controlaDiv: any;
  planoSelecionado: Plano;
  cartaoCredito: CartaoCredito;
  objValidarTestamento: any;
  cupomDesconto: string;
  idTipoPagamento:string=this.tipoPagamento.credito;
  quantidadeParcelas:number=1;
  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
    private modalService: NgbModal,
    private http: HttpClient,
    private route: ActivatedRoute

  ) {
    debugger;
    this.idAdministradorEmpresa = this.auth.getUser()?.user?.idUsuarioAdministradorEmpresa;

    this.route.queryParams
    .filter(params => params.construir)
    .subscribe(params => {
      this.isConstruir = params.construir && params.construir != 'false';
    });

    this.buscarTestamento();
    this.inicializar();
  }

 async inicializar() {
  debugger;
  this.mensagemPagamento = this.mensagemPagamentoProcessando;
    this.controlaDivs('esconderTodos');
    this.cotacao = null;
    this.cupomDesconto = "";
    this.cartaoCredito = new CartaoCredito();
    this.cartaoCredito.cartao = new Cartao();
    this.cartaoCredito.cartao.enderecoCobranca = new EnderecoCobranca();
    this.cartaoCredito.cartao.enderecoCobranca.codPaisTelefone = "55";
    this.validacaoTestamento();
    this.mensagem =  "";
    this.chave = {"chave": ""};
    await this.verificarSeEstaPago();
    this.buscarPais();


    //this.mensagem =  "8f385b85-615b-4442-9fec-0cdfb34230d4";
    //this.chave = {"chave": "8f385b85-615b-4442-9fec-0cdfb34230d4"};
    //this.controlaDivs('download');
  }


  async verificarSeEstaPago(){
    //ToDo - Pagamento
    let estaPago = false;
    let url = "";

    if( this.idAdministradorEmpresa )
      url =  API + "Financeiro/Pagamento/Testamento/" +  this.idAdministradorEmpresa  + "/EstaPagoUsuarioEmpresa";
    else
     url = API + "Financeiro/Pagamento/Testamento/" + this.auth.testamentoAtivo.guid + "/EstaPago";

    await this.ajax.getAsync(url,  response => {
      estaPago = response.pago;

      if(estaPago){
        this.mensagemPagamento = this.mensagemPagamentoJaEfetuado;
        this.controlaDivs('processandoPagamento');
      }else{

        let url = API + "TestamentoDigital/Testamento/DadosTestador";
          this.ajax.getAsync(url,response => {
            if (response.data.length > 0) {
              let dados = response.data[0];
              if(dados != null && dados.telefone){
                try{
                this.cartaoCredito.cartao.enderecoCobranca.telefone = dados.telefone.substring(2,dados.telefone.length)
                }catch(e){

                }
              }
            }
          });

        this.controlaDivs('plano');
      }
    });

    if(this.isConstruir){
    if(estaPago)
      await this.buildTestamento(this.auth.testamentoAtivo.guid);
    }

    return estaPago;
  }

  ngOnInit() {
    this.paisSelecionado = 1;
  }

  selecionaCidade(cidade){
    let objCidade = this.originalArrayCidade.find(c => c.id == cidade);
    this.cartaoCredito.cartao.enderecoCobranca.cidade = objCidade.nome;
    this.buscaCep();
  }

  buscaCidade(estado){
    let objEstado = this.originalArrayEstado.find(c => c.id == estado);
    this.cartaoCredito.cartao.enderecoCobranca.estado = objEstado.uf;
    this.util.showLoading();
    let url = API + "logradouro/cidade?idEstado="+estado;
    return this.ajax.get(url)
      .subscribe(response => {
        this.originalArrayCidade = response;
        this.arrayCidade = this.util.convertIoptions(response, "id", "nome");
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Cidades");
      });
  }

  buscaEstados(pais){
    let objPais = this.originalArrayPais.find(c => c.id == pais);
    this.cartaoCredito.cartao.enderecoCobranca.pais = objPais.sigla;
    this.util.showLoading();
    let url = API + "logradouro/estado?idPais="+pais;
    return this.ajax.get(url)
      .subscribe(response => {
        this.originalArrayEstado = response
        this.arrayEstado = this.util.convertIoptions(response, "id", "nome");
        this.cartaoCredito.cartao.enderecoCobranca.estado = this.originalArrayEstado.find(f => f.id==5).uf;
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Estados");
      });
  }

  buscarPais() {
    debugger;
    this.util.showLoading();
    let url = API + "logradouro/pais";
    return this.ajax.get(url)
      .subscribe(response => {
        this.originalArrayPais = response;
        this.arrayPais = this.util.convertIoptions(response, "id", "nomePt");

        this.paisSelecionado = [1];
        this.buscaEstados(1);
        this.util.closeLoading();

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar País");
      });
  }



  buscaCep(){

    if(this.cartaoCredito.cartao.enderecoCobranca.cep){
    let url = "https://viacep.com.br/ws/"+this.util.removeMask(this.cartaoCredito.cartao.enderecoCobranca.cep)+"/json";
    this.util.showLoading();
    this.http.get<any>(url)
      .subscribe(response => {
        this.cepPreenchido=true;
        this.util.closeLoading();
        if (response.erro == undefined) {
          this.cartaoCredito.cartao.enderecoCobranca.rua = response.logradouro;
          //this.cartaoCredito.cartao.enderecoCobranca.complemento = response.complemento;
          this.cartaoCredito.cartao.enderecoCobranca.bairro = response.bairro;
          //this.cartaoCredito.cartao.enderecoCobranca.cidade = response.localidade;
          //this.cartaoCredito.cartao.enderecoCobranca.estado = response.uf;
          this.cartaoCredito.cartao.enderecoCobranca.dDDTelefone = response.ddd;
          var estado = this.originalArrayEstado.find(w => w.uf.toUpperCase() == response.uf.toUpperCase() );
          //this.selectEstado.select(;
          this.estadoSelecionado = estado.id;
        }
      }, err => {
        this.cepPreenchido=true;
        this.util.closeLoading();
      });

    }
    else{
      this.cepPreenchido=true;
    }
  }

  validacaoTestamento() {

if(this.isConstruir){

    this.auth.getTestamento();
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + this.auth.testamentoAtivo.guid + "/Validar";
    return this.ajax.get(url)
      .subscribe(response => {
        this.objValidarTestamento = response;

        if (this.objValidarTestamento.status.testamentoPago) {
         // this.router.navigate(['/construir-testamento']);
        }
        this.util.closeLoading();
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
    }
  }

  buscarTestamento() {
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        debugger;
        if (response.testamento != null) {
          this.auth.setTestamento(response.testamento);
          this.auth.getTestamento();
          this.buscarPlanos();
        } else {
          if( this.idAdministradorEmpresa ){
            this.buscarPlanos();
          }
          else{
          this.util.toasterShowError("Atenção", "É necessário terminar o preenchimento do testamento");
          }
        }
        this.util.closeLoading();

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  buscarPlanos() {
    this.util.showLoading();
    let url = API + "Financeiro/Plano";
    return this.ajax.get(url)
      .subscribe((response: Planos) => {

        this.arrayPlanos = response
        this.util.closeLoading();

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Planos");
      });
  }

  escolherPlano(plano: Plano) {
    this.planoSelecionado = plano;
    this.controlaDivs('cotacao');
    this.buscarCotacao();

  }

  aplicarCupom() {
    this.buscarCotacao();
  }

  controlaDivs(plano: string) {
    switch (plano) {
      case 'plano':
        this.controlaDiv = { plano: true, cotacao: false, pagamento: false, download: false , processandoPagamento:false};
        break;
      case 'cotacao':
        this.controlaDiv = { plano: false, cotacao: true, pagamento: false, download: false  , processandoPagamento:false};
        break;
      case 'pagamento':
        this.controlaDiv = { plano: false, cotacao: false, pagamento: true, download: false  , processandoPagamento:false};
        break;
      case 'download':
        this.controlaDiv = { plano: false, cotacao: false, pagamento: false, download: true  , processandoPagamento:false};
        break;
      case 'processandoPagamento':
          this.controlaDiv = { plano: false, cotacao: false, pagamento: false, download: false  , processandoPagamento:true};
      break;
      case 'esconderTodos':
        this.controlaDiv = { plano: false, cotacao: false, pagamento: false, download: false  , processandoPagamento:false};
      break;
    }
  }

  buscarCotacao() {
    this.auth.getTestamento();
    this.util.showLoading();
    let url = '';
    let dados = {
      "idValorPlano": this.planoSelecionado.idValorPlano,
      "cupomDesconto": this.cupomDesconto};

    if( this.idAdministradorEmpresa ){
      dados["IdUsuarioEmpresa"] =  this.idAdministradorEmpresa ;
      url = API + "Financeiro/Cotacao/Testamento/CotarEmpresa";
    }
    else{
      var idTestamento =  this.auth?.testamentoAtivo?.guid;
      if(idTestamento)
        dados["IdTestamento"] = idTestamento;

     url = API + "Financeiro/Cotacao/Testamento/" + this.auth.testamentoAtivo.guid + "/Cotar";
    }

    return this.ajax.post(url, dados)
      .subscribe((response: any) => {

        if(this.cupomDesconto)
          this.cotacaoComCupomRealizada=true;

        this.cotacao = response.cotacao

        if(   this.cotacao
          && !this.cotacao.totalPedido
          &&  this.cotacao?.cupom
          &&  this.cotacao.cupom.status==1 ){
              this.cupom100 = true;
          }


        this.util.closeLoading();

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  selecionarCartao() {
    this.controlaDivs('pagamento');
  }

  validarDadosCartao(): boolean {

  if(this.cupom100)
      return true;

    if (this.cartaoCredito.cartao.nomeProprietario == undefined || this.cartaoCredito.cartao.cpf == "") {
      this.util.toasterShowError("Atenção", "O Campo Nome Impresso no Cartão não pode ser Vazio!");
      return false;
    }

    if (this.cartaoCredito.cartao.cpf == undefined || this.util.removeMask(this.cartaoCredito.cartao.cpf) == "" || this.util.removeMask(this.cartaoCredito.cartao.cpf).length < 11) {
      this.util.toasterShowError("Atenção", "O Campo CPF não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.cartaoCredito.cartao.numeroCartao == undefined || this.util.removeMask(this.cartaoCredito.cartao.numeroCartao) == "" || this.util.removeMask(this.cartaoCredito.cartao.numeroCartao).length < 16) {
      this.util.toasterShowError("Atenção", "O Campo Numero Cartão não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.cartaoCredito.cartao.mesVencimento == undefined || this.util.removeMask(this.cartaoCredito.cartao.mesVencimento) == "" || this.util.removeMask(this.cartaoCredito.cartao.mesVencimento).length < 2) {
      this.util.toasterShowError("Atenção", "O Campo Mês não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.cartaoCredito.cartao.anoVencimento == undefined || this.util.removeMask(this.cartaoCredito.cartao.anoVencimento) == "" || this.util.removeMask(this.cartaoCredito.cartao.anoVencimento).length < 2) {
      this.util.toasterShowError("Atenção", "O Campo Ano não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.cartaoCredito.cartao.codigoVerificador == undefined || this.util.removeMask(this.cartaoCredito.cartao.codigoVerificador) == "" || this.util.removeMask(this.cartaoCredito.cartao.codigoVerificador).length < 3) {
      this.util.toasterShowError("Atenção", "O Campo CVV não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (!this.cartaoCredito.cartao.enderecoCobranca.cep) {
      this.util.toasterShowError("Atenção", "O Campo Cep do Endereço de Cobrança não pode ser Vazio!");
      return false;
    }

    if (!this.cartaoCredito.cartao.enderecoCobranca.cidade) {
      this.util.toasterShowError("Atenção", "O Campo Cidade do Endereço de Cobrança não pode ser Vazio!");
      return false;
    }

    if (!this.cartaoCredito.cartao.enderecoCobranca.estado) {
      this.util.toasterShowError("Atenção", "O Campo Estado do Endereço de Cobrança não pode ser Vazio!");
      return false;
    }

    if (!this.cartaoCredito.cartao.enderecoCobranca.rua) {
      this.util.toasterShowError("Atenção", "O Campo Rua do Endereço de Cobrança não pode ser Vazio!");
      return false;
    }

    if (!this.cartaoCredito.cartao.enderecoCobranca.bairro) {
      this.util.toasterShowError("Atenção", "O Campo Bairro do Endereço de Cobrança não pode ser Vazio!");
      return false;
    }

    if (!this.cartaoCredito.cartao.enderecoCobranca.numero) {
      this.util.toasterShowError("Atenção", "O Campo Número do Endereço de Cobrança não pode ser Vazio!");
      return false;
    }


    if (!this.cartaoCredito.cartao.enderecoCobranca.telefone) {
      this.util.toasterShowError("Atenção", "O Campo Número do Telefone não pode ser Vazio!");
      return false;
    }


    if (!this.cartaoCredito.cartao.enderecoCobranca.dDDTelefone) {
      this.util.toasterShowError("Atenção", "O Campo DDD do Telefone não pode ser Vazio!");
      return false;
    }


    if (!this.cartaoCredito.cartao.enderecoCobranca.codPaisTelefone) {
      this.util.toasterShowError("Atenção", "O Campo Cód País do Telefone não pode ser Vazio!");
      return false;
    }

    if(this.idTipoPagamento != this.tipoPagamento.credito || this.quantidadeParcelas <1)
    {
      this.quantidadeParcelas=1;
    }

    if(this.quantidadeParcelas > this.maximoQuantidadeParcelas)
    {
      this.quantidadeParcelas=this.maximoQuantidadeParcelas;
    }

    return true;
   }

  async salvarDadosCartao(): Promise<void> {
    let dados = this.cartaoCredito;

    dados.idValorPlano = this.planoSelecionado.idValorPlano;
    dados.quantidadeParcelas = this.quantidadeParcelas;
    dados.cupomDesconto = this.cupomDesconto;
    dados.idTipoPagamento = this.idTipoPagamento;
    let pago = false;

    if (this.validarDadosCartao()) {

      this.util.showLoading();
      let url = '';

      if( this.idAdministradorEmpresa ){
        url =  API + "Financeiro/Pagamento/Testamento/" +  this.idAdministradorEmpresa  + "/PagarEmpresa";
      }
      else{
        dados.idTestamento = this.auth.testamentoAtivo.guid;
         url = API + "Financeiro/Pagamento/Testamento/" + this.auth.testamentoAtivo.guid + "/Pagar";
      }

      return await this.ajax.postAsync(url, dados, response => {

        if (response.pago) {
          this.controlaDivs('processandoPagamento');
          pago=true;
          this.buildTestamento(dados.idTestamento);
        } else {
          this.util.toasterShowError("Atenção", "Erro ao processar Cartão");
        }
      });
    }


  }

  async buildTestamento(guidTestamento) : Promise<void> {

    if(!this.isConstruir){
      this.router.navigate(['/gerenciar-testamento']);
    }
    else{

    var that = this;
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/" + guidTestamento + "/Construir";
    await this.ajax.postAsync(url, null,(response: any) => {
        that.controlaDivs('download');
        that.util.closeLoading();
        let chave = response.dados;
        that.chave = chave;

        if (chave.seraConstruidoDepoisDasAssinaturasNoClickSign) {

          if (!that.auth.testamentoAtivo.isTestamentoComBens || !that.auth.testamentoAtivo.isAssinaturaDigital) {
            that.mensagem = MENSAGEM.construcaoExecutoresAceitarTermo;
          } else {
            that.mensagem = MENSAGEM.construcaoTestemunhasAssinarem;
          }

        }

        that.btnExibirModal.nativeElement.click();
      }, (erro) =>{

        let sufixo= '<br /><br /><b>Favor reparar os erros apontados em seu testamento e clicar novamente em Validar/Concluir.</n>';
        let errors ='';
        if(erro){

        for(var i=0; i< erro.length; i++)
        {
          errors +=erro[i];
        }
      }

      let message = `<p style='text-align:justify'>${errors}${sufixo}</p>`;

      Swal.fire({
        title: 'Atenção',
        html: message,
        confirmButtonText: 'Ok',
      }).then((result) => {
          this.router.navigate(['/home']);
      });
  });
}
  }



  irParaGerenciarTestamentos() {
    this.router.navigate(['/gerenciar-testamento']);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { RedefinirSenhaComponent } from './page/redefinirSenha/redefinirSenha.component';


import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { HomeComponent } from './page/home/home.component';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { PagamentoComponent } from './page/pagamento/pagamento.component';
import { ConstruirTestamentoComponent } from './page/construir-testamento/construir-testamento.component';
import { GerenciarTestamentoComponent } from './page/gerenciar-testamento/gerenciar-testamento.component';
import { AcompanharAssinaturasComponent } from './page/acompanhar-assinaturas/acompanhar-assinaturas.component';
import { AceiteExecutorComponent } from './page/aceite-executor/aceite-executor.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
     , { path: 'construir-testamento/:guid', component: ConstruirTestamentoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'gerenciar-testamento', component: GerenciarTestamentoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'acompanhar-assinaturas/:guid', component: AcompanharAssinaturasComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'home', component: HomeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'pagamento', component: PagamentoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
    ]
  }
  ,
  {
    path: '',
    component: AuthComponent,
    children: [
    
      { path: 'login', component: LoginComponent },
      { path: 'redefinir-senha', component: RedefinirSenhaComponent },
      { path: 'cadastro-usuario', component: CadastroUsuarioComponent } ,
      { path: 'aceite-executor', component: AceiteExecutorComponent },
      { path: '**', redirectTo: '/login' },
    ]
  }/*,
  {
    path: '',
    component: AuthComponent,
    children: []
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecretToken } from 'src/environments/environment';
import { GeoLocation } from '../model/geoLocation';
import { AuthService } from './auth.service';
import { UtilService } from './util.service';
import Swal from 'sweetalert2';
import { API } from 'src/environments/environment';
//headers_object.append("Authorization", "Bearer " + t);

export const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'LumagoKey': SecretToken,
    'Access-Control-Allow-Credentials': 'false',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  })
};

@Injectable({
  providedIn: 'root'
})



export class AjaxService {

  public headers: any;
  private options: any;
  private userLang: string;


  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public util: UtilService
  ) {
    this.userLang = this.GetLanguage();

  }

  GetLanguage() {
    var lang = window.navigator.language;

    if (lang) {
      lang = lang.toLowerCase();
      return lang.startsWith('pt')
        ? 'pt-br'
        : 'en-us'
    }
    else return 'pt-br';

  }

  post(url: string, data: any): any {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers
      .set('Content-Language', this.userLang)
      .set('LumagoKey', SecretToken)
      .set('timeout','600000')

    if (this.auth.user != null)
      this.headers = HTTP_OPTIONS.headers
        .set('Content-Language', this.userLang)
        .set('LumagoKey', SecretToken)
        .set('timeout','600000')
        .set('Authorization', "bearer " + this.auth.user.token);

    this.options = {
      headers: this.headers
    };

    return this.http.post<any>(url, data, this.options);
  }


  postFile(url: string, data: any): any {
    this.auth.getUser();
    this.options = {
      headers: new HttpHeaders({ 'SecretToken': SecretToken })
        .set('Authorization', "bearer " + this.auth.user.token)
        .set('timeout','600000')
    };

    return this.http.post<any>(url, data, this.options);
  }

  putFile(url: string, data: any): any {

    this.auth.getUser();
    this.options = {
      headers: new HttpHeaders({ 'SecretToken': SecretToken })
        .set('Authorization', "bearer " + this.auth.user.token)
        .set('timeout','600000')
    };

    return this.http.put<any>(url, data, this.options);
  }


  put(url: string, data: any): any {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers.set('LumagoKey', this.userLang)
      .set('Authorization', "bearer " + this.auth.user.token)
      .set('timeout','600000')

    this.options = {
      headers: this.headers
    };

    return this.http.put<any>(url, data, this.options);
  }

  patch(url: string, data: any): any {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers.set('LumagoKey', this.userLang)
      .set('Authorization', "bearer " + this.auth.user.token)
      .set('timeout','600000')

    this.options = {
      headers: this.headers
    };

    return this.http.patch<any>(url, data, this.options);
  }

  get(url: string): any {

    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers
      .set('Content-Language', this.userLang)
      .set('timeout','600000')
      .set('LumagoKey', SecretToken);

    if (this.auth.user != null)
      this.headers = HTTP_OPTIONS.headers
        .set('Content-Language', this.userLang)
        .set('LumagoKey', SecretToken)
        .set('timeout','600000')
        .set('Authorization', "bearer " + this.auth.user.token);

    this.options = {
      headers: this.headers
    };

    return this.http.get<any>(url, this.options);
  }

  async getAsync(url: string, successCallback: any,erroCallBack:any=null): Promise<any> {

    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers
      .set('Content-Language', this.userLang)
      .set('LumagoKey', SecretToken)
      .set('timeout','600000');

    if (this.auth.user != null)
      this.headers = HTTP_OPTIONS.headers
        .set('Content-Language', this.userLang)
        .set('LumagoKey', SecretToken)
        .set('timeout','600000')
        .set('Authorization', "bearer " + this.auth.user.token);

    this.options = {
      headers: this.headers
    };

    this.util.showLoading();

    return this.http
      .get<any>(url, this.options)
      .toPromise()
      .then(resp => {
        successCallback(resp);
        this.util.closeLoading();
      }).catch(err =>{ this.catchError(err,erroCallBack);
        this.util.closeLoading();});
  }

  async postAsync(url: string, data: any, successCallback: any,erroCallBack:any=null, isAsync:boolean =false): Promise<any> {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers
      .set('Content-Language', this.userLang)
      .set('LumagoKey', SecretToken)
      .set('timeout','600000')


    if (this.auth.user != null)
      this.headers = HTTP_OPTIONS.headers
        .set('Content-Language', this.userLang)
        .set('LumagoKey', SecretToken)
        .set('timeout','600000')
        .set('Authorization', "bearer " + this.auth.user.token);

    this.options = {
      headers: this.headers
    };

    this.util.showLoading();
    var response = null;

    return await this.http.post<any>(url, data, this.options)
      .toPromise()
      .then(async resp => {
        response =resp;

         if(isAsync || successCallback.name && successCallback.name.endsWith('Async'))
            await successCallback(resp);
        else
           successCallback(resp);

           this.util.closeLoading();
      }).catch(err =>{

        let errorMess = this.catchError(err,erroCallBack);
         this.util.closeLoading();

          if(erroCallBack)
            erroCallBack(errorMess,response);
        });
  }


  async postFileAsync(url: string, data: any, successCallback: any): Promise<any> {
    this.auth.getUser();
    this.options = {
      headers: new HttpHeaders({ 'SecretToken': SecretToken })
      .set('Authorization', "bearer " + this.auth.user.token)
      .set('timeout','600000')
    };

    this.util.showLoading();

    return await this.http.post<any>(url, data, this.options).toPromise()
      .then(resp => {
        successCallback(resp);
        this.util.closeLoading();
      }).catch(err =>{ this.catchError(err); this.util.closeLoading();});
  }


  async putAsync(url: string, data: any, successCallback: any): Promise<any> {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers.set('LumagoKey', this.userLang)
      .set('Authorization', "bearer " + this.auth.user.token)
      .set('timeout','600000')

    this.options = {
      headers: this.headers
    };

    this.util.showLoading();

    return this.http.put<any>(url, data, this.options).toPromise()
      .then(resp => {
        successCallback(resp);
        this.util.closeLoading();
      }).catch(err =>{ this.catchError(err); this.util.closeLoading();});
  }

  async patchAsync(url: string, data: any, successCallback: any): Promise<any> {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers.set('LumagoKey', this.userLang)
      .set('Authorization', "bearer " + this.auth.user.token)

    this.options = {
      headers: this.headers
    };

    this.util.showLoading();

    return this.http.patch<any>(url, data, this.options).toPromise()
      .then(resp => {
        successCallback(resp);
        this.util.closeLoading();
      }).catch(err => this.catchError(err));;
  }

  async deleteAsync(url: string, successCallback: any): Promise<any> {
    this.auth.getUser();
    this.headers = HTTP_OPTIONS.headers.set('LumagoKey', this.userLang)
      .set('Authorization', "bearer " + this.auth.user.token)

    this.options = {
      headers: this.headers
    };

    this.util.showLoading();

    return this.http.delete<any>(url, this.options).toPromise()
      .then(resp => {
        successCallback(resp);
        this.util.closeLoading();
      }).catch(err =>{ this.catchError(err); this.util.closeLoading();});
  }



  catchError(err: any, errorCallback:any=null, errorText:any = null) {
    if(!err)
      err = {error:"Erro" };

    let errorMessage = '';

    if(errorText)
      errorMessage = errorText;
    else
    {
      try{
      errorMessage=  err.error.error ? err.error.text : err.error;
      }catch(errorEx){
        errorMessage = err && err.message ? err.message : 'Erro';
      }
    }

    if(!errorCallback){

      var message ='';

      if(errorMessage && errorMessage.length)
        for(var i=0; i< errorMessage.length; i++)
          message += errorMessage[i];
       else
         message += errorMessage;

         if(message == "Você não tem permissão para acessar este recurso")
         {
           Swal.fire('Atenção',"Você não tem permissão para acessar este recurso, favor efetuar login novamente!");
           setTimeout(function(){
            location.href='/login'
           },2000);
         }
         else
          Swal.fire('Atenção',message);
    }

    this.util.closeLoading();
    return errorMessage;
  }


  getGeoLocation(url: string, location: GeoLocation): any {

    this.headers = HTTP_OPTIONS.headers
      .set('Content-Language', this.userLang)
      .set('x-latitude', location.latitude.toString())
      .set('x-longitude', location.longitude.toString());

    this.options = {
      headers: this.headers
    };

    return this.http.get<any>(url, this.options);
  }
}

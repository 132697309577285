export class User{
    id: string;
    idImutavel: string;
    idCliente: string;
    nome: string;
    nomeSimular:string;
    email: string;
    telefone: string;
    dataNascimento: string;
    cpf: string;
    idExecutor: string;
    isAdvogado : boolean;
    isClienteAcessoTestadorDireto:boolean;
    isAdministradorEmpresa:boolean;
    isTestador:boolean;
    isTestadorClienteAdvogado : boolean;
    idUsuarioEmpresa:string
    idUsuarioAdministradorEmpresa : string
    urlDiretaAcessoCliente : string;
  }


  export class ResponseUser{
    user: User;
    originalUser: User;
    token: string
  }


  export interface jwtDecode{
    aud: string,
    exp: string ,
    iat: boolean,
    iss: any,
    nameid: any,
    nbf: any,
    unique_name:any
  }

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<!-- esse style só serve para ocultar o teclado do simulador de responsivididade do chrome -->
<style>
  span.tooltip-keyboard.b-tooltip.is-dark.is-top.is-medium.is-multiline,
  span.tooltip-keyboard.b-tooltip.is-dark.is-top.is-medium.is-multiline {
    display: none !important;
  }
</style>

<!-- aqui é a tela desktop  -->
<div class="row d-none-sm">
  <div class="col-md-3">

    <div class="nav-pills nav-fill menuTestamentoAbas">
      <li   *ngIf="!isTestadorClienteAdvogado" class="nav-link step-nav"
        [ngClass]="{'step-nav-active': this.abas.cadastroTestador}"
        (click)="selectAba('cadastro-testador')">
        <div class="bolinhas"><span *ngIf="dadosValidados"
            class="badge badge-success"><i
              class="feather icon-check-circle"></i>
          </span></div>
        <div class="bolinhas"><span *ngIf="!dadosValidados"
            class="badge badge-danger"><i
              class="feather icon-slash"></i> </span></div>

        <img src="assets/images/icones-home/icone-cadastro.png" alt="user image"
          class=" align-center m-r-15"
          style="margin-top: -20px;">

        <div class="d-inline-block">
          <h6 class="m-b-0">Cadastro do Testador</h6>
          <p class="m-b-0">Insira os dados do testador</p>
        </div>
      </li>
      <li   *ngIf="!isTestadorClienteAdvogado" class="nav-link step-nav"
        [ngClass]="{'step-nav-active': this.abas.executoresBeneficiarios}"
        (click)="selectAba('excecutores-beneficiarios')">
        <div class="bolinhas">
          <span
            *ngIf="validarModel.Validar?.status.beneficiariosEnviados && validarModel.Validar?.status.executoresEnviados"
            class="badge badge-success"><i
              class="feather icon-check-circle"></i> </span>
        </div>
        <div class="bolinhas">
          <span
            *ngIf="!validarModel.Validar?.status.beneficiariosEnviados || !validarModel.Validar?.status.executoresEnviados"
            class="badge badge-danger"><i class="feather icon-slash"></i>
          </span>
        </div>
        <img src="assets/images/icones-home/icone-executores.png"
          alt="user image" class=" align-center m-r-15"
          style="margin-top:-20px">
        <div class="d-inline-block">
          <h6 class="m-b-0">Executores e Beneficiários</h6>
          <p class="m-b-0">Escolha as Partes Envolvidas</p>
        </div>
      </li>
      <li   *ngIf="!isTestadorClienteAdvogado" class="nav-link step-nav" [ngClass]="{'step-nav-active': this.abas.testamento}" (click)="selectAba('testamento')">
        <div class="bolinhas">
          <span
            *ngIf="((validarModel.Validar?.status.testemunhasEnviadas && validarModel.Andamento?.testamento?.testamentoPrincipal?.corpo != null) || (!testamento.HaveraDisposicaoBens))"
            class="badge badge-success"><i
              class="feather icon-check-circle"></i> </span>
        </div>
        <div class="bolinhas">
          <span
            *ngIf="!validarModel.Validar?.status.testemunhasEnviadas || validarModel.Andamento?.testamento?.testamentoPrincipal?.corpo == null && testamento?.HaveraDisposicaoBens"
            class="badge badge-danger"><i class="feather icon-slash"></i>
          </span>
        </div>
        <img src="assets/images/icones-home/icone-testamento.png"
          alt="user image" class=" align-center m-r-15"
          style="margin-top: -20px;">
        <div class="d-inline-block">
          <h6 class="m-b-0">Testamento Particular</h6>
          <p class="m-b-0">Escreva seu Testamento</p>
        </div>
      </li>
      <li class="nav-link step-nav" [ngClass]="{'step-nav-active': this.abas.anexosAba}" (click)="selectAba('anexos')">
        <div class="bolinhas"><span *ngIf="validarModel.Validar?.status.arquivosEnviados"
            class="badge badge-success"><i
              class="feather icon-check-circle"></i> </span></div>
        <div class="bolinhas"><span
            *ngIf="!validarModel.Validar?.status.arquivosEnviados"
            class="badge badge-danger"><i
              class="feather icon-slash"></i> </span></div>
        <img src="assets/images/icones-home/icone-anexos.png" alt="user image"
          class=" align-center m-r-15"
          style="margin-top: -20px;">
        <div class="d-inline-block">
          <h6 class="m-b-0">Anexos e Senhas</h6>
          <p class="m-b-0">Armazene seus arquivos e senhas</p>
        </div>
      </li>
      <li  *ngIf="!isTestadorClienteAdvogado" class="nav-link step-nav" [ngClass]="{'step-nav-active': this.abas.leituraTestamento}"
        (click)="selectAba('leitura')">
        <div class="bolinhas"><span class="badge badge-success"><i
              class="feather icon-check-circle"></i> </span>
        </div>
        <img src="assets/images/icones-home/icone-leitura.png" alt="user image"
          class=" align-center m-r-15"
          style="margin-top: -20px;">
        <div class="d-inline-block">
          <h6 class="m-b-0">Leitura do Testamento</h6>
          <p class="m-b-0">Ambiente de reunião que pode ser gravado</p>
        </div>
      </li>
    </div>

  </div>

  <div class="col-md-7 step-form">
    <fieldset id="controleCampos">
      <div class="tab-content py-4" id="nav-tabContent">

        <div class="tab-pane fade show active" *ngIf="this.abas.cadastroTestador">
          <app-tab-cadastro-testador (onChangeAba)="selectAba($event)" [(homeValidacao)]="validarModel">
          </app-tab-cadastro-testador>
        </div>
        <div class="tab-pane fade show active" *ngIf="this.abas.executoresBeneficiarios">
          <app-tab-excecutores-beneficiarios (onChangeAba)="selectAba($event)" [(homeValidacao)]="validarModel">
          </app-tab-excecutores-beneficiarios>
        </div>

        <div class="tab-pane fade show active bloquearNaEdicao" *ngIf="this.abas.testamento">
          <app-tab-testamento (onChangeAba)="selectAba($event)" [(homeValidacao)]="validarModel"></app-tab-testamento>
        </div>
        <div class="tab-pane fade show active" *ngIf="this.abas.anexosAba">
          <app-tab-anexos (onChangeAba)="selectAba($event)" [(homeValidacao)]="validarModel"></app-tab-anexos>
        </div>

        <div class="tab-pane fade show active" *ngIf="this.abas.leituraTestamento">
          <!-- <app-tab-senhas (onChangeAba)="selectAba($event)" [(homeValidacao)]="validarModel"></app-tab-senhas> -->
          <app-tab-leitura (onChangeAba)="selectAba($event)"></app-tab-leitura>
        </div>

      </div>
    </fieldset>
  </div>
  <div class="col-md-2 form-preview">
    <fieldset id="controleValidarGerar">
      <app-preview-testamento
        [(homeValidacao)]="validarModel"></app-preview-testamento>
    </fieldset>
  </div>

</div>
<!-- aqui termina a tela desktop   -->

<!-- início da tela mobile -->
<div class="row d-mobile position-fixed w-100" *ngIf="toDisplay">
  <div class="col-md-12 mt-4">

    <ngb-carousel [showNavigationArrows]="false"
      [showNavigationIndicators]="true" [interval]="5000">
      <ng-template ngbSlide>
        <div
          class="d-flex justify-content-center flex-column align-items-center text-center">
          <h4 class="mb-4 text-blue text-uppercase">Seja bem vindo a MTD</h4>
          <p class="mb-3">O Meu Testamento Digital permite acriação de um
            Testamento Digital com áudios, vídeos,
            senhas
            e
            documentos. Utilizando recursos de criptografia, o MTD garante
            atransmissão de seu patrimônio e de suas
            memórias
            de forma segura e sem burocracia.</p>
        </div>
        <div class="imgBoasVindas">
          <img class src="assets/images/slider/mtd_slide_1.png"
            alt="Primeiro Slide">
        </div>

      </ng-template>
      <ng-template ngbSlide>
        <div
          class="d-flex justify-content-center flex-column align-items-center text-center">
          <h4 class="mb-4 text-blue text-uppercase">Deixe seu legado para quem
            mais ama!</h4>
          <p class="mb-3">Fazer um Testamento Digital é um ato de amor!</p>
        </div>
        <div class="imgBoasVindas">
          <img class src="assets/images/slider/mtd_slide_2.png"
            alt="Primeiro Slide">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div
          class="d-flex justify-content-center flex-column align-items-center text-center">
          <h4 class="mb-4 text-blue text-uppercase">Faça seu Testamento Digital,
            escolha as partes envolvidas e criptografe seu TD</h4>
          <p class="mb-3">Escolha seu(s) Beneficiário(s) e Executores(s) e
            realize a criptografia do seu Testamento
            Digital.</p>
        </div>
        <div class="imgBoasVindas">
          <img class src="assets/images/slider/mtd_slide_3.png"
            alt="Primeiro Slide">
        </div>
      </ng-template>

    </ngb-carousel>

  </div>

  <div class="col-md-12 d-flex justify-content-center w-100 mt-4">
    <button type="button" class="btn button-rounded col-md-3"
      style="margin: 0 1rem !important;" (click)="toggleData()">
      <img class="iconMobileAnimated" src="/assets/images/icones-mobile/go.gif">
      Criar meu testamento</button>

  </div>

</div>

<div class="row mt-4 position-fixed" *ngIf="!toDisplay">
  <div class="col-md-12 gridBtnMobile">

    <app-tab-excecutores-beneficiarios [(homeValidacao)]="validarModel"
      style="width: 100%;"></app-tab-excecutores-beneficiarios>
    <app-tab-anexos [(homeValidacao)]="validarModel"
      style="width: 100%;"></app-tab-anexos>
    <app-tab-senhas [(homeValidacao)]="validarModel"
      style="width: 100%;"></app-tab-senhas>
    <app-tab-testamento [(homeValidacao)]="validarModel"></app-tab-testamento>

  </div>

  <div class="menuFooterMobile col-md-12 gridStatusMobile form-preview mobile">

    <fieldset id="controleValidarGerar">
      <app-preview-testamento
        [(homeValidacao)]="validarModel"></app-preview-testamento>
    </fieldset>
  </div>

</div>
<!-- fim da tela mobile -->

<!-- ---------  modal nome testamento ---------  -->
<div class="basic modal fade in show modal-md"
  *ngIf="false" style="display: flex;">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="app-modal-header">
          <h5 class="modal-title titulo">Novo testamento digital</h5>
        </div>
      </div>
      <div class="modal-body">
        <div class="app-modal-body">
          <div class="row">

            <div class="col-md-12">
              <p>Para prosseguir, defina um nome para o testamento</p>
            </div>
            <div class="col-md-12">
              <input type="text" class="form-control fundoInput"
                placeholder="Nome do Testamento">
            </div>

            <div class="col-md-12 mt-4 mb-2">
              <button type="button" class="btn button-rounded col-md-6"
                style="margin: 0 auto;" data-dismiss="modal" aria-label="Close"
                (click)="toggleDataModalNomeTestamento()">
                <i class="feather icon-arrow-right"></i>AVANÇAR
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { jwtDecode, ResponseUser, User } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF } from 'src/environments/environment';

@Component({
  selector: 'app-aceite-login',
  templateUrl: './aceite-login.component.html',
  styleUrls: ['./aceite-login.component.scss']
})
export class AceiteLoginComponent implements OnInit {
  @Output() acaoProximo = new EventEmitter();

  public email: string;
  public senha: string;
  user : ResponseUser = new ResponseUser();
  jwt_decode: jwtDecode;
  public maskCPF = maskCPF;

  constructor(
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,

  ) {
    this.user.user = new User();
    this.user.token = "";
   }

  ngOnInit() {
    //this.email = "99999999999";
    //this.senha = "12345678";
    this.email = "";
    this.senha = "";
  }

  logar() {

    //só fazer um ajax buscando as informaçoes

    var url = API + 'Auth/User/Executor?login=' + this.email + '&senha=' + this.senha;
    this.ajax.getAsync(url, response => {
      //this.acaoProximo.emit({'resposta': true});
      this.jwt_decode = this.util.getDecodedAccessToken(response.token);
      this.user.user.nome = response.user.nome;
      this.user.user.idCliente = response.user.idCliente;
      this.user.user.id = response.user.id;
      this.user.user.email = response.user.email;
      this.user.user.telefone = response.user.telefone;
      this.user.user.dataNascimento = response.user.dataNascimento;
      this.user.user.cpf = response.user.cpf;
      this.user.user.idExecutor = response.user.idExecutor;
      this.user.token = response.token;
      this.auth.setUser(this.user);
      this.acaoProximo.emit({'resposta': true});
    });

  }

}

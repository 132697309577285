import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CadastroTestador } from 'src/app/model/CadastroTestador.model';
import { TestamentoEdicao } from 'src/app/model/TestamentoEdicao';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskData, maskTelefoneCelular } from 'src/environments/environment';

@Component({
  selector: 'app-componente-cad-usuario',
  templateUrl: './componente-cad-usuario.component.html',
  styleUrls: ['./componente-cad-usuario.component.scss']
})
export class ComponenteCadUsuarioComponent implements OnInit {

  @Input() TodosCampos: Boolean =true;
  @Output()
  onChangeAba = new EventEmitter<string>();

  private testamentoEdicao :TestamentoEdicao;
  public testador: CadastroTestador;
  public maskTelefoneCelular = maskTelefoneCelular;
  public maskData = maskData;
  public urlDiretaAcessoCliente:string;
  public isGarantirAcessoTestador:boolean=false;

  constructor(
    private util: UtilService,
    private ajax: AjaxService,
    private auth: AuthService,
  ) {
    this.testamentoEdicao = new TestamentoEdicao();
    this.inicializar();
  }

  ngOnInit() {
    this.inicializar();
    this.buscarDadosTestador();
    this.buscarTestamento();
  }

  buscarTestamento() {

    if(this.testamentoEdicao && this.testamentoEdicao.buscarTestamentoEmEdicao() ){
      this.inicializarTestamento(this.testamentoEdicao.Testamento);
      return true;
     }

    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/Em/Andamento";
    return this.ajax.get(url)
      .subscribe(response => {
        this.util.closeLoading();
        if (response.testamento != null) {
          this.inicializarTestamento(response.testamento);
        } else {

        }

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Testamento");
      });
  }

  inicializarTestamento(testamento:any){
    this.auth.setTestamento(testamento);
    this.auth.getTestamento();
}
  inicializar() {
    this.auth.getUser();
    this.testador = new CadastroTestador(
      this.auth.user.user.nome,
      "",
      this.util.formatDateStringOnlyDate(this.auth.user.user.dataNascimento),
      "",
      "",
      "",
      this.auth.user.user.cpf,
      "2",
      "",
      this.auth.user.user.email,
      this.auth.user.user.telefone,
      "",
      this.util);
      this.urlDiretaAcessoCliente = this.auth.user.user.urlDiretaAcessoCliente;
      if(this.urlDiretaAcessoCliente)
        this.isGarantirAcessoTestador = true;
      else
      this.isGarantirAcessoTestador = false;
  }

async garantirAcessoTestadorClick(){
  debugger;
    var data = {
      IsGarantirAcesso : !this.isGarantirAcessoTestador
      ,IdTestamento : this.auth.getTestamento().guid
    };
    let url = `${API}TestamentoDigital/GarantirAcessoTestadorAdvogado`;
    return await this.ajax.postAsync(url,data,response => {

      this.urlDiretaAcessoCliente = response.url;
      this.auth.user.user.urlDiretaAcessoCliente = response.url;
      this.auth.setUser(this.auth.user);
      });
}
onGarantirAcessoTestadorChanged(value:boolean){
  this.isGarantirAcessoTestador = value;
}

 async buscarDadosTestador() {
    this.util.showLoading();
    let url = API + "TestamentoDigital/Testamento/DadosTestador";
    return await this.ajax.getAsync(url,response => {
        if (response.data.length > 0) {
          let dados = response.data[0];
          if(dados != null){
            this.testador = new CadastroTestador(
              dados.nome,
              dados.nacionalidade,
              this.util.formatDateStringOnlyDate(dados.dataNascimento),
              dados.nomePai,
              dados.nomeMae,
              dados.profissao,
              this.auth.user.user.cpf,
              this.util.convertEstadoCivil(dados.estadoCivil),
              dados.endereco,
              dados.email,
              dados.telefone,
              "",
              this.util);
          }
        }
      });
  }

  async salvar() {
    if (this.testador.validar(this.TodosCampos)) {
      let dados = this.testador.montarJson();
      let url = API + "Auth/Client";

      this.util.showLoading();
      await this.ajax.putAsync(url, dados,response => {
        this.util.toasterShowSuccess("Atenção", "Testador Atualizado com Sucesso!");
        this.buscarDadosTestador();
        this.irParaExecutoresEBeneficiarios();
      });
    }
  }

  irParaExecutoresEBeneficiarios(){
    this.onChangeAba.emit('excecutores-beneficiarios');
  }


}


  <div class="container">
    <div *ngIf="!exibirModalEdicaoTestemunhas" class="row text-center justify-content-center mb-5">
        <div class="col-xl-6 col-lg-8">
            <h5 class="text-muted">{{objTestamento?.dadosTestador?.nome.toUpperCase()}}, acompanhe aqui o status de seu Testamento Digital</h5>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div *ngIf="!exibirModalEdicaoTestemunhas" class="timeline-steps aos-init aos-animate" data-aos="fade-up">

              <div  class="timeline-step"  >
                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                    <div [ngClass]="validado?.prontoParaPagamento ? 'inner-circle-verde' : 'inner-circle'" ></div>
                    <p *ngIf="validado?.prontoParaPagamento" class="h6 text-muted mb-0 mb-lg-0">Dados Válidos para Pagamento<br></p>
                    <p *ngIf="!validado?.prontoParaPagamento" class="h6 text-muted mb-0 mb-lg-0 alertable" (click)="showMessage(mensagensValidacao)">Favor completar os dados pra concluir seu testamento!<br></p>
                  </div>
            </div>

            <div class="timeline-step mb-0" *ngIf="!objTestamento?.isAssinaturaDigital && objTestamento?.isTestamentoParticularComBens">
              <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                <div [ngClass]="objTestamento?.isTestamentoParticularJaAnexado ? 'inner-circle-verde' : 'inner-circle'" ></div>

                <p *ngIf="objTestamento?.isTestamentoParticularJaAnexado"   class="h6 text-muted mb-0 mb-lg-0">Testamento Particular já anexado!</p>
                <p *ngIf="!objTestamento?.isTestamentoParticularJaAnexado"  class="h6 text-muted mb-0 mb-lg-0">Favor anexar o Testamento Particular.<br>
              </div>
          </div>

            <div  class="timeline-step"  >
              <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                  <div [ngClass]="validado?.testamentoPago ? 'inner-circle-verde' : 'inner-circle'" ></div>
                  <p *ngIf="validado?.testamentoPago" class="h6 text-muted mb-0 mb-lg-0">Pagamento Confirmado<br></p>
                  <p *ngIf="!validado?.testamentoPago" class="h6 text-muted mb-0 mb-lg-0">Pagamento Pendente<br></p>
                </div>
          </div>


               <div class="timeline-step" >

                <div *ngIf="objTestamento?.isAssinaturaDigital && objTestamento?.isTestamentoParticularComBens" class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                  <div [ngClass]="objTestamento?.dataAssinaturaTestador ? 'inner-circle-verde' : 'inner-circle'" ></div>
                  <p *ngIf="objTestamento?.dataAssinaturaTestador" class="h6 text-muted mb-0 mb-lg-0">Você assinou o testamento em {{objTestamento?.dataAssinaturaTestador}}<br></p>
                  <p *ngIf="!objTestamento?.dataAssinaturaTestador" class="h6 text-muted mb-0 mb-lg-0">Você ainda NÃO assinou o testamento<br></p>
                  <br/>
                  <br/>
                </div>



                    <div  *ngIf="objTestamento?.isAssinaturaDigital && objTestamento?.isTestamentoParticularComBens" class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                      <div [ngClass]="objTestamento?.todasTestemunhasAssinaram ? 'inner-circle-verde' : 'inner-circle'" ></div>
                        <p *ngIf="objTestamento?.todasTestemunhasAssinaram" (click)="showMessage(objTestamento?.testemunhasAssinaram)" class="h6 text-muted mb-0 mb-lg-0 alertable">Todas as Testemunhas já Assinararam</p>
                                 <p *ngIf="!objTestamento?.todasTestemunhasAssinaram" (click)="showMessage(objTestamento?.testemunhasAssinaram)" class="h6 text-muted mb-0 mb-lg-0 alertable">Faltam {{objTestamento?.quantidadeTestemunhasFaltamAssinar}} testemunhas assinarem o testamento</p>
                                 <br/>
                                 <br/>
                                </div>



                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                      <div [ngClass]="objTestamento?.todosExecutoresAssinaram ? 'inner-circle-verde' : 'inner-circle'" ></div>
                        <p *ngIf="objTestamento?.todosExecutoresAssinaram"  (click)="showMessage(objTestamento?.executoresAssinaram)" class="h6 text-muted mb-0 mb-lg-0 alertable">Todos(a) os(a) Executores(a) já Assinararam</p>
                        <p *ngIf="!objTestamento?.todosExecutoresAssinaram" (click)="showMessage(objTestamento?.executoresAssinaram)" class="h6 text-muted mb-0 mb-lg-0 alertable">Faltam {{objTestamento?.quantidadeExecutoresFaltamAssinar}} executores(a) para assinar o testamento</p>
                    </div>
                </div>

                <div class="timeline-step mb-0" *ngIf="objTestamento?.isAssinaturaDigital && objTestamento?.isTestamentoParticularComBens">
                  <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                    <div [ngClass]="objTestamento?.isTestamentoParticularJaAnexado ? 'inner-circle-verde' : 'inner-circle'" ></div>

                    <p *ngIf="objTestamento?.isTestamentoParticularJaAnexado"   class="h6 text-muted mb-0 mb-lg-0">Testamento Particular já anexado!</p>
                    <p *ngIf="!objTestamento?.isTestamentoParticularJaAnexado"  class="h6 text-muted mb-0 mb-lg-0">O Testamento Particular será anexado de forma automática, após todas as assinaturas!<br>
                  </div>
              </div>


                <div class="timeline-step mb-0">
                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                      <div [ngClass]="objTestamento?.testamentoJaConstruido ? 'inner-circle-verde' : 'inner-circle'" ></div>

                      <p *ngIf="objTestamento?.testamentoJaConstruido"   class="h6 text-muted mb-0 mb-lg-0">Chave e Link já enviados para Usuário</p>

                      <p *ngIf="!objTestamento?.testamentoJaConstruido"  class="h6 text-muted mb-0 mb-lg-0">Usuário(a) ainda não recebeu a<br>
                        chave e o link para<br>
                        download do<br>
                        Testamento Digital</p>
                    </div>
                </div>

            </div>
<!--Testemunhas do testamento, quando for clicksign-->
<div (click)="carregarTestemunhasTestamento(objTestamento?.guid)" class="espacoAssinaturaClickSign" *ngIf="!exibirModalEdicaoTestemunhas && objTestamento?.isAssinaturaDigital && !objTestamento?.testamentoJaConstruido && objTestamento?.existeTestemunhas">
  Clique aqui para editar as informações das Testemunhas!
</div>

<div class="espacoAssinaturaClickSignTestemunhasTitulo" *ngIf="exibirModalEdicaoTestemunhas">
  Testemunhas
</div>


<div class="table-responsive" *ngIf="exibirModalEdicaoTestemunhas && objTestamento?.isAssinaturaDigital && !objTestamento?.testamentoJaConstruido && objTestamento?.existeTestemunhas">
  <table class="table">
    <thead>
      <tr>
        <th>Nome Completo</th>
        <th>E-mail</th>
        <th>Telefone</th>
        <th>Cpf</th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>

      <tr title="CPF:{{item.cpf}}" *ngFor="let item of arrayTestemunhas">
        <td>{{item.nome}}</td>
        <td><input class="form-control" type="text" [(ngModel)]="item.email" value="{{item.email}}"></td>
        <td>{{item.telefone}}</td>
        <td><input type="text" class="form-control" [(ngModel)]="item.cpf" value="{{item.cpf}}"></td>
        <td class="col-1">
        <button type="button" (click)="editarTestemunha(objTestamento?.guid,item)" class="btn btn-icon btn-info btn-sm">Salvar</button>
        </td>
      </tr>
    </tbody>
  </table>

</div>

<div (click)="voltarParaAcompanhamento()" class="espacoAssinaturaClickSignVoltar" *ngIf="exibirModalEdicaoTestemunhas">
  Voltar
</div>

        </div>
    </div>
</div>

<app-ui-modal #modalSenha [hideFooter]="true" [dialogClass]="'modal-md'" class="modalMobile">
  <div class="app-modal-header">
    <h5 class="modal-title titulo">ADICIONAR SENHAS</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" #botaoFecharModalSenhas
      (click)="util.closeModal(modalSenha)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="row">

      <div class="col-md-12 mt-3">
        <label for="linhaSenha.Descricao" style="position: absolute; z-index: -1;">Descrição</label>
        <textarea type="text" class="form-control fundoInput formSenhas" [(ngModel)]="linhaSenha.Descricao"
          name="linhaSenha.Descricao" placeholder="Descrição">
           </textarea>
      </div>
      <div class="col-md-12">
        <label for="linhaSenha.Descricao" style="position: absolute; z-index: -1;">Senha</label>
        <input type="password" class="form-control fundoInput" [(ngModel)]="linhaSenha.Senha" name="linhaSenha.Senha"
          placeholder="SENHA" />

      </div>
      <div class="col-md-12 mt-4 mb-2">
        <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;" (click)="addSenha()">
          <i class="feather icon-upload-cloud"></i>
          SALVAR
        </button>
      </div>


      <div class="d-mobile lineDividerH"></div>

      <div class="d-mobile justify-content-center col-md-12 mt-3 mb-2" style="text-transform: uppercase;" align="center">
        <p class="titulo mb-0">Já adicionado(s)</p>
      </div>

      <div class="d-mobile col-md-12 d-flex align-items-center flex-wrap" style="text-transform: uppercase;" *ngFor="let item of arraySenhas">
        <div class="col-6">{{item.chave}}</div>
        <div class="col-3 td-senha">******</div>
        <div class="col-1">
          <button type="button" (click)="visualizarSenha(item.guid,item.idTestamento)" class="btn btn-outline-senha" style="border: none !important;">
            <i class="feather icon-eye"></i></button>
        </div>
        <div class="col-1">
          <button type="button" (click)="deletarSenha(item.guid,item.idTestamento)" class="btn btn-outline-danger" style="border: none !important;">
            <i class="feather icon-trash"></i></button>
        </div>
        <div class="lineDividerAdicionados"></div>
      </div>

      <div class="d-mobile m-2"></div>

    </div>

  </div>
</app-ui-modal>

<div class="d-none-sm">
  <div class="row img-topo img-senhas mt-0">
    <p>
      O fornecimento de senhas de acesso ao celular, e-mail, conta bancária, redes sociais, criptomoedas ou
      até mesmo de cofres físicos pode ser muito útil para seus Beneficiários no momento de emergência.
      Mas não se preocupe, todas as senhas são criptografadas!
    </p>
  </div>

  <div class="row align-items-center" style="padding: 0 0 14px 0; margin:0px;">

    <div class="col-md-12 pr-0">
      <a class="float-right text-muted">

        <button type="button" class="btn btn-icon btn-outline-info" (click)="util.openModal(modalSenha)">
          <i class="feather icon-plus"></i>Adicionar senha</button>
      </a>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Descrição</th>
          <th>Senha</th>
          <th class="headerAcoes">Ações</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let item of arraySenhas">
          <td>{{item.chave}}</td>
          <td class="td-senha">******</td>
          <td class="gridBtnAcoes" *ngIf="!isAdvogadoUtilizando">
            <button  type="button" (click)="visualizarSenha(item.guid,item.idTestamento)"
              class="btn btn-outline-senha"><i class="feather icon-eye"></i></button>

            <button  type="button" (click)="deletarSenha(item.guid,item.idTestamento)" class="btn btn-outline-danger"><i
                class="feather icon-trash"></i></button>
          </td>
          <td class="gridBtnAcoes" *ngIf="isAdvogadoUtilizando">

               <button type="button"
               class="btn btn-outline-senha desativado" placement="top" tooltipClass="tooltipTestemunhas"
               ngbTooltip="Somente o testador pode visualizar"><i class="feather icon-eye"></i></button>

               <button *ngIf="!isAdvogadoUtilizando" type="button"  class="btn btn-outline-danger"><i
                class="feather icon-trash desativado" tooltipClass="tooltipTestemunhas"
                ngbTooltip="Somente o testador pode deletar"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="!isTestadorClienteAdvogado" class="col-md-12 mt-5">
      <button type="button" class="btn button-rounded col-md-3" style="margin: 0 auto;"
        (click)="irParaLeitura()">
        <i class="feather icon-arrow-right"></i>AVANÇAR
      </button>
    </div>
  </div>
</div>

<div class="d-mobile">

  <button type="button" class="col-12 btn button-rounded mobile" style="border-color: var(--azulMedio); color: var(--azulMedio);"
    (click)="util.openModal(modalSenha)">
    <!-- <i class="feather icon-lock"></i> -->
    <img class="iconMobileHome" src="/assets/images/icones-mobile/senha.gif">
    <span>Adicionar Senha (s)</span>
    </button>

</div>

import { UtilService } from "../service/util.service";

export class Senha {

  Senhas: LinhaSenha[];
  IdTestamento: string;
  ChaveTestamento:String;

  constructor(
    senhas: LinhaSenha[],
    idTestamento: string,
    private util?: UtilService
  ) {
    this.Senhas = senhas;
    this.IdTestamento = idTestamento;
  }


  public validaAddSenha(objSenha: LinhaSenha): boolean {

    if (objSenha.Descricao == "") {
      this.util.toasterShowError("Atenção", "O Campo Descrição não pode ser Vazio!");
      return false;
    }

    if (objSenha.Senha == "") {
      this.util.toasterShowError("Atenção", "O Campo Senha não pode ser Vazio!");
      return false;
    }

    return true;
  }

  public montarItemJson(objSenha: LinhaSenha): any {
    if (this.validaAddSenha(objSenha)) {
      return {
        "chave": objSenha.Descricao,
        "valor": objSenha.Senha,
        "descricao": objSenha.Descricao,
        "idTestamento": this.IdTestamento,
        "chaveTestamento":this.ChaveTestamento
      }
    }
  }

  public addLinhaSenha(objSenha: LinhaSenha) {
    if (this.validaAddSenha(objSenha))
      this.Senhas.push(objSenha);
  }

}

export class LinhaSenha {
  Descricao: string;
  Senha: string;
  ChaveTestamento:String;
}

import { Component, Input, OnInit,ViewChild,ElementRef, EventEmitter, Output  } from '@angular/core';
import { ValidacaoModel } from 'src/app/model/Validacao.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, MENSAGEM } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-tab-leitura',
  templateUrl: './tab-leitura.component.html',
  styleUrls: ['./tab-leitura.component.scss']
})
export class TabLeituraComponent implements OnInit {

  public meetingLink: string;
  public arrayMeetings:Array<any> = [];

  @Input() homeValidacao: ValidacaoModel;

  @Output()
  onChangeAba = new EventEmitter<string>();

  public arraylLeitura: any;

  @ViewChild('botaoFecharModalLeitura')
  botaoFecharModalLeitura: ElementRef;

  @ViewChild('btnAbrirModalAddGravacao')
  btnAbrirModalAddGravacao

  @ViewChild('botaoFechamodalLeituraReuniao')
  botaoFechamodalLeituraReuniao: ElementRef;

  constructor(
    public util: UtilService,
    private auth: AuthService,
    private ajax: AjaxService,
    private clipboard: Clipboard
  ) {

  }

  async ngOnInit() {
    this.inicializar();
    await this.getMeetings();
  }

  inicializar() {

  }

  irParaAvancar(){
    this.onChangeAba.emit('cadastro-testador');
  }

  iniciarReuniao(){
    location.href =  this.meetingLink;
  }


async apagarMeeting(idMeeting:string, idTestamento:string):Promise<void>{
this.util.Confirmacao("Tem certeza que deseja remover o metting assim como todos os arquivos gravados relacionados ao mesmo?",async ()=>{
      var url = `${API}TestamentoDigital/Arquivo/Meeting/${idMeeting}/${idTestamento}`;
      await this.ajax.deleteAsync(url,async r=>{
         this.util.toasterShowSuccess("Atenção", "Deletado com Sucesso");
         await this.getMeetings();
    });
  });
}

  async adicionarGravacao()
  {
    this.util.Confirmacao('Tem certeza que deseja iniciar uma nova reunião?', async () => {
      this.btnAbrirModalAddGravacao.nativeElement.click();
      await this.gerarLink();
    },null,"Sim");
  }

  copiarLink(){
    this.clipboard.copy(this.meetingLink);
  }

  async gerarLink(){
    let url = `${API}TestamentoDigital/Meeting/${this.auth.getTestamento().guid}`;

    await this.ajax.postAsync(url,null,response => {
      if (response) {
          this.meetingLink  = response.url;
          this.getMeetings();
      }
    });
  }

  async getMeetings(){
    let url = `${API}TestamentoDigital/Meeting/${this.auth.getTestamento().guid}`;
    await this.ajax.getAsync(url,response => {
      if (response && response.data) {
          this.arrayMeetings = response.data;
      }
    });
  }
}

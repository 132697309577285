<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>



<div class="row divPlanos" *ngIf="controlaDiv?.processandoPagamento">

  <div class="divAguarde">
    <h4>{{mensagemPagamento}}</h4>
    <img src="assets/images/auth/logo_preta.png" alt="" class="img-fluid">
    <!--<img src="assets/images/loading.gif" alt="" class="img-fluid">-->
  </div>
</div>

<div class="row divPlanos divPlanosFull d-none-sm" *ngIf="controlaDiv?.plano">

  <div class="divCorpoModal">
    <div class="divPlanosTitulo">
      <h3 class="modal-comum">Escolha um Plano</h3>
      <h3 class="modal-title titulo" style="z-index: 1;">PAGUE AGORA COM DESCONTO E FAÇA DEPOIS!</h3>
    </div>
    <div class="row col-lg-12 d-flex justify-content-center">
      <div class="row divPlanosContent">
        <div class="col-sm-12  divPlano" *ngFor="let item of arrayPlanos?.planos">
          <app-card [hidHeader]="true" blockClass="task-details" [options]="false" blockClass="text-center"
            (click)="escolherPlano(item)" class="cardValor">
            <h3 class="m-t-20 mb-0"><span class="tituloPlano">{{item.nome}}</span></h3>
            <!-- <table class="table table-responsive invoice-table table-borderless p-l-20" style="overflow-wrap: break-word; overflow:hidden; min-height: 350px;"> -->
            <table class="table table-responsive invoice-table table-borderless" style="overflow-wrap: break-word; overflow:hidden; margin-bottom: 0;">
              <tbody>
                <tr>
                  <td>
                    <h4 class="valorPlano mb-3">{{item.valorBase | currency : 'R$ '}} - Setup {{item.valorSetup | currency : 'R$ '}}</h4>
                  </td>
                </tr>
                <tr>
                  <td style="overflow-wrap: break-word;" class="description col-md-12 planoDisponivel"
                    [innerHTML]="item.descricao | SafePipe"> </td>
                </tr>
              </tbody>
            </table>

          </app-card>
        </div>

      </div>

    </div>

  </div>
</div>

<div class="row divPlanos d-mobile" *ngIf="controlaDiv?.plano">

  <div class="divCorpoModal">
    <div class="divPlanosTitulo">
      <h3 class="modal-comum">Escolha um Plano</h3>
      <h3 class="modal-title titulo" style="z-index: 1;">PAGUE AGORA COM DESCONTO E FAÇA DEPOIS!</h3>
    </div>
    <div class="row col-lg-12 d-flex justify-content-center">
      <div class="row divPlanosContent">
        <div class="col-sm-12 divPlano" *ngFor="let item of arrayPlanos?.planos">
          <app-card [hidHeader]="true" blockClass="task-details" [options]="false" blockClass="text-center"
            class="cardValor" class="cardValor">
            <a (click)="escolherPlano(item)">
            <h3><span class="tituloPlano">{{item.nome}}</span></h3>

            <h4 class="valorPlano">{{item.valorBase | currency : 'R$ '}} - Setup {{item.valorSetup | currency : 'R$ '}}</h4>
            </a>
            <ngb-accordion [closeOthers]="true" activeIds="static-1" class="gradientaccordion">
              <ngb-panel id="static-2">
                <ng-template ngbPanelTitle>
                  <h5><a href="javascript:">Detalhes do plano</a></h5>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div style="overflow-wrap: break-word;" class="description col-md-12 planoDisponivel"
                    [innerHTML]="item.descricao | SafePipe"> </div>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>
          </app-card>

        </div>
      </div>

    </div>

  </div>
</div>

<div class="row divResumo mt-3" *ngIf="controlaDiv?.cotacao">
  <!-- <div class="col-md-12 col-lg-3"></div> -->
  <div class="mx-auto col-md-12 col-lg-6 mobile-mt-3 cardResumoCompraMobile">
    <app-card cardClass="trnasiction-card" [customHeader]="true" headerClass="bg-c-gray" blockClass="text-center"
      [isCardFooter]="true" footerClass="border-top transection-footer p-0">
      <div class="app-card-header">
        <h5 class="text-white"> Plano: {{planoSelecionado.nome}}</h5>
        <!--<div class="card-header-right">
          <div class="transection-preogress running-75">
            <p class="m-b-0">90%</p>
          </div>
        </div>-->
      </div>
      <br>
      <legend>Resumo da Compra</legend>

      <!-- <div class="row">
        <div class="col text-left">
          <h3 class="m-b-5">{{cotacao?.totalDesconto  | currency : 'R$ '}}</h3>
          <h6 class="m-b-0">Total Desconto</h6>
        </div>
        <div *ngIf="cotacao?.totalArquivos" class="col text-right">
          <h3 class="m-b-5">{{cotacao?.totalArquivos | currency : 'R$ '}}</h3>
          <h6 class="m-b-0">Total Arquivos</h6>
        </div>
      </div> -->
      <br>
      <div class="row">
        <br>

        <div *ngIf="cotacao?.totalArquivos" class="table-responsive" style="padding: 20px;">
          <table class="table table-xs">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Preço</th>
                <th>Tamanho</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let item of cotacao?.arquivos">
                <td>{{item.nome}}</td>
                <td>{{item.preco | currency : 'R$ '}}</td>
                <td>{{item.tamanho}}</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div>
      <!-- <div class="row form-control-inline"> -->
      <div class="row d-flex align-items-center mobileMarginLat">
        <!-- <div class="row"> -->
        <label class="pr-2">Cumpom de desconto: </label>
        <input type="text" class="form-control col-md-6 col-sm-12 cupom mr-2" [(ngModel)]="cupomDesconto"
          name="cupomDesconto">
        <!-- <button type="button" class="btn btn-sm button-rounded-gray col-md-3 ml-2 mobile-mt-3" (click)="aplicarCupom()"> -->
        <button type="button" class="btn button-rounded btnDesativar col-md-3 col-12  mobile-mt-3" (click)="aplicarCupom()">
          Aplicar
        </button>
      </div>

      <div class="row d-flex align-items-center justify-content-center">
        <div class="statusCupom verde row mt-4" *ngIf="cotacao?.cupom?.status==1">
          Cupom Válido!
        </div>
        <div class="statusCupom vermelho row mt-4" *ngIf="cotacao?.cupom?.status==2">
          Cupom Expirado!
        </div>
        <div class="statusCupom vermelho row mt-4" *ngIf="cotacao?.cupom?.status==3">
          Cupom Esgotado!
        </div>
        <div class="statusCupom vermelho row mt-4" *ngIf="!cotacao?.cupom && cotacaoComCupomRealizada">
          Cupom não localizado!
        </div>
      </div>
      <div class="row d-flex align-items-center mt-4">
        <!-- <div class="col text-left d-flex align-items-center"> -->
        <h6 class="">Total Desconto: </h6>
        <h3 class="pl-3">{{cotacao?.totalDesconto | currency : 'R$ '}}</h3>
        <!-- </div> -->
      </div>
      <div *ngIf="cotacao?.totalArquivos" class="row d-flex align-items-center mt-4">
        <h3 class="">{{cotacao?.totalArquivos | currency : 'R$ '}}</h3>
        <h6 class="pl-3">Total Arquivos</h6>
      </div>

      <div class="row">
        <p>Valor do Plano Escolhido: {{cotacao?.valorPlano | currency : 'R$ '}}</p>
      </div>
      <div class="row">
        <p>Valor do Setup: {{cotacao?.valorSetup | currency : 'R$ '}}</p>
      </div>
      <div class="row" *ngIf="cotacao?.cupom">
        <p>Desconto do Cupom: {{cotacao?.cupom?.valorDesconto | currency : 'R$ '}}</p>
      </div>
      <div class="row">
        <h3>Total: {{cotacao?.totalPedido | currency : 'R$ '}}</h3>
      </div>

        <div class="col-md-12 mt-4 mb-2">
          <button type="button" class="btn button-rounded col-md-4" style="margin: 0 auto;"
            (click)="selecionarCartao()">
            <i class="feather icon-credit-card"></i>
            Ir para Pagamento
          </button>
        </div>

    </app-card>
  </div>
</div>

<div class="divFinalizarPagamento" style="overflow: auto !important;">
<div class="row mt-4" *ngIf="controlaDiv?.pagamento">
  <!-- <div class="col-md-3"></div> -->
  <div class="mx-auto col-md-12 col-lg-6 mb-3">
    <app-card [options]="false" [hidHeader]="true">

      <h5 style="margin-top:30px;" *ngIf="idTipoPagamento==tipoPagamento.credito && !cupom100"class="card-title">Dados do Cartão de Crédito</h5>
      <h5  style="margin-top:30px;" *ngIf="idTipoPagamento==tipoPagamento.debito && !cupom100" class="card-title">Dados do Cartão de Débito</h5>
      <h5  style="margin-top:30px;" *ngIf="idTipoPagamento==tipoPagamento.pix && !cupom100"    class="card-title">Dados do Pix</h5>
      <h5  style="margin-top:30px;" *ngIf="cupom100"    class="card-title">Cupom de 100% de Desconto</h5>


      <div class="row rowPagamento">
        <div *ngIf="!cupom100" class="col-md-4">
          <label for="idTipoPagamento">Tipo do Pagamento:</label>
          <select class="form-control" [(ngModel)]="idTipoPagamento" name="idTipoPagamento">
            <option value="bff963a1-cbc8-4e70-8a75-248a0a221dc0">Cartão - Crédito</option>
            <option value="bff963a1-cbc8-4e70-8a75-248a0a221dc1">Cartão - Débito</option>
            <!-- <option value="bff963a1-cbc8-4e70-8a75-248a0a221dc2">Pix</option>-->
          </select>
        </div>


        <div *ngIf="idTipoPagamento==tipoPagamento.credito || idTipoPagamento==tipoPagamento.debito" class="row mb-3">

          <div class="col-md-8">
            <label for="cartaoCredito.cartao.nomeProprietario">Nome Impresso no Cartao</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.nomeProprietario"
              name="cartaoCredito.cartao.nomeProprietario">
          </div>
          <div class="col-md-4">
            <label for="cartaoCredito.cartao.cpf">CPF</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.cpf"
              name="cartaoCredito.cartao.cpf" [textMask]="{mask: maskCPF}">
          </div>

          <div class="col-md-5">
            <label for="cartaoCredito.cartao.numeroCartao">Número Cartão</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.numeroCartao"
              name="cartaoCredito.cartao.numeroCartao" [textMask]="{mask: maskNumeroCartao}">
          </div>

          <div class="col-6 col-md-2">
            <label for="cartaoCredito.cartao.mesVencimento">Mês</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.mesVencimento"
              name="cartaoCredito.cartao.mesVencimento" [textMask]="{mask: maskMes}">
          </div>
          <div class="col-6 col-md-2">
            <label for="cartaoCredito.cartao.anoVencimento">Ano</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.anoVencimento"
              name="cartaoCredito.cartao.anoVencimento" [textMask]="{mask: maskAno}">
          </div>
          <div class="col-md-3">
            <label for="cartaoCredito.cartao.codigoVerificador">CVV (Cód. de Segurança)</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.codigoVerificador"
              name="cartaoCredito.cartao.codigoVerificador" [textMask]="{mask: maskCVV}">
          </div>

          <div *ngIf="idTipoPagamento==tipoPagamento.credito" class="col-md-4">
            <label for="quantidadeParcelas">Deseja Parcelar?</label>
            <select class="form-control" [(ngModel)]="quantidadeParcelas" name="quantidadeParcelas">
              <option selected value="1">1x Sem juros</option>
              <option value="2">2x Sem juros</option>
              <option value="3">3x Sem juros</option>
              <option value="4">4x Sem juros</option>
              <option value="5">5x Sem juros</option>
              <option value="6">6x Sem juros</option>
              <option value="7">7x Sem juros</option>
            </select>
          </div>

        </div>
        <div *ngIf="idTipoPagamento==tipoPagamento.pix" class="col-md-12 row">
          <p>Ainda não disponível</p>
        </div>
      </div>
    </app-card>
  </div>
</div>


<div class="row" *ngIf="controlaDiv?.pagamento">
  <!-- <div class="col-md-3"></div> -->
  <div class="col-md-6 mx-auto">
    <app-card [options]="false" [hidHeader]="true">

      <h5  style="margin-top:30px;" class="card-title d-none-sm">Dados de Cobrança</h5>

      <!-- <div class="row"> -->

      <div *ngIf="idTipoPagamento==tipoPagamento.credito || idTipoPagamento==tipoPagamento.debito"
        class="row rowPagamento">

        <div class="row col-md-12">
          <label for="cartaoCredito.cartao.enderecoCobranca.cep">Cep: &nbsp;</label>
          <input (blur)="buscaCep()" type="text" class="form-control col-md-3"
            [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.cep" [textMask]="{mask: maskCEP}"
            name="cartaoCredito.cartao.enderecoCobranca.cep"/>

            <div class="col-md-2" style="margin-top:-5px">
              <button type="button" class="btn col-md-12 form-control" style="margin: 0 auto;"
                (click)="buscaCep()">
                <i class="feather icon-credit-card"></i>
                Buscar
              </button>
            </div>
        </div>

        <ng-container *ngIf="cepPreenchido">
          <!-- <div class="row" *ngIf="cepPreenchido"> -->
          <!-- <div class="col-md-12 row"> -->
          <div class="col-md-3">
            <label for="arrayPais">País</label>
            <ng-select [ngClass]="'tag-select'" [options]="arrayPais" (ngModelChange)="buscaEstados(paisSelecionado)"
              id="paisSelecionado" name="paisSelecionado" [(ngModel)]="paisSelecionado" disabled>
            </ng-select>
          </div>

          <div class="col-md-3">
            <label for="estadoSelecionado">Estado</label>
            <ng-select [ngClass]="'tag-select'" [options]="arrayEstado" #selectEstado [(ngModel)]="estadoSelecionado"
              (ngModelChange)="buscaCidade(estadoSelecionado)" name="estadoSelecionado">
            </ng-select>
            <!--<input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.estado"
              name="cartaoCredito.cartao.enderecoCobranca.estado">-->
          </div>

          <div class="col-md-3">
            <label for="cartaoCredito.cartao.enderecoCobranca.cidade">Cidade</label>
            <ng-select [ngClass]="'tag-select'" [options]="arrayCidade" [(ngModel)]="cidadeSelecionado"
              name="cidadeSelecionado" (ngModelChange)="selecionaCidade(cidadeSelecionado)">
            </ng-select>
            <!--<label for="cartaoCredito.cartao.enderecoCobranca.cidade">Cidade</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.cidade"
              name="cartaoCredito.cartao.enderecoCobranca.cidade">-->
          </div>


          <div class="col-md-9">
            <label for="cartaoCredito.cartao.enderecoCobranca.rua">Rua</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.rua"
              name="cartaoCredito.cartao.enderecoCobranca.rua">
          </div>

          <div class="col-6 col-md-3">
            <label for="cartaoCredito.cartao.enderecoCobranca.numero">Número</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.numero"
              name="cartaoCredito.cartao.enderecoCobranca.numero">
          </div>

          <div class="col-6 col-md-3">
            <label for="cartaoCredito.cartao.enderecoCobranca.complemento">Complemento</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.complemento"
              name="cartaoCredito.cartao.enderecoCobranca.complemento">
          </div>

          <div class="col-md-9">
            <label for="cartaoCredito.cartao.enderecoCobranca.bairro">Bairro</label>
            <input type="text" class="form-control" [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.bairro"
              name="cartaoCredito.cartao.enderecoCobranca.bairro">
          </div>

          <div class="col-md-12 pl-0 mt-3">
            <h5 class="pl-mobile-3">Telefone</h5>
          </div>

          <div class="col-3 col-md-2">
            <label for="cartaoCredito.cartao.enderecoCobranca.codPaisTelefone">Cód. País</label>
            <input [textMask]="{mask: maskOnlyNumbers}" maxlength="3" type="text" class="form-control"
              [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.codPaisTelefone"
              name="cartaoCredito.cartao.enderecoCobranca.codPaisTelefone">
          </div>

          <div class="col-3 col-md-2">
            <label for="cartaoCredito.cartao.enderecoCobranca.dDDTelefone">DDD</label>
            <input maxlength="2" type="text" class="form-control"
              [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.dDDTelefone"
              name="cartaoCredito.cartao.enderecoCobranca.dDDTelefone">
          </div>

          <div class="col-6 col-md-5">
            <label for="cartaoCredito.cartao.enderecoCobranca.telefone">Número (SEM O DDD)</label>
            <input type="text" maxlength="11" name="cartaoCredito.cartao.end" class="form-control"
              [(ngModel)]="cartaoCredito.cartao.enderecoCobranca.telefone"
              name="cartaoCredito.cartao.enderecoCobranca.telefone">
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </ng-container>
      </div>
      <!-- </div> -->
      <div class="row">
        <div class="col-md-12 mt-4 mb-2">
          <button type="button" class="btn button-rounded col-md-4" style="margin: 0 auto;"
            (click)="salvarDadosCartao()">
            <i class="feather icon-credit-card"></i>
            Finalizar Pagamento
          </button>
        </div>
      </div>
    </app-card>
  </div>
</div>
</div>
<app-ui-modal #modalchave [hideFooter]="true" [hideHeader]="true" [dialogClass]="'modal-md modalChave'"
  [containerClick]="false">

  <div class="app-modal-body ">

    <app-card [customHeader]="false" [options]="false" blockClass="pt-0 users-contact">
      <div class="imgChaveModal text-center">
        <div class="row align-items-end">
          <div class="col"></div>
          <div class="col">
            <div class="position-relative d-inline-block">
              <img class=" " src="assets/images/img-chave-gerada.png" alt="User image" width="230">
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="text-center">
        <br> <br> <br> <br> <br> <br>
        <h3 class="mb-1 mt-3">Parabéns!</h3>

        <p class="msgChave" *ngIf="!chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
          O Testamento Digital foi criptografado.
          Guarde sua Chave de Acesso, mas não se preocupe,
          a chave será
          enviada por e-mail.
        </p>

        <p class="msgChave" *ngIf="chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
          {{mensagem}}
        </p>

        <div class="col-md-12" *ngIf="!chave?.seraConstruidoDepoisDasAssinaturasNoClickSign">
          <br>
          <label>CHAVE DE ACESSO</label>
          <input type="text" class="form-control fundoInput chaveAcesso text-center" [(ngModel)]="chave.chave"
            readonly="true">
        </div>

      </div>
      <hr class="wid-80 b-wid-3 my-4">
      <div class="row text-center">
        <div class="col-md-12 mt-4 mb-2">
          <button type="button" class="btn button-rounded col-md-6" style="margin: 0 auto;"
            (click)="irParaGerenciarTestamentos()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            Prosseguir
          </button>
        </div>

      </div>
    </app-card>

  </div>
</app-ui-modal>
<button type="button" class="close basic-close" aria-label="Close" style="display: none; width: 0px; height: 0px"
  #btnExibirModal (click)="util.openModal(modalchave)">.</button>
